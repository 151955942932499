import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#let (unique-id) as |inputId|}}\n  <label\n    aria-disabled={{@disabled}}\n    aria-busy={{this.isBusy}}\n    data-test-settings-toggle-label\n    for={{concat inputId \"-toggle-control\"}}\n    local-class=\"label\"\n  >\n    {{@label}}\n  </label>\n\n  <Shell::SettingsRoute::Toggle::Button\n    @isBusy={{this.isBusy}}\n    @isChecked={{this.isChecked}}\n    @isDisabled={{@disabled}}\n    @onChange={{perform this.update}}\n    id={{concat inputId \"-toggle-control\"}}\n  />\n{{/let}}", {"contents":"{{#let (unique-id) as |inputId|}}\n  <label\n    aria-disabled={{@disabled}}\n    aria-busy={{this.isBusy}}\n    data-test-settings-toggle-label\n    for={{concat inputId \"-toggle-control\"}}\n    local-class=\"label\"\n  >\n    {{@label}}\n  </label>\n\n  <Shell::SettingsRoute::Toggle::Button\n    @isBusy={{this.isBusy}}\n    @isChecked={{this.isChecked}}\n    @isDisabled={{@disabled}}\n    @onChange={{perform this.update}}\n    id={{concat inputId \"-toggle-control\"}}\n  />\n{{/let}}","moduleName":"@clarksource/client/components/shell/settings-route/toggle.hbs","parseOptions":{"srcName":"@clarksource/client/components/shell/settings-route/toggle.hbs"}});
import type { ConfirmResult } from '@clark-shell/core-plugins/modals';
import type { AllPlugins } from '@clark-shell/ember';
import { plugin } from '@clark-shell/ember';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { isDevelopingApp, macroCondition } from '@embroider/macros';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import * as Sentry from '@sentry/browser';
import { dropTask } from 'ember-concurrency';

import type { indeterminate, UpdateHandler } from './toggle/button';

export interface ShellSettingsRouteToggleSignature {
  Args: {
    busy?: boolean;
    checked?: boolean | typeof indeterminate;
    disabled?: boolean;
    label: string;
    update?: UpdateHandler;
  };
}

export default class ShellSettingsRouteToggleComponent extends Component<ShellSettingsRouteToggleSignature> {
  @plugin private declare readonly modals: AllPlugins['Modals'];
  @service declare intl: Services['intl'];

  @tracked private optimisticState?: boolean;

  get isBusy(): boolean {
    return this.args.busy || this.update.isRunning;
  }

  get isChecked(): boolean | typeof indeterminate | undefined {
    return this.optimisticState ?? this.args.checked;
  }

  update = dropTask(async (checked: boolean, event: Event) => {
    try {
      // Set the new state right away temporarily override the real `@checked`
      // argument state to give the illusion of faster UI performance, a.k.a.
      // "optimistic UI".
      // @TODO Extract this optimistic UI pattern into a reusable utility.
      this.optimisticState = checked;

      // Perform and await the actual update.
      await this.args.update?.(checked, event);
    } catch (error) {
      // In case of an error, rollback the optimistic state override and
      // delegate back to the `@check` argument.
      this.optimisticState = undefined;

      // Log the error to Sentry and save the unique event ID.
      const eventId = Sentry.captureException(error);

      // When running a dev build, also log the error to the console.
      if (macroCondition(isDevelopingApp())) {
        console.error(error);
      }

      // Show a modal to inform the user that something has gone wrong. The user
      // can not dismiss or show more details.
      // @TODO We should generalize this, so that we can make use of it across
      // the application and dramatically level up our error handling UX. We
      // need a nicer wrapper, UI / UX, and the possibility to conveniently
      // forward specific Sentry event IDs and more details to the service team
      // and / or bug tracker.
      const { value } = (await this.modals.confirm({
        cancelButtonTitle: this.intl.t(
          'native.settings.toggle.unknown_error.dismiss',
        ),
        okButtonTitle: this.intl.t(
          'native.settings.toggle.unknown_error.more_details',
        ),
        message: this.intl.t('native.settings.toggle.unknown_error.message', {
          eventId,
        }),
        title: this.intl.t('native.settings.toggle.unknown_error.title'),
      })) as ConfirmResult;

      // If dismissed, stop.
      if (!value) {
        return;
      }

      // For more details, show another modal that includes the Sentry event ID
      // for reference, e.g. when contacting the service team, plus the full
      // error as JSON.
      await this.modals.alert({
        title: this.intl.t('native.settings.toggle.error_details.title', {
          eventId,
        }),
        message: error ? JSON.stringify(error, undefined, 2) : String(error),
      });
    } finally {
      // In any case, including Task cancellation, make sure to always rollback
      // the optimistic state override, to restore normal toggle button
      // functionality.
      this.optimisticState = undefined;
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Shell::SettingsRoute::Toggle': typeof ShellSettingsRouteToggleComponent;
    'shell/settings-route/toggle': typeof ShellSettingsRouteToggleComponent;
  }
}
