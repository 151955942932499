/* eslint-disable ember/classic-decorator-hooks */
import type { Lead, User } from '@clark-utils/enums-and-types';
import { ResponseError } from '@clarksource/ember-api/errors';
import { UNAUTHORIZED } from '@clarksource/ember-api/status-codes';
import Evented from '@ember/object/evented';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { DateTime } from 'luxon';

/**
 * Session service
 *
 * This is a hack to shadows and mimics SessionService from ember-simple-auth as
 * much as possible as of today.
 *
 * At the moment, we have a dependency to our authentication mechanism, which is
 * on the roadmap for A&M. Once its there, we can use ESA and make this service
 * extend the one from ESA. We keep our own customizations (e.g. currentUser)
 * but provide adapters for auth mechanisms.
 */
export default class SessionService extends Service.extend(Evented) {
  @service declare api: Services['api'];
  @service declare authEventManager: Services['auth-event-manager'];
  @service declare localStorage: Services['local-storage'];
  @service declare userEventManager: Services['user-event-manager'];

  @tracked isAuthenticated = false;

  @tracked currentUser?: User;

  get currentMandate() {
    return this.currentUser?.mandate;
  }

  init(props: object | undefined) {
    super.init(props);

    // @ts-expect-error: Property 'on' does not exist on type 'AuthEventManagerService'
    this.authEventManager.on('didLogin', this.authenticated.bind(this));
    // @ts-expect-error: Property 'on' does not exist on type 'AuthEventManagerService'
    this.authEventManager.on('didLogout', this.unauthenticated.bind(this));
    // @ts-expect-error: Property 'on' does not exist on type 'UserEventManagerService'
    this.userEventManager.on('didRegister', this.authenticated.bind(this));
  }

  willDestroy() {
    super.willDestroy();

    if (
      !(this.authEventManager.isDestroying || this.authEventManager.isDestroyed)
    ) {
      // @ts-expect-error: Property 'off' does not exist on type 'AuthEventManagerService'
      this.authEventManager.off('didLogin', this.authenticated.bind(this));
      // @ts-expect-error: Property 'off' does not exist on type 'AuthEventManagerService'
      this.authEventManager.off('didLogout', this.unauthenticated.bind(this));
    }

    if (
      !(this.userEventManager.isDestroying || this.userEventManager.isDestroyed)
    ) {
      // @ts-expect-error: Property 'off' does not exist on type 'UserEventManagerService'
      this.authEventManager.off('didRegister', this.authenticated.bind(this));
    }
  }

  authenticated() {
    return new Promise((resolve) => {
      let instance;
      if (this.checkAuthentication.isRunning) {
        instance = this.checkAuthentication.last;
      } else {
        instance = this.checkAuthentication.perform();
      }

      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      instance.then(() => {
        // @ts-expect-error TS(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
        resolve();
      });
    });
  }

  unauthenticated() {
    this.isAuthenticated = false;
    this.currentUser = undefined;

    // @ts-expect-error: Property 'trigger' does not exist on type 'SessionService'
    this.trigger('invalidationSucceeded');
  }

  checkAuthentication = dropTask(async () => {
    await this.load.perform();

    if (this.isAuthenticated) {
      // @ts-expect-error: Property 'trigger' does not exist on type 'SessionService'
      this.trigger('authenticationSucceeded');
    }
  });

  triggerMandateCreated() {
    // @ts-expect-error: Property 'trigger' does not exist on type 'SessionService'
    this.trigger('mandateCreated');
  }

  /**
   * To get the current user for the mandate funnel and solely for it
   *
   * The black magic this method does:
   * - Fetches from the /current_user endpoint
   * - Applies hacks from user service
   * - Applies hacks from mandate/user service
   * - Applies hacks from application.index route
   * - Loads the partner config (if given)
   */
  load = dropTask(async () => {
    let response: { user: User } | { lead: Lead };

    try {
      response = await this.api.get('current_user');
    } catch (error) {
      if (
        error instanceof ResponseError &&
        error.response.status === UNAUTHORIZED
      ) {
        this.isAuthenticated = false;
        return;
      }
      throw error;
    }

    this.isAuthenticated = 'user' in response;
    const userOrLead = 'user' in response ? response.user : response.lead;

    // route: index
    // enforce userOrLead.mandate.info (primarily for easier testing, no other reason):
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!userOrLead.mandate) {
      // @ts-expect-error TS(2740) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message
      userOrLead.mandate = {};
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!userOrLead.mandate.info) {
      // @ts-expect-error TS(2741) FIXME: Property 'wizard_steps' is missing in type '{}' bu... Remove this comment to see the full error message
      userOrLead.mandate.info = {};
    }

    // merge with local storage info
    const info = this.localStorage.getAttr('mandate', 'info', {}) || {};
    Object.assign(userOrLead.mandate.info, info);

    // service: mandate/user
    // @ts-expect-error TS(2339) FIXME: Property 'installation_id' does not exist on type ... Remove this comment to see the full error message
    userOrLead.lead = userOrLead.installation_id !== undefined;

    const { birthdate } = userOrLead.mandate;

    if (birthdate) {
      userOrLead.mandate.birthdate =
        DateTime.fromISO(birthdate).toFormat('dd.MM.yyyy');
    }

    // service: user
    userOrLead.lead = 'installation_id' in userOrLead;

    // @ts-expect-error TS(2322) FIXME: Type 'User | Lead' is not assignable to type 'User... Remove this comment to see the full error message
    this.currentUser = userOrLead;

    return userOrLead;
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    session: SessionService;
  }
}
