import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Shell::SettingsRoute::Toggle\n  @label={{this.label}}\n  @checked={{this.shellSettingsBiometricAuth.isEnabled}}\n  @update={{this.update}}\n  @disabled={{not this.shellSettingsBiometricAuth.isAvailable}}\n/>", {"contents":"<Shell::SettingsRoute::Toggle\n  @label={{this.label}}\n  @checked={{this.shellSettingsBiometricAuth.isEnabled}}\n  @update={{this.update}}\n  @disabled={{not this.shellSettingsBiometricAuth.isAvailable}}\n/>","moduleName":"@clarksource/client/components/shell/settings-route/biometric-auth-toggle.hbs","parseOptions":{"srcName":"@clarksource/client/components/shell/settings-route/biometric-auth-toggle.hbs"}});
import type { BiometricAuthSettingsService } from '@clark-shell/ember';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import Component from '@glimmer/component';

interface ShellSettingsRouteBiometricAuthToggleSignature {
  Args: {};
}

export default class ShellSettingsRouteBiometricAuthToggleComponent extends Component<ShellSettingsRouteBiometricAuthToggleSignature> {
  @service declare intl: Services['intl'];

  @service('shell/settings/biometric-auth')
  declare shellSettingsBiometricAuth: BiometricAuthSettingsService;

  get label(): string {
    const { authMethod } = this.shellSettingsBiometricAuth;

    return this.intl.t(`native.settings.options.login_with.${authMethod}`);
  }

  @action update(isChecked: boolean): Promise<boolean> {
    return this.shellSettingsBiometricAuth.toggle(isChecked);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Shell::SettingsRoute::BiometricAuthToggle': typeof ShellSettingsRouteBiometricAuthToggleComponent;
    'shell/settings-route/biometric-auth-toggle': typeof ShellSettingsRouteBiometricAuthToggleComponent;
  }
}
