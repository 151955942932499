import type { User } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { MandateStep } from '@clarksource/client/services/mandate-state';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class MandateCockpitPreviewRoute extends ClarkRoute {
  @service declare mandateState: Services['mandate-state'];

  async beforeModel(...args: any) {
    // @ts-expect-error: A spread argument must either have a tuple type or be passed to a rest parameter
    super.beforeModel(...args);

    const user = this.modelFor('index') as User;

    this.mandateState.gotoMissingStep(user, MandateStep.CockpitPreview);
  }

  model() {
    return {
      user: this.modelFor('index') as User,
    };
  }

  @action
  didTransition() {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    super.actions.didTransition.call(this, false);
  }
}
