import { getOwner } from '@ember/owner';
import * as Sentry from '@sentry/browser';

/**
 * Overrides the missing translation key functionality in ember-intl.
 * Logs a sentry error log for each missing translation per route
 */
export class TranslationMissingError extends Error {
  key: string;

  route: string;

  name = 'TranslationMissingError';

  constructor(translationKey: string, route: string) {
    super();
    this.key = translationKey;
    this.route = route;
  }

  get message() {
    return `Translation Key missing: ${this.key} for Route: ${this.route}`;
  }
}

export default function missingMessage(key: string) {
  // @ts-expect-error TS(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  const owner = getOwner(this);
  // @ts-expect-error: 'owner' is possibly 'undefined'
  const routerService = owner.lookup('service:router');

  const error = new TranslationMissingError(
    key,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    routerService?.currentRouteName as string,
  );

  Sentry.withScope((scope) => {
    scope.setExtras({
      message: error.message,
      name: error.name,
    });

    Sentry.captureException(error);
  });
}
