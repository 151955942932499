import EmberObject from '@ember/object';

export default {
  emberize(object: any) {
    // don't do anything to objects that have already been emberized
    if (object instanceof EmberObject) {
      return object;
    }

    if (typeof object === 'object') {
      // recursively emberize the objects
      Object.keys(object).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          const property = object[key];

          if (Array.isArray(property)) {
            // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
            object[key].forEach((item, index) => {
              if (typeof item === 'object' && item !== null) {
                object[key][index] = this.emberize(item);
              }
            });
          } else if (typeof property === 'object' && property !== null) {
            object[key] = this.emberize(object[key]);
          }
        }
      });
    }

    return EmberObject.create(object);
  },
};
