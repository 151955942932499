import { CustomerState } from '@clark-customer/entities';
import Route from '@ember/routing/route';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class DemandcheckRoute extends Route {
  @service declare customer: Services['customer'];
  @service declare experiments: Services['experiments'];
  @service declare router: Services['router'];
  @service declare settings: Services['settings'];

  get isPartOfOpenBuyingJourneyExperiment(): boolean {
    return (
      this.experiments.hasVariant('2021Q3OpenBuyingJourney') &&
      this.experiments.getVariant('2021Q3OpenBuyingJourney') === 'v1'
    );
  }

  async beforeModel() {
    const isClark2User =
      this.experiments.getVariant('business-strategy') === 'clark2';

    if (this.settings.getSetting('clark2') && isClark2User) {
      await this.customer.createProspectIfNotExists();
    }

    if (this.isPartOfOpenBuyingJourneyExperiment) {
      const state = await this.customer.getState();

      // Prevent onboarding prospect customers
      // from doing demandcheck
      if (state === CustomerState.Prospect) {
        this.router.replaceWith('recommendations');
      }
    }
  }
}
