import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

interface CustomerCurrentConfigsData {
  bankConnectModalHidden: boolean;
}

export default class MandateCustomerCurrentConfigsService extends Service {
  @service declare api: Services['api'];

  @tracked
  bankConnectModalSkipped = false;

  async getCustomerCurrentConfigsData(): Promise<CustomerCurrentConfigsData> {
    return (await this.fetchCustomerData.perform()) as CustomerCurrentConfigsData;
  }

  fetchCustomerData = task(async () => {
    const response = await this.api.get(
      `customer/current/configs?types=bank_connect`,
      {
        deserializeJSONAPI: true,
      },
    );

    return response;
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'mandate/customer-current-configs': MandateCustomerCurrentConfigsService;
  }
}
