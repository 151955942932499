import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-action no-builtin-form-components no-invalid-interactive no-triple-curlies }}\n<div local-class=\"terms {{if @hasError 'error'}}\" ...attributes>\n  <div\n    data-terms-checkbox={{@ident}}\n    class=\"custom-checkbox\"\n    local-class=\"checkbox\"\n    onclick={{action @toggle}}\n  >\n    <Input\n      @checked={{@termsAccepted}}\n      @type=\"checkbox\"\n      id={{@ident}}\n    />\n    <CheckboxLabel @isSelected={{@termsAccepted}} />\n  </div>\n\n  <label local-class=\"label\" for={{@ident}}>\n    {{{@label}}}\n  </label>\n</div>", {"contents":"{{! template-lint-disable no-action no-builtin-form-components no-invalid-interactive no-triple-curlies }}\n<div local-class=\"terms {{if @hasError 'error'}}\" ...attributes>\n  <div\n    data-terms-checkbox={{@ident}}\n    class=\"custom-checkbox\"\n    local-class=\"checkbox\"\n    onclick={{action @toggle}}\n  >\n    <Input\n      @checked={{@termsAccepted}}\n      @type=\"checkbox\"\n      id={{@ident}}\n    />\n    <CheckboxLabel @isSelected={{@termsAccepted}} />\n  </div>\n\n  <label local-class=\"label\" for={{@ident}}>\n    {{{@label}}}\n  </label>\n</div>","moduleName":"@clarksource/client/components/mandate/mandate-confirming/terms-checkbox.hbs","parseOptions":{"srcName":"@clarksource/client/components/mandate/mandate-confirming/terms-checkbox.hbs"}});
import templateOnlyComponent from '@ember/component/template-only';

interface MandateMandateConfirmingTermsCheckboxSignature {
  Args: {
    hasError?: boolean;
    ident: string;
    label: string;
    termsAccepted: boolean;
    toggle: () => void;
  };
  Element: HTMLDivElement;
}

const MandateMandateConfirmingTermsCheckboxComponent =
  templateOnlyComponent<MandateMandateConfirmingTermsCheckboxSignature>();

export default MandateMandateConfirmingTermsCheckboxComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Mandate::MandateConfirming::TermsCheckbox': typeof MandateMandateConfirmingTermsCheckboxComponent;
    'mandate/mandate-confirming/terms-checkbox': typeof MandateMandateConfirmingTermsCheckboxComponent;
  }
}
