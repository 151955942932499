import { CustomerState } from '@clark-customer/entities';
import { customerWireStateToCustomerState } from '@clark-customer/entities/transforms';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import * as Sentry from '@sentry/browser';
import { restartableTask } from 'ember-concurrency';

export default class SignUpThankYouRouteController extends Controller {
  @service declare api: Services['api'];
  @service declare mandateState: Services['mandate-state'];
  @service declare router: Services['router'];
  @service declare session: Services['session'];

  queryParams = ['confirmation_token'];

  confirmation_token?: string;

  confirmSignUp = restartableTask(async () => {
    try {
      await this.api.patch('customer/marketing_consent', {
        confirmation_token: this.confirmation_token,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  });

  @action
  continueToApp(): void {
    if (this.isMandateOrSelfServiceCustomer) {
      this.router.transitionTo('index.manager');
      return;
    }

    if (this.isProspectCustomer) {
      this.router.transitionTo('customer.registration');
      return;
    }

    /**
     * Since the user has not completed the signup process, take them to the next
     * step in the mandate funnel
     */
    this.router.transitionTo(this.mandateState.nextStepRoute());
  }

  private get isMandateOrSelfServiceCustomer(): boolean {
    const state = customerWireStateToCustomerState(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'CustomerStateWireFormat | undefi... Remove this comment to see the full error message
      this.session.currentMandate?.customer_state,
    );
    return [CustomerState.Mandate, CustomerState.SelfService].includes(state);
  }

  private get isProspectCustomer(): boolean {
    const state = customerWireStateToCustomerState(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'CustomerStateWireFormat | undefi... Remove this comment to see the full error message
      this.session.currentMandate?.customer_state,
    );
    return [CustomerState.Prospect].includes(state);
  }
}
