/* eslint-disable ember/no-computed-properties-in-native-classes */
import type { CapacitorService, PlatformService } from '@clark-shell/ember';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';
import { compareVersions } from 'compare-versions';

export default class UpdateNativeAppService extends Service {
  @service declare config: Services['config'];
  @service('shell/platform') declare platform: PlatformService;
  @service('shell/capacitor') declare capacitor: CapacitorService;

  get isUpdateAvailable(): boolean {
    if (!this.platform.isNative) {
      return false;
    }

    // @ts-expect-error TS(2322) FIXME: Type 'string | boolean | string[] | { mins: number... Remove this comment to see the full error message
    const requiredVersion: string | null = this.config.getConfig(
      `nativeApp.${this.platform.operatingSystem}.minimumVersion`,
    );

    if (!requiredVersion) {
      return false;
    }

    return compareVersions(requiredVersion, this.capacitor.version) > 0;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'update-native-app': UpdateNativeAppService;
  }
}
