function easeInOutQuad(
  currentTime: number,
  startValue: number,
  changeInValue: number,
  duration: number,
): number {
  currentTime /= duration / 2;

  if (currentTime < 1) {
    return (changeInValue / 2) * currentTime * currentTime + startValue;
  }

  currentTime--;

  return (
    (-changeInValue / 2) * (currentTime * (currentTime - 2) - 1) + startValue
  );
}

/**
 * @returns {Promise}
 * @param {HTMLElement|Selector} Target
 * @param {Number} Duration
 */
export default function scrollTo(
  target: HTMLElement | string,
  offset = 0,
  duration = 500,
): Promise<void> {
  return new Promise((resolve, reject) => {
    let to;
    const element = document.scrollingElement;

    if (typeof target === 'string') {
      to = document.querySelector(target) || reject();
    } else {
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      to = target.getBoundingClientRect().top + element.scrollTop;
    }

    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    const start = element.scrollTop;
    // @ts-expect-error TS(2365) FIXME: Operator '+' cannot be applied to types 'number | ... Remove this comment to see the full error message
    const change = to + offset - start;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = function () {
      currentTime += increment;
      const value = easeInOutQuad(currentTime, start, change, duration);
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      element.scrollTop = value;
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        resolve();
      }
    };
    animateScroll();
  });
}
