import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"wrapper\">\n  <ul local-class=\"settings-section\">\n    <li\n      local-class=\"item\"\n      data-test-settings=\"Push Notifications\"\n    >\n      <Shell::SettingsRoute::PushNotificationsToggle />\n    </li>\n\n    <li local-class=\"item\" data-test-settings=\"User Tracking\">\n      <Shell::SettingsRoute::UserTrackingToggle />\n    </li>\n\n    <li\n      local-class=\"item\"\n      data-test-settings=\"Biometric Authentication\"\n    >\n      <Shell::SettingsRoute::BiometricAuthToggle />\n    </li>\n  </ul>\n</div>", {"contents":"<div local-class=\"wrapper\">\n  <ul local-class=\"settings-section\">\n    <li\n      local-class=\"item\"\n      data-test-settings=\"Push Notifications\"\n    >\n      <Shell::SettingsRoute::PushNotificationsToggle />\n    </li>\n\n    <li local-class=\"item\" data-test-settings=\"User Tracking\">\n      <Shell::SettingsRoute::UserTrackingToggle />\n    </li>\n\n    <li\n      local-class=\"item\"\n      data-test-settings=\"Biometric Authentication\"\n    >\n      <Shell::SettingsRoute::BiometricAuthToggle />\n    </li>\n  </ul>\n</div>","moduleName":"@clarksource/client/components/shell/settings-route.hbs","parseOptions":{"srcName":"@clarksource/client/components/shell/settings-route.hbs"}});
import templateOnlyComponent from '@ember/component/template-only';

interface ShellSettingsRouteSignature {
  Args: {};
}

const ShellSettingsRouteComponent =
  templateOnlyComponent<ShellSettingsRouteSignature>();

export default ShellSettingsRouteComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Shell::SettingsRoute': typeof ShellSettingsRouteComponent;
    'shell/settings-route': typeof ShellSettingsRouteComponent;
  }
}
