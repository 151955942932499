import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"ig__error--wrapper\">\n  {{#if @error}}\n    <div\n      data-test-form-error-message\n      class=\"ig__error-msg form-list__error\"\n    >\n      <span>{{@error}}</span>\n    </div>\n  {{/if}}\n</div>", {"contents":"<div class=\"ig__error--wrapper\">\n  {{#if @error}}\n    <div\n      data-test-form-error-message\n      class=\"ig__error-msg form-list__error\"\n    >\n      <span>{{@error}}</span>\n    </div>\n  {{/if}}\n</div>","moduleName":"@clarksource/client/components/error-message.hbs","parseOptions":{"srcName":"@clarksource/client/components/error-message.hbs"}});
import templateOnlyComponent from '@ember/component/template-only';

interface ErrorMessageSignature {
  Args: {
    error?: string;
  };
}

const ErrorMessageComponent = templateOnlyComponent<ErrorMessageSignature>();

export default ErrorMessageComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ErrorMessage: typeof ErrorMessageComponent;
    'error-message': typeof ErrorMessageComponent;
  }
}
