import Route from '@ember/routing/route';
import { type Registry as Services, service } from '@ember/service';

export default class AccessRoute extends Route {
  @service declare router: Services['router'];

  beforeModel() {
    // Need this for IOS app login
    // we need to trigger the hook on blank route
    // esp in case of failed login/register attempts.
    this.router.transitionTo('blank');
  }
}
