import { CustomerState } from '@clark-customer/entities';
import Route from '@ember/routing/route';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class RetirementCheckRoute extends Route {
  @service declare api: Services['api'];
  @service declare customer: Services['customer'];
  @service declare experiments: Services['experiments'];
  @service declare features: Services['features'];
  @service declare router: Services['router'];

  get isPartOfOpenBuyingJourneyExperiment(): boolean {
    return (
      this.experiments.hasVariant('2021Q3OpenBuyingJourney') &&
      this.experiments.getVariant('2021Q3OpenBuyingJourney') === 'v1'
    );
  }

  async beforeModel() {
    const isClark1User =
      this.experiments.getVariant('business-strategy') === 'control';

    if (isClark1User) {
      /*
       * TODO: eligibility needs to be checked from backend before
       * you can check if cockpit is accessible. Ideally, the eligibility
       * checking needs to happen in a common route up the route hierarchy.
       */
      await this.api.get('retirement/cockpit/eligible');

      if (!this.features.isEnabled('RETIREMENT_COCKPIT_ENABLED')) {
        this.router.replaceWith('index.manager');
      }
    }

    if (this.isPartOfOpenBuyingJourneyExperiment) {
      const state = await this.customer.getState();

      // Prevent onboarding prospect and self-service customers
      // from doing retirement check
      if (
        state === CustomerState.Prospect ||
        state === CustomerState.SelfService
      ) {
        this.router.replaceWith('index.retirement');
      }
    }
  }
}
