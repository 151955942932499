/* eslint-disable ember/no-computed-properties-in-native-classes */
import type { Offer, ProductDetails } from '@clark-utils/enums-and-types';
import { OfferIdent } from '@clark-utils/enums-and-types';
import Emberizer from '@clarksource/client/lifters/helpers/emberizer';
import { computed, defineProperty } from '@ember/object';
import type Owner from '@ember/owner';
import { setOwner } from '@ember/owner';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class ProductDetailsLifter {
  @service declare config: Services['config'];

  isApp = false;
  isGKV = false;
  isKfz = false;

  offer: Offer;
  product: ProductDetails;

  constructor(
    owner: Owner,
    offer: Offer,
    product: ProductDetails,
    isApp: boolean,
  ) {
    setOwner(this, owner);

    // as we copy the items, we need to emberize them, and return the modified result
    // this is a little gotcha!
    this.product = Emberizer.emberize(product) as unknown as ProductDetails;
    this.isApp = isApp;
    // we dont modify it, but lets just be sure
    this.offer = Emberizer.emberize(offer) as unknown as Offer;
  }

  // Public API
  public format(): ProductDetails {
    defineProperty(
      this.product,
      'firstMessage',
      computed('messages', this.setFirstMessage.bind(this)),
    );

    // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
    const firstMessage = this.product.get('firstMessage');
    // after we have the first message we can establish if KFZ / GKV
    this.isGKV = firstMessage && firstMessage.identifier === OfferIdent.Gkv;
    this.isKfz = firstMessage && firstMessage.identifier === OfferIdent.Kfz;

    defineProperty(
      this.product,
      'kfzCTA',
      computed('firstMessage', this.setKfzCTA.bind(this)),
    );
    defineProperty(
      this.product,
      'gkvCTA',
      computed('firstMessage', this.setGkvCTA.bind(this)),
    );
    defineProperty(
      this.product,
      'offerCTA',
      computed('firstMessage', this.setOfferCTA.bind(this)),
    );
    defineProperty(
      this.product,
      'questionnaireCTA',
      computed('category', this.setQuestionnaireCTA.bind(this)),
    );
    defineProperty(
      this.product,
      'showWasHelpful',
      computed('firstMessage', this.setShowWasHelpful.bind(this)),
    );

    // messenger CTA does not need to be computed
    // @ts-expect-error TS(2339) FIXME: Property 'set' does not exist on type 'ProductDeta... Remove this comment to see the full error message
    this.product.set(
      'messengerCTA',
      this.config.getConfig('messenger') && this.isApp,
    );

    // the main 'hasCTAs' flag needs to be computed on all out computed flags!
    defineProperty(
      this.product,
      'hasCTAs',
      computed(
        'kfzCTA',
        'gkvCTA',
        'offerCTA',
        'questionnaireCTA',
        'messengerCTA',
        this.setHasCTAs.bind(this),
      ),
    );

    defineProperty(
      this.product,
      'showDoneQuestionnaire',
      computed('firstMessage', this.setShowDoneQuestionnaire.bind(this)),
    );
    defineProperty(
      this.product,
      'showMessageText',
      computed('firstMessage', this.setShowMessageText.bind(this)),
    );
    defineProperty(
      this.product,
      'showExpertAdvice',
      computed('firstMessage', this.setShowExpertAdvice.bind(this)),
    );
    defineProperty(
      this.product,
      'showFeedbackSection',
      computed('category', this.setShowFeedbackSection.bind(this)),
    );
    defineProperty(
      this.product,
      'showDocuments',
      computed(
        'documents_pending',
        'policy_section',
        this.setShowDocuments.bind(this),
      ),
    );

    // the flag that controls if we even show the messages section, depends on all that can appear in the top section
    // uncluding all the crazy logic for the CTA's
    defineProperty(
      this.product,
      'showMessagesSection',
      computed(
        'showDoneQuestionnaire',
        'showMessageText',
        'showExpertAdvice',
        'showFeedbackSection',
        this.setShowMessagesSection.bind(this),
      ),
    );

    return this.product;
  }

  // Private internal functions for setting flags
  private setShowWasHelpful(): boolean {
    // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
    return !this.isGKV && this.product.get('firstMessage').helpful === null;
  }

  private setShowDocuments(): boolean {
    let showSectionIfNone = true;
    // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
    if (this.product.get('documents_pending')) {
      // @ts-expect-error TS(2322) FIXME: Type 'string | boolean | string[] | BrandAgent | R... Remove this comment to see the full error message
      showSectionIfNone = this.config.getConfig('automaticDocumentProcessing');
    }
    // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
    return Boolean(this.product.get('policy_section') && showSectionIfNone);
  }

  private setShowDoneQuestionnaire(): boolean {
    return Boolean(
      this.config.getConfig('productDetails.questionnaireStatus') &&
        this.product.customer_finished_questionnaire,
    );
  }

  private setShowMessageText(): boolean {
    return Boolean(
      this.config.getConfig('productDetails.messages') &&
        // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
        this.product.get('firstMessage') &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        this.offer.id === undefined,
    );
  }

  private setShowExpertAdvice(): boolean {
    return Boolean(
      this.product.offer_id !== null &&
        this.config.getConfig('productDetails.expertAdvices'),
    );
  }

  private setShowFeedbackSection(): boolean {
    return Boolean(
      this.config.getConfig('productDetails.giveFeedback') &&
        // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
        this.product.get('showWasHelpful') &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        this.offer.id === undefined,
    );
  }

  private setFirstMessage() {
    const [messages] = this.product.messages;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return messages || false;
  }

  private setKfzCTA(): boolean {
    return Boolean(
      this.isKfz &&
        // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
        this.product.get('firstMessage').cta_link !== null &&
        // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
        this.product.get('firstMessage').cta_link !== '' &&
        this.config.getConfig('automatedOffers.kfz'),
    );
  }

  private setGkvCTA(): boolean {
    return Boolean(
      this.isGKV &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        this.product.open_offer_id !== null &&
        this.config.getConfig('automatedOffers.gkv'),
    );
  }

  private setOfferCTA(): boolean {
    return Boolean(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      this.product.offer_cta !== null &&
        this.config.getConfig('productDetails.offerCta') &&
        !this.isGKV &&
        !this.isKfz &&
        this.config.getConfig('companyIdent') !== this.product.company.ident,
    );
  }

  private setQuestionnaireCTA(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this.product.category.questionnaire) {
      return false;
    }
    return Boolean(
      this.product.category.questionnaire.identifier &&
        this.config.getConfig('productDetails.canDoQuestionnaire'),
    );
  }

  private setHasCTAs(): boolean {
    return (
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
      this.product.get('kfzCTA') ||
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
      this.product.get('gkvCTA') ||
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
      this.product.get('offerCTA') ||
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
      this.product.get('messengerCTA') ||
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
      this.product.get('questionnaireCTA')
    );
  }

  // decide if to show the entire messages section, depends on how all the other flags went
  private setShowMessagesSection(): boolean {
    return (
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
      this.product.get('showDoneQuestionnaire') ||
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
      this.product.get('showMessageText') ||
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
      this.product.get('showExpertAdvice') ||
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'ProductDeta... Remove this comment to see the full error message
      this.product.get('showFeedbackSection')
    );
  }
}
