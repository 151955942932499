import type { User } from '@clark-utils/enums-and-types';
import { Partner } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { action } from '@ember/object';
import { type Registry as Services, service } from '@ember/service';

type Model = {
  mamAccountNumber?: string;
  partner?: Partner;
};

export default class IndexMandateIndexMamRoute extends ClarkRoute {
  @service declare router: Services['router'];

  model() {
    const user = this.modelFor('index') as User;

    return {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      mamAccountNumber: user.mandate?.loyalty?.mam?.mmAccountNumber,
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      partner: user.mandate?.partner,
    };
  }

  afterModel(model: Model) {
    if (model.partner !== Partner.milesAndMore) {
      this.router.transitionTo('index.mandate.index');
    }
  }

  @action
  didTransition() {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    super.actions.didTransition.call(this, false);
  }
}
