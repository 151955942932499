/* eslint-disable ember/classic-decorator-no-classic-methods */
import type { Category, Company } from '@clark-utils/enums-and-types';
import { set } from '@ember/object';
import Service, { type Registry as Services, service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export interface CategoryItem {
  active: boolean;
  category: Category;
  company: Company;
  notFoundType: boolean;
}

export default class SelectCategoryService extends Service {
  @tracked
  selectedCategories: CategoryItem[] = [];

  @tracked
  searchText = '';

  @tracked
  placeholderId: string | undefined;

  @service declare features: Services['features'];

  itemBeingModified: CategoryItem | {} = {};

  redirect = '';

  afterCreateRedirect = '';

  mutating = false;

  notFoundCount = 0;

  setRedirect(redirect: string) {
    set(this, 'redirect', redirect);
  }

  getRedirect() {
    return this.redirect === '' ? false : this.redirect;
  }

  setPlaceholderId(placeholderId: string) {
    this.placeholderId = placeholderId;
  }

  getPlaceholderId() {
    return this.placeholderId;
  }

  setSearchText(searchText: string) {
    this.searchText = searchText;
  }

  getSearchText(reset: boolean) {
    const { searchText } = this;
    if (reset) {
      this.searchText = '';
    }
    return searchText;
  }

  setAfterCreateRedirect(redirect: string) {
    set(this, 'afterCreateRedirect', redirect);
  }

  getAfterCreateRedirect() {
    return this.afterCreateRedirect === '' ? false : this.afterCreateRedirect;
  }

  addItem(category: Category | string) {
    if (category === 'notFound') {
      const { notFoundCount } = this;
      // create an ident to match when modifying, and flag to set as different style
      this.set('itemBeingModified', {
        category: {
          id: `notFound-${notFoundCount + 1}`,
          notFoundType: true,
        },
      });
      set(this, 'notFoundCount', notFoundCount + 1);
    } else {
      set(this, 'itemBeingModified', { category });
    }
    set(this, 'mutating', false);
  }

  uncheck(item: CategoryItem) {
    this.selectedCategories.forEach((selected) => {
      if (this.hasMatchingIdentifiers(selected, item)) {
        set(selected, 'active', false);
      }
    });
  }

  removeItem(item: CategoryItem) {
    // @ts-expect-error: Property 'removeObject' does not exist on type 'CategoryItem[]'
    this.selectedCategories.removeObject(item);
  }

  clearItems() {
    set(this, 'selectedCategories', []);
  }

  modifyItem(category: Category) {
    // find the company that is already selected to form an identifier
    this.selectedCategories.forEach((selected) => {
      if (category.id === selected.category.id) {
        const { itemBeingModified } = this;

        set(itemBeingModified, 'company', selected.company);
        set(itemBeingModified, 'category', category);
      }
    });
    set(this, 'mutating', true);
  }

  saveItem(company: Company) {
    const { selectedCategories } = this;
    const { itemBeingModified } = this;

    if (this.mutating) {
      // making a modification to an existing selected category
      selectedCategories.forEach((selected) => {
        // @ts-expect-error TS(2345) FIXME: Argument of type '{} | CategoryItem' is not assign... Remove this comment to see the full error message
        if (this.hasMatchingIdentifiers(selected, itemBeingModified)) {
          set(selected, 'company', company);
        }
      });
    } else {
      // create a var (assuming we created the cat company) so we can check if it
      // already exists
      const duplicateToCheck = itemBeingModified;

      // @ts-expect-error TS(2339) FIXME: Property 'company' does not exist on type '{} | Ca... Remove this comment to see the full error message
      duplicateToCheck.company = company;
      let duplicateFound = false;

      // check existing companies and cats to make sure the identifier is not there
      selectedCategories.forEach((selected) => {
        // @ts-expect-error TS(2345) FIXME: Argument of type '{} | CategoryItem' is not assign... Remove this comment to see the full error message
        if (this.hasMatchingIdentifiers(selected, duplicateToCheck)) {
          set(this, 'itemBeingModified', {});

          duplicateFound = true;

          if (this.features.isEnabled('SINGLE_CATEGORY_INQUIRY')) {
            duplicateFound = false;
          }
        }
      });

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!duplicateFound) {
        // insert company data for a new selection
        // @ts-expect-error: Property 'pushObject' does not exist on type 'CategoryItem[]'
        selectedCategories.pushObject({
          active: true,

          // @ts-expect-error TS(2339) FIXME: Property 'category' does not exist on type '{} | C... Remove this comment to see the full error message
          category: itemBeingModified.category,
          company,

          // @ts-expect-error TS(2339) FIXME: Property 'category' does not exist on type '{} | C... Remove this comment to see the full error message
          notFoundType: itemBeingModified.category.notFoundType || false,
        });
      }
    }

    // save back to component
    set(this, 'selectedCategories', selectedCategories);

    // unset the temp item
    set(this, 'itemBeingModified', {});
  }

  getSelected() {
    return this.selectedCategories;
  }

  getTemp() {
    return this.itemBeingModified;
  }

  hasMatchingIdentifiers(a: CategoryItem, b: CategoryItem) {
    return a.category.id === b.category.id && a.company.id === b.company.id;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'select-category': SelectCategoryService;
  }
}
