import type { User } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

interface MandateIndexModel {
  user: User;
}

export default class MandateIndex extends ClarkRoute {
  @service declare mandateState: Services['mandate-state'];
  @service declare router: Services['router'];

  model() {
    return {
      user: this.modelFor('index') as User,
    };
  }

  redirect(model: MandateIndexModel): void {
    const currentMandateState = model.user.mandate.state;
    if (
      currentMandateState === 'not_started' ||
      currentMandateState === 'in_creation'
    ) {
      // if there is an adjust prop on the user obj we need to check the network,
      // as we may transition elsewhere
      this.router.transitionTo(this.mandateState.nextStepRoute());
    } else {
      this.router.transitionTo('index.manager');
    }
  }

  @action
  didTransition() {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    super.actions.didTransition.call(this, false);
  }
}
