/* eslint-disable ember/classic-decorator-hooks */
import type { PlatformService } from '@clark-shell/ember';
import StubAdapter from '@clarksource/messenger/connection/stub/stub-adapter';
import BaseMessengerService from '@clarksource/messenger/services/messenger';
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { isTesting, macroCondition } from '@embroider/macros';

export default class MessengerService extends BaseMessengerService {
  @service declare api: Services['api'];
  @service('config') declare appConfig: Services['config'];
  @service declare session: Services['session'];
  @service declare settings: Services['settings'];

  @service('shell/platform') declare platform: PlatformService;

  init(props: object | undefined) {
    super.init(props);

    // hook into auth system
    // @ts-expect-error: Property 'on' does not exist on type 'SessionService'
    this.session.on('authenticationSucceeded', this.authenticated.bind(this));
    // @ts-expect-error: Property 'on' does not exist on type 'SessionService'
    this.session.on('invalidationSucceeded', this.invalidated.bind(this));

    // to make sure we run check authentication (in case it wasn't already run)
    this.session.checkAuthentication.perform();

    this.setupHeartbeatListeners();
  }

  /**
   * Handler for the "authenticated" system event
   *
   * Will check if eligible to use the messenger, will fetch the connection
   * details and connect to the messenger service.
   */
  async authenticated() {
    if (!this.appConfig.getConfig('messenger')) {
      return;
    }

    if (!this.session.currentUser || !this.session.isAuthenticated) {
      return;
    }

    const mandateId = this.session.currentUser.mandate.id;

    if (macroCondition(isTesting())) {
      await this.connect(
        `${mandateId}@messenger.clark.de`,
        {},
        new StubAdapter(),
      );
    } else {
      const { token } = (await this.api.post('messages/token')) as {
        token: string;
      };
      const messengerUrl =
        this.settings.getSetting('socket_server') || 'wss://socket.clark.de';

      // Testing IE/from other machines:
      //
      // if you have to test IE, then manually set the url to the IP of your
      // machine during testing.
      //
      // Important:
      // -> Do not push this line!
      // -> Comment out before!
      // -> You will owe a beer to the person spotting it!
      // const messengerUrl = 'ws://192.168.2.*:8801/';

      const resource = `${this.platform.isNative ? 'app' : 'web'}-${
        this.isMobile() ? 'mobile' : 'desktop'
      }`;

      await this.runConnect.perform(
        `${mandateId}@messenger.clark.de/${resource}`,
        {
          url: messengerUrl + token,
          api: this.api,
        },
      );
    }
  }

  private isMobile() {
    const isAndroid: boolean = /Android/.test(window.navigator.userAgent);

    const isIOS: boolean =
      /iPad|iPhone|iPod|iWatch/.test(window.navigator.userAgent) &&
      !/IEMobile/.test(window.navigator.userAgent);

    return isIOS || isAndroid;
  }

  private setupHeartbeatListeners() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    document.addEventListener('pageshow', this.tryReconnect);

    registerDestructor(this, () => {
      document.removeEventListener(
        'visibilitychange',
        this.handleVisibilityChange,
      );
      document.removeEventListener('pageshow', this.tryReconnect);
    });
  }

  @action
  private handleVisibilityChange() {
    if (document.visibilityState === 'visible') {
      this.tryReconnect();
    }
  }

  @action
  private tryReconnect() {
    if (!this.connected && this.connection.online) {
      this.authenticated();
    }
  }

  /**
   * Handler for the "invalidated" system event
   *
   * Will disceonnect from the messenger service
   */
  invalidated() {
    this.disconnect();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    // @ts-expect-error TS(2717) FIXME: Subsequent property declarations must have the sam... Remove this comment to see the full error message
    messenger: MessengerService;
  }
}
