import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class SignupWithPromocode extends Route {
  @service declare router: Services['router'];
  @service declare customer: Services['customer'];
  @service declare experiments: Services['experiments'];
  @service declare localStorage: Services['local-storage'];
  @service declare session: Services['session'];
  @service declare mandateState: Services['mandate-state'];

  queryParams = {
    promo_code: { replace: false },
  };

  redirectToMandateFunnel() {
    const nextStep = this.mandateState.nextStepRoute();
    this.router.transitionTo(nextStep);
  }

  async beforeModel(transition: Transition): Promise<void> {
    if (this.session.load.isRunning) {
      // first try to load the session since the task might be running when the
      // page is loaded directly
      await this.session.load.last;
    }

    const { currentUser } = this.session;

    if (currentUser) {
      this.router.transitionTo('index');
      return;
    }

    const { promo_code } = transition.to?.queryParams as Record<
      string,
      unknown
    >;

    if (promo_code) {
      this.localStorage.setData('voucher-promo-code', promo_code);
      this.experiments.forceVariant(
        '2024Q3IncentiveInfoMandateFunnel',
        'control',
      );
    }
  }

  async afterModel(): Promise<void> {
    await this.customer.createAnonymousLead();
    this.redirectToMandateFunnel();
  }
}
