import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { type Registry as Services, service } from '@ember/service';

export default class IndexManagerPreDemandcheckRoute extends ClarkRoute {
  @service declare router: Services['router'];

  // @ts-expect-error TS(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
  beforeModel(...args) {
    // @ts-expect-error: A spread argument must either have a tuple type or be passed to a rest parameter
    super.beforeModel(...args);

    // This is just added as a backup in case some old links (e.g. emails) have this route.
    this.router.transitionTo('index.demandcheck.index.intro');
  }
}
