import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Customer::ReferralPage::Version1\n  @campaignDescription={{this.campaignDescription}}\n  @inviteeTagline={{this.inviteeTagline}}\n  @inviterTagline={{this.inviterTagline}}\n  @isLinkCopied={{this.isReferralLinkCopied}}\n  @onCopyLink={{this.copyReferralLink}}\n  @onShare={{this.shareOnMobile}}\n  @referralAmount={{this.formattedReferralBonus}}\n  @referralLink={{this.referralLink}}\n  @termsLink={{this.tncLink}}\n  @invitees={{@invitees}}\n  data-test-optimised-referral-page\n/>", {"contents":"<Customer::ReferralPage::Version1\n  @campaignDescription={{this.campaignDescription}}\n  @inviteeTagline={{this.inviteeTagline}}\n  @inviterTagline={{this.inviterTagline}}\n  @isLinkCopied={{this.isReferralLinkCopied}}\n  @onCopyLink={{this.copyReferralLink}}\n  @onShare={{this.shareOnMobile}}\n  @referralAmount={{this.formattedReferralBonus}}\n  @referralLink={{this.referralLink}}\n  @termsLink={{this.tncLink}}\n  @invitees={{@invitees}}\n  data-test-optimised-referral-page\n/>","moduleName":"@clarksource/client/components/invite-friend.hbs","parseOptions":{"srcName":"@clarksource/client/components/invite-friend.hbs"}});
import type { ReferralCampaignData } from '@clark-customer/services/services/customer';
import { makeReferralCampaignResource } from '@clark-home/ui/resources/referral-campaign';
import type { AllPlugins, PlatformService } from '@clark-shell/ember';
import { plugin } from '@clark-shell/ember';
import { uiFormatMoneyLegacy } from '@clark-ui/format/utils/ui/format/money-legacy';
import type { Query } from '@clark-utils/ember-query';
import type { Money, User } from '@clark-utils/enums-and-types';
import { RatingModalTrigger } from '@clark-utils/enums-and-types';
import { action } from '@ember/object';
import type Owner from '@ember/owner';
import { later } from '@ember/runloop';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { isTesting, macroCondition } from '@embroider/macros';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import * as Sentry from '@sentry/browser';
import { dropTask } from 'ember-concurrency';
import { runTask } from 'ember-lifeline';

const SHOW_DURATION = macroCondition(isTesting()) ? 1 : 3000;

interface InviteFriendSignature {
  Args: {
    invitees: any[];
    user: User;
  };
  Element: HTMLFormElement;
}

export default class InviteFriendComponent extends Component<InviteFriendSignature> {
  @plugin private declare readonly share: AllPlugins['Share'];

  @service declare api: Services['api'];
  @service declare intl: Services['intl'];
  @service declare tracking: Services['tracking'];

  @service declare experiments: Services['experiments'];
  @service declare query: Services['query'];

  @service('rating/rating') declare rating: Services['rating/rating'];

  @service('shell/platform') declare platform: PlatformService;

  @service declare notificationBar: Services['notification-bar'];

  @tracked formErrors? = {};

  @tracked isReferralLinkCopied = false;
  @tracked hasRatingTriggerBeenHit = false;
  @tracked email = '';

  constructor(owner: Owner, args: InviteFriendSignature['Args']) {
    super(owner, args);

    runTask(
      this,
      () => {
        if (!this.referralAmount) {
          this.referralCampaignResource.fetch();
        }
      },
      100,
    );
  }

  get referralLink(): string {
    return this.isInviteeLinkExperiment
      ? `${document.location.origin}/clark-app/referral/?code=${this.args.user.referral_code}`
      : `${document.location.origin}/de/invitation/${this.args.user.referral_code}`;
  }

  get isInviteeLinkExperiment(): boolean {
    return this.experiments.getVariant('2024Q3InviteeLandingPage') === 'v1';
  }

  get inviterTagline(): string {
    return (
      this.dynamicInviterTagline ??
      this.intl.t('customer.referral-page.inviter-tagline', {
        amount: this.formattedReferralBonus,
      })
    );
  }

  get inviteeTagline(): string {
    return (
      this.dynamicInviteeTagline ??
      this.intl.t('customer.referral-page.invitee-tagline', {
        amount: this.formattedReferralBonus,
      })
    );
  }

  get campaignDescription(): string {
    return (
      this.dynamicCampaignDescription ??
      this.intl.t('customer.referral-page.campaign-description')
    );
  }

  get referralCampaignResource(): Query<{ data: ReferralCampaignData }> {
    return makeReferralCampaignResource(this.api, this.query);
  }

  get referralCampaignData(): ReferralCampaignData | undefined {
    if (this.referralCampaignResource.data) {
      return this.referralCampaignResource.data.data;
    }
    return undefined;
  }

  get referralAmount(): number | undefined {
    return this.referralCampaignData?.amount;
  }

  get dynamicCampaignDescription(): string | undefined {
    return this.referralCampaignData?.campaign_description ?? undefined;
  }

  get dynamicInviterTagline(): string | undefined {
    return this.referralCampaignData?.inviter_tagline;
  }

  get dynamicInviteeTagline(): string | undefined {
    return this.referralCampaignData?.invitee_tagline;
  }

  get tncLink(): string | undefined {
    return this.referralCampaignData?.terms_and_conditions_url;
  }

  get formattedReferralBonus(): string {
    if (!this.referralAmount) {
      return '';
    }

    const referralBonus: Money = {
      currency: 'EUR',
      value: this.referralAmount * 100,
    };

    return uiFormatMoneyLegacy(this.intl, referralBonus, {
      minimumFractionDigits: 0,
    });
  }

  sendInvitationEmail = dropTask(async (event: Event) => {
    event.preventDefault();
    this.formErrors = {};
    try {
      await this.api.post('invitations', {
        email: this.email,
      });
      this.email = '';
      this.rating.triggerEvent(RatingModalTrigger.FRIEND_INVITATION_SENT);
      this.tracking.track('customer/referrals:email-link');

      this.notificationBar.success(
        this.intl.t('customer.referral-page.email-link-success'),
        5000,
      );
    } catch (error: any) {
      // handle the response better for different types of errors
      let finalError;
      if (error.body && error.body.errors) {
        const possibleErrors = [
          'referrer::invitation',
          'domain::referrals::invitation',
        ];
        possibleErrors.forEach((errorCode) => {
          if (error.body.errors[errorCode] !== undefined) {
            finalError = error.body.errors[errorCode];
          }
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!finalError) {
        // if we still do not have an error, just show generic error message from the
        // translations file
        finalError = {
          email: this.intl.t('error.generic_error'),
        };
      }
      this.formErrors = finalError;
    }
  });

  @action async shareOnMobile() {
    const shareData = {
      title: this.intl.t('customer.referral-page.share.title'),
      text: this.intl.t('customer.referral-page.share.text'),
      url: this.referralLink,
    };

    this.tracking.track('customer/referrals:share-link');

    if (this.platform.isNative) {
      await this.share.share({
        dialogTitle: this.intl.t('customer.referral-page.share.dialog-title'),
        ...shareData,
      });
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!navigator.canShare) {
      return;
    }

    if (navigator.canShare(shareData)) {
      await navigator.share(shareData);
    }
  }

  @action async copyReferralLink() {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!navigator.clipboard) {
        /**
         * navigator.clipboard is supported in all latest major browsers
         */
        return;
      }

      await navigator.clipboard.writeText(this.referralLink);
      this.isReferralLinkCopied = true;

      if (macroCondition(!isTesting())) {
        // eslint-disable-next-line ember/no-runloop
        later(() => {
          this.isReferralLinkCopied = false;
        }, SHOW_DURATION);
      }

      this.tracking.track('customer/referrals:copy-link');

      if (this.hasRatingTriggerBeenHit) {
        return;
      }

      this.rating.triggerEvent(
        RatingModalTrigger.FRIEND_INVITATION_LINK_COPIED,
      );

      this.hasRatingTriggerBeenHit = true;
    } catch (error) {
      /**
       * FIXME: possibly add a notification message here informing the user to
       * manually copy the link
       */
      Sentry.withScope((scope) => {
        scope.setExtras({
          message: `Could not copy referral link`,
          component: 'Invite Friend',
        });

        Sentry.captureException(error);
      });
    }
  }
}
