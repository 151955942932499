import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Button\n  @appearance={{or\n    @style\n    (if (eq @floaterType \"next\") \"primary\" \"secondary\")\n  }}\n  @isDisabled={{or @disabled @disableCTA}}\n  @onClick={{@click}}\n  class=\"{{local-class\n      'button'\n      from=\"@clarksource/client/components/button-floater.css\"\n    }}\n    {{if (eq @floaterType 'next') 'cucumber-primary-button'}}\n    \"\n  data-test-input-button-next={{eq @floaterType \"next\"}}\n  data-test-input-button-back={{eq @floaterType \"back\"}}\n  ...attributes\n>\n  <:start>\n    {{#if @preIcon}}\n      {{~svg-jar @preIcon~}}\n    {{/if}}\n  </:start>\n\n  <:default>\n    {{~yield~}}\n  </:default>\n\n  <:end>\n    {{#if @postIcon}}\n      {{~svg-jar @postIcon~}}\n    {{/if}}\n  </:end>\n</Ui::Button>", {"contents":"<Ui::Button\n  @appearance={{or\n    @style\n    (if (eq @floaterType \"next\") \"primary\" \"secondary\")\n  }}\n  @isDisabled={{or @disabled @disableCTA}}\n  @onClick={{@click}}\n  class=\"{{local-class\n      'button'\n      from=\"@clarksource/client/components/button-floater.css\"\n    }}\n    {{if (eq @floaterType 'next') 'cucumber-primary-button'}}\n    \"\n  data-test-input-button-next={{eq @floaterType \"next\"}}\n  data-test-input-button-back={{eq @floaterType \"back\"}}\n  ...attributes\n>\n  <:start>\n    {{#if @preIcon}}\n      {{~svg-jar @preIcon~}}\n    {{/if}}\n  </:start>\n\n  <:default>\n    {{~yield~}}\n  </:default>\n\n  <:end>\n    {{#if @postIcon}}\n      {{~svg-jar @postIcon~}}\n    {{/if}}\n  </:end>\n</Ui::Button>","moduleName":"@clarksource/client/components/button-floater/legacy-input-button-adapter.hbs","parseOptions":{"srcName":"@clarksource/client/components/button-floater/legacy-input-button-adapter.hbs"}});
import templateOnlyComponent from '@ember/component/template-only';

interface ButtonFloaterLegacyInputButtonAdapterSignature {
  Args: {
    click: unknown;
    disableCTA: unknown;
    disabled: unknown;
    floaterType: unknown;
    postIcon: unknown;
    preIcon: unknown;
    style: unknown;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

const ButtonFloaterLegacyInputButtonAdapterComponent =
  templateOnlyComponent<ButtonFloaterLegacyInputButtonAdapterSignature>();

export default ButtonFloaterLegacyInputButtonAdapterComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ButtonFloater::LegacyInputButtonAdapter': typeof ButtonFloaterLegacyInputButtonAdapterComponent;
    'button-floater/legacy-input-button-adapter': typeof ButtonFloaterLegacyInputButtonAdapterComponent;
  }
}
