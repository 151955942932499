import Route from '@ember/routing/route';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class RetirementLandingRoute extends Route {
  @service declare router: Services['router'];

  beforeModel() {
    this.router.transitionTo('/recommendations/dashboard/retirement/cockpit');
  }
}
