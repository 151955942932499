import type {
  DocumentViewerService,
  PlatformService,
} from '@clark-shell/ember';
import type { SettingsConfiguration } from '@clark-utils/business-de/types';
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { schedule } from '@ember/runloop';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

const urlMap: Record<string, ResourceMap> = {
  initialInfo: {
    url: 'https://www.clark.de/de/erstinformation',
    pdf: 'first_information_pdf',
  },
  obligation: {
    url: 'https://www.clark.de/de/anzeigepflicht',
    // @ts-expect-error: Unknown key
    pdf: 'notifiable_pdf',
  },
  privacy: {
    url: 'https://www.clark.de/de/datenschutz',
    pdf: 'privacy_pdf',
  },
  terms: {
    url: 'https://www.clark.de/de/agb',
    pdf: 'terms_pdf',
  },
} as const;

interface ResourceMap {
  pdf: keyof Pick<
    SettingsConfiguration,
    'first_information_pdf' | 'privacy_pdf' | 'terms_pdf'
  >;
  url: string;
}

// TODO: https://clarkteam.atlassian.net/browse/JCLARK-56295
export default class LegalRoute extends Route {
  @service declare settings: Services['settings'];

  @service('shell/platform') declare platform: PlatformService;
  @service('shell/document-viewer')
  declare documentViewer: DocumentViewerService;

  beforeModel(transition: Transition) {
    const params = transition.to?.params as { document: string } | undefined;

    if (params?.document === undefined) {
      return;
    }

    const pdfAndUrl = urlMap[params.document];

    if (pdfAndUrl === undefined) {
      return;
    }

    if (this.platform.isNative) {
      // eslint-disable-next-line ember/no-runloop
      schedule('routerTransitions', () => {
        this.documentViewer.open(`/${this.settings.getSetting(pdfAndUrl.pdf)}`);
      });

      transition.abort();
    } else {
      window.location.replace(pdfAndUrl.url);
    }
  }
}
