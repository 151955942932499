import type { User } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { MandateStep } from '@clarksource/client/services/mandate-state';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class IndexMandateIndexExitPollRoute extends ClarkRoute {
  @service declare mandateState: Services['mandate-state'];

  // @ts-expect-error TS(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
  async beforeModel(...args) {
    // @ts-expect-error: A spread argument must either have a tuple type or be passed to a rest parameter
    super.beforeModel(...args);

    const user = this.modelFor('index') as User;

    this.mandateState.gotoMissingStep(user, MandateStep.ExitPoll);

    /**
     * Business logic to show exit-poll questionnaire to Clark customers
     * https://clarkteam.atlassian.net/browse/JCLARK-92669
     */
    const notAllowedNetworks = [
      'mam',
      'payback',
      'sparweltd',
      'mcanism_clark',
      'mystipendium',
      'salenti',
      'sam',
      'awin',
      'financeads',
      'service_intern',
      'n26',
      'dkb',
      'finanzblick',
      'steuerbot',
      'funnelplus',
      'sovendus',
      'buhldataservice',
      'userwerk',
      'cooper',
      'gsg',
      'referral program',
    ];

    if (
      // @ts-expect-error TS(4111) FIXME: Property 'network' comes from an index signature, ... Remove this comment to see the full error message
      user.adjust?.network &&
      // @ts-expect-error TS(4111) FIXME: Property 'network' comes from an index signature, ... Remove this comment to see the full error message
      notAllowedNetworks.includes(user.adjust.network)
    ) {
      this.mandateState.completeMandateStep(MandateStep.ExitPoll);

      this.mandateState.completeAndNavigateToNextStep(
        MandateStep.Finished,
        user,
      );
      return;
    }
  }

  model() {
    return {
      user: this.modelFor('index') as User,
    };
  }

  @action
  didTransition() {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    super.actions.didTransition.call(this, false);
  }
}
