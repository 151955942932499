import type { AllPlugins, PlatformService } from '@clark-shell/ember';
import { plugin } from '@clark-shell/ember';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';

export default class RedeemVoucherRoute extends Route {
  @plugin private declare readonly browser: AllPlugins['Browser'];

  @service declare router: RouterService;
  @service('shell/platform') declare platform: PlatformService;

  async beforeModel(transition: Transition) {
    const { redeemLink } = transition.to?.queryParams as Record<
      string,
      unknown
    >;

    if (redeemLink) {
      if (this.platform.isNative) {
        this.browser.open({ url: redeemLink as string });
      } else {
        window.open(redeemLink as string, '_blank');
      }
    }

    this.router.transitionTo('index.manager');
  }
}
