import type { Category, CategoryPage } from '@clark-utils/enums-and-types';
import { ErrorCode } from '@clark-utils/enums-and-types';
import Emberizer from '@clarksource/client/lifters/helpers/emberizer';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import * as Sentry from '@sentry/browser';
import { task } from 'ember-concurrency';
// eslint-disable-next-line import/no-unresolved
import { Promise } from 'rsvp';

export default class CategoryRoute extends ClarkRoute {
  @service declare api: Services['api'];
  @service declare router: Services['router'];
  @service declare category: Services['category'];

  // @ts-expect-error TS(2416) FIXME: Property 'queryParams' in type 'CategoryRoute' is ... Remove this comment to see the full error message
  queryParams = {
    recommendation: { refresh: true },
  };

  handleError(response: any, requestType: string) {
    Sentry.withScope((scope) => {
      scope.setExtras({
        message: `Could not get ${requestType} by ID API - category details page`,
      });

      Sentry.captureException(response.err);
    });

    // if we could not find the cat
    if (response.err === 'resource can not be found') {
      this.router.transitionTo('errors', {
        queryParams: { code: ErrorCode.CATEGORY },
      });
    } else {
      this.router.transitionTo('errors');
    }
  }

  loadRecommendation = task(async (mandateId, recommendationID) => {
    const recommendation = await this.api.get(
      `mandates/${mandateId}/recommendations/${recommendationID}/`,
    );

    return recommendation;
  });

  // @ts-expect-error TS(7006) FIXME: Parameter 'parameters' implicitly has an 'any' typ... Remove this comment to see the full error message
  model(parameters, transition) {
    if (parameters.id === '') {
      // @ts-expect-error TS(2339) FIXME: Property 'redirectOut' does not exist on type 'Cat... Remove this comment to see the full error message
      this.redirectOut();
    } else {
      const promise = new Promise((resolve) => {
        this.category.findById(parameters.id).then(
          // @ts-expect-error TS(2345) FIXME: Argument of type '(category: Category) => void' is... Remove this comment to see the full error message
          (category: Category) => {
            this.category.getCategoryPage(parameters.id).then(
              // @ts-expect-error TS(2345) FIXME: Argument of type '(categoryDetails: CategoryPage) ... Remove this comment to see the full error message
              async (categoryDetails: CategoryPage) => {
                // emberize all the things
                categoryDetails = Emberizer.emberize(
                  categoryDetails,
                ) as unknown as CategoryPage;

                category = Emberizer.emberize(category) as unknown as Category;

                const recommendationID =
                  transition.to.queryParams.recommendation;
                const user = this.modelFor('index');
                const recommendation = recommendationID
                  ? await this.loadRecommendation.perform(
                      // @ts-expect-error: Property 'mandate' does not exist on type '{}'
                      user?.mandate?.id,
                      recommendationID,
                    )
                  : null;

                resolve({
                  user,
                  categoryDetails,
                  category,
                  recommendation,
                });
              },
              (error: any) => {
                this.handleError(error, 'CategoryDetails');
              },
            );
          },
          (error: any) => {
            this.handleError(error, 'Category');
          },
        );
      });

      return promise;
    }
  }
}
