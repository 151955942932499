import ClarkRoute from '@clarksource/client/routes/-clark-route';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class MandatePayback extends ClarkRoute {
  @service('mandate/payback') declare payback: Services['mandate/payback'];

  model() {
    return this.payback.enablePayback();
  }
}
