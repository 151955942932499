/* eslint-disable @typescript-eslint/no-explicit-any */

import Inquiry from '@clark-home/ui/models/inquiry';
import Opportunity from '@clark-home/ui/models/opportunity';
import Product from '@clark-home/ui/models/product';
import Recommendation from '@clark-home/ui/models/recommendation';
import { flattenInquiries } from '@clark-home/ui/utils/models/inquiry';
import type {
  Optimization,
  Product as ProductType,
} from '@clark-utils/enums-and-types';
import { ErrorCode, ProductSoldBy } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { getOwner } from '@ember/owner';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import * as Sentry from '@sentry/browser';
import { dropTask } from 'ember-concurrency';

const Model = {
  inquiry: Inquiry,
  opportunity: Opportunity,
  product: Product,
  recommendation: Recommendation,
} as const;

type ContractsData = {
  contracts: Product[] | null;
  inquiries: any[] | null;
};

type ModelName = keyof typeof Model;

type AvailableModel = (typeof Model)[ModelName];

type ParamsForDeserializeCollection<T extends AvailableModel> = {
  additionalProperties?: {
    [key: string]: any;
  };
  collection: T[];
  modelName: ModelName;
};

export default class IndexContractsTabRoute extends ClarkRoute {
  @service declare localStorage: Services['local-storage'];
  @service declare api: Services['api'];
  @service declare router: Services['router'];
  @service declare selectCategory: Services['select-category'];

  deserializeCollection({
    additionalProperties = {},
    collection,
    modelName,
  }: ParamsForDeserializeCollection<AvailableModel>) {
    const Klass = Model[modelName];

    return collection.map((serialized) => {
      const properties = { ...serialized, ...additionalProperties } as any;
      return new Klass(properties, getOwner(this));
    });
  }

  processPayload(
    contractsData: ContractsData,
    recommendations: Optimization[],
  ) {
    const inquiries = contractsData.inquiries ?? [];
    const products = contractsData.contracts ?? [];

    const productsDeserialized = this.deserializeCollection({
      collection: products as unknown as AvailableModel[],
      modelName: 'product',
    }) as unknown as ProductType[];

    productsDeserialized.forEach((product: ProductType) => {
      // @ts-expect-error TS(2339) FIXME: Property 'total_evaluation' does not exist on type... Remove this comment to see the full error message
      product.total_evaluation =
        product.sold_by === ProductSoldBy.US
          ? 'Sehr Gut'
          : // @ts-expect-error TS(2339) FIXME: Property 'total_evaluation' does not exist on type... Remove this comment to see the full error message
            product.total_evaluation;
    });

    const recommendationsDeserialized = this.deserializeCollection({
      additionalProperties: {
        products,
      },
      collection: recommendations as unknown as AvailableModel[],
      modelName: 'recommendation',
    });

    const inquiriesDeserialized = this.deserializeCollection({
      additionalProperties: {
        willWait: this.localStorage.getData('will_wait_for_inquiry'),
      },
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      collection: flattenInquiries(inquiries),
      modelName: 'inquiry',
    });

    return {
      inquiries: inquiriesDeserialized,
      products: productsDeserialized,
      recommendations: recommendationsDeserialized,
    };
  }

  async model() {
    this.taskForContractsAndRecommendations.perform();

    this.selectCategory.setAfterCreateRedirect('index.contracts-tab.index');

    return {
      taskForContractsAndRecommendations:
        this.taskForContractsAndRecommendations,
    };
  }

  taskForContractsAndRecommendations = dropTask(async () => {
    const [contractsData, recommendationsResponse] = await Promise.all([
      this.api.get('contracts/.customer_contracts'),
      this.api.get('recommendations/customer_recommendations'),
    ]);

    const recommendations =
      //@ts-expect-error "recommendations" is not a property of the response
      (recommendationsResponse && recommendationsResponse.recommendations) ??
      [];

    try {
      return {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'Response' is not assignable to p... Remove this comment to see the full error message
        ...this.processPayload(contractsData, recommendations),
      };
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          message: 'Failed to process cockpit data',
          component: 'dashboard',
        });

        Sentry.captureException(error);
      });

      this.router.transitionTo('errors', {
        queryParams: { code: ErrorCode.VERTRAG_DETAILS },
      });
    }
  });
}
