import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @title}}\n  <h3\n    local-class=\"title\"\n    class=\"cucumber-targeting-insurance-intro\"\n  >\n    {{@title}}\n  </h3>\n{{/if}}\n\n<p local-class=\"subtitle\" data-test-targeting-subtitle>\n  {{@subtitle}}\n</p>\n\n{{#if this.isSkipButtonVisible}}\n  <Ui::Button\n    @appearance=\"link\"\n    @onClick={{@skip}}\n    local-class=\"skip-button\"\n    data-test-targeting-skip-button\n  >\n    <p local-class=\"skip-button-text\">\n      {{t \"manager.category_select.categories.skip_button\"}}\n    </p>\n    <Ui::Icon\n      @source=\"arrowRight\"\n      @size=\"custom\"\n      local-class=\"skip-button-icon\"\n    />\n  </Ui::Button>\n{{/if}}", {"contents":"{{#if @title}}\n  <h3\n    local-class=\"title\"\n    class=\"cucumber-targeting-insurance-intro\"\n  >\n    {{@title}}\n  </h3>\n{{/if}}\n\n<p local-class=\"subtitle\" data-test-targeting-subtitle>\n  {{@subtitle}}\n</p>\n\n{{#if this.isSkipButtonVisible}}\n  <Ui::Button\n    @appearance=\"link\"\n    @onClick={{@skip}}\n    local-class=\"skip-button\"\n    data-test-targeting-skip-button\n  >\n    <p local-class=\"skip-button-text\">\n      {{t \"manager.category_select.categories.skip_button\"}}\n    </p>\n    <Ui::Icon\n      @source=\"arrowRight\"\n      @size=\"custom\"\n      local-class=\"skip-button-icon\"\n    />\n  </Ui::Button>\n{{/if}}","moduleName":"@clarksource/client/components/mandate/select-category/heading.hbs","parseOptions":{"srcName":"@clarksource/client/components/mandate/select-category/heading.hbs"}});
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { TargetingView } from '../select-category';

interface MandateSelectCategoryHeadingSignature {
  Args: {
    currentView: TargetingView;
    isSkippable: boolean;
    skip: () => void;
    subtitle: string;
    title: string;
  };
}

export default class MandateSelectCategoryHeadingComponent extends Component<MandateSelectCategoryHeadingSignature> {
  @service declare selectCategory: Services['select-category'];

  get isSkipButtonVisible() {
    if (!this.args.isSkippable) {
      return false;
    }
    if (this.args.currentView !== TargetingView.CategoryView) {
      return false;
    }
    if (this.selectCategory.getSelected().length > 0) {
      return false;
    }

    return true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Mandate::SelectCategory::Heading': typeof MandateSelectCategoryHeadingComponent;
    'mandate/select-category/heading': typeof MandateSelectCategoryHeadingComponent;
  }
}
