import type { User } from '@clark-utils/enums-and-types';
import UserServiceError from '@clarksource/client/errors/user-service';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';
import { DateTime } from 'luxon';

export default class MandateUserService extends Service {
  @service declare api: Services['api'];
  @service declare session: Services['session'];
  @service declare tracking: Services['tracking'];

  @service declare authEventManager: Services['auth-event-manager'];

  @service declare userEventManager: Services['user-event-manager'];

  // @TODO we need to replace this when we merge the users
  private setCurrentUserOnRouteRestrictionService(user: User) {
    this.session.currentUser = user;
  }

  async getUser() {
    try {
      const response = await this.api.get('current_user');
      // @ts-expect-error TS(2339) FIXME: Property 'user' does not exist on type 'Response'.
      const user = response.user || (response.lead as User);
      // There should be a better way of deciding if a user is a lead.
      // This seems wrong
      user.lead = user.installation_id !== undefined;

      const { birthdate } = user.mandate;

      if (birthdate) {
        user.mandate.birthdate =
          DateTime.fromISO(birthdate).toFormat('dd.MM.yyyy');
      }

      return user;
    } catch (error) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      throw new UserServiceError(error);
    }
  }

  // update the user, called on mandate
  async update(user: User) {
    try {
      return await this.api.put('current_user', user);
    } catch (error) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      throw new UserServiceError(error);
    }
  }

  async reloadCurrentUser() {
    try {
      return await this.session.load.perform();
    } catch (error) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      throw new UserServiceError(error);
    }
  }

  // register for a new user account. This is done when the user is a lead
  async register(
    user: User,
    password: string,
    mandate: { first_name?: string; last_name?: string } = {},
  ) {
    // strange that the API asks for confirmed password and does not validate it?
    const data = {
      mandate,
      user: {
        email: user.email,
        password,
      },
    };

    // the one argument is for the version of the API, as this is an old api call
    try {
      const response = await this.api.post('app/register', data);
      this.userEventManager.didRegister();
      return response;
    } catch (error) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      throw new UserServiceError(error);
    }
  }

  async login(email: string, password: string) {
    const data = {
      user: {
        email,
        password,
      },
    };

    try {
      const response = (await this.api.post('app/login', data)) as {
        user: User;
      };
      this.setCurrentUserOnRouteRestrictionService(response.user);
      this.authEventManager.didLogin();
      // @ts-expect-error TS(2345) FIXME: Argument of type '"customer/account/login:successf... Remove this comment to see the full error message
      this.tracking.track('customer/account/login:successful');
      return response;
    } catch (error) {
      // @ts-expect-error TS(2345) FIXME: Argument of type '"customer/account/login:failed"'... Remove this comment to see the full error message
      this.tracking.track('customer/account/login:failed');
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      throw new UserServiceError(error);
    }
  }

  // call to the API to reset the password
  async resetPassword(password: string, passwordRepeat: string) {
    const data = {
      user: {
        password,
        password_confirmation: passwordRepeat,
      },
    };
    try {
      return await this.api.post('current_user/change_password', data);
    } catch (error) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      throw new UserServiceError(error);
    }
  }

  // Facebook login
  async loginWithFacebook(token: string, uid: string) {
    const data = {
      auth: {
        token,
        uid,
      },
    };
    try {
      return await this.api.post('app/oauth/facebook', data);
    } catch (error) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      throw new UserServiceError(error);
    }
  }

  // Apple sign-in
  // @ts-expect-error TS(7031) FIXME: Binding element 'uid' implicitly has an 'any' type... Remove this comment to see the full error message
  async loginWithApple({ uid, email, firstName, lastName, token }) {
    const data = {
      auth: {
        user: {
          email,
          firstName,
          lastName,
        },
        token,
        uid,
      },
    };
    try {
      return await this.api.post('app/oauth/apple', data);
    } catch (error) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      throw new UserServiceError(error);
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'mandate/user': MandateUserService;
  }
}
