import type { User } from '@clark-utils/enums-and-types';
import Controller from '@ember/controller';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class MandateIndexPhoneVerificationController extends Controller {
  @service('mandate/fde-onboarding')
  declare fdeOnboarding: Services['mandate/fde-onboarding'];

  get user(): User {
    // @ts-expect-error: Property 'user' does not exist on type 'unknown'
    const { user } = this.model;

    if (this.fdeOnboarding.profile) {
      const { phone } = this.fdeOnboarding.profile;
      Object.assign(user.mandate, { phone });
    }

    return user;
  }
}
