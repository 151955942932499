/* eslint-disable ember/classic-decorator-hooks, ember/classic-decorator-no-classic-methods */
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import Service from '@ember/service';
import { debounceTask } from 'ember-lifeline';
import type { IFuseOptions } from 'fuse.js';
import Fuse from 'fuse.js';

interface Collection {
  id: string | number;
  name: string;
}

export default class MandateFilterableService extends Service {
  searchInputValue = '';

  fuse?: Fuse<Collection>;

  searching = false;

  searchResults = [];

  searchOptions: IFuseOptions<unknown> = {
    isCaseSensitive: false,
    distance: 100,
    keys: ['name'],
    location: 0,
    minMatchCharLength: 1,
    shouldSort: true,
    threshold: 0.3,
  };

  init(props: object | undefined) {
    super.init(props);

    registerDestructor(this, () => this.destroySearch());
  }

  searchDebounced() {
    debounceTask(this, 'search', 300);
  }

  destroySearch() {
    if (this.fuse) {
      this.set('fuse', undefined);
    }

    this.clearSearchInput();
  }

  @action
  initSearchableCollection(collection: Collection[]) {
    this.destroySearch();

    // init the search with a new collection, this collection cleans up the shays
    // and is just the names and ID's anything else we do not care about
    this.set(
      'fuse',
      new Fuse<Collection>(
        collection.map((item) => ({
          id: item.id,
          name: item.name.replace(/&shy;/g, ''),
        })),
        this.searchOptions,
      ),
    );
  }

  resetSearchResults() {
    this.set('searchResults', []);
  }

  clearSearchInput() {
    this.set('searchInputValue', '');
  }

  search() {
    if (!this.fuse) {
      return;
    }

    this.set('searching', this.searchInputValue !== '');

    if (this.searchInputValue === '') {
      return this.resetSearchResults();
    }

    const searchResponse = this.fuse
      .search(this.searchInputValue)
      .map((r) => r.item);

    this.set(
      'searchResults',
      searchResponse.map((result) => result.id),
    );
  }

  @action
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  onSearchInput(value) {
    this.set('searchInputValue', value);

    this.searchDebounced();
  }

  @action
  onClearInput() {
    this.clearSearchInput();

    this.resetSearchResults();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'mandate/filterable': MandateFilterableService;
  }
}
