import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-action no-invalid-interactive }}\n<ul local-class=\"radiobutton\">\n  {{#each @question.options as |option index|}}\n    <li\n      data-test-demandcheck-radio\n      class=\"capybara-demandcheck-list-item cucumber-demandcheck-list-item\"\n      local-class=\"list-item\"\n      {{action \"clickOption\" option preventDefault=false}}\n    >\n      <label\n        local-class=\"label\"\n        class=\"custom-radio ember-radio-button\"\n      >\n        <FormElements::RadioButton\n          @groupValue={{this.groupValue}}\n          @value={{option.value}}\n          id={{index}}\n        />\n\n        <span local-class=\"radio\"></span>\n\n        <label local-class=\"text\">\n          {{option.label}}\n        </label>\n      </label>\n    </li>\n  {{/each}}\n</ul>", {"contents":"{{! template-lint-disable no-action no-invalid-interactive }}\n<ul local-class=\"radiobutton\">\n  {{#each @question.options as |option index|}}\n    <li\n      data-test-demandcheck-radio\n      class=\"capybara-demandcheck-list-item cucumber-demandcheck-list-item\"\n      local-class=\"list-item\"\n      {{action \"clickOption\" option preventDefault=false}}\n    >\n      <label\n        local-class=\"label\"\n        class=\"custom-radio ember-radio-button\"\n      >\n        <FormElements::RadioButton\n          @groupValue={{this.groupValue}}\n          @value={{option.value}}\n          id={{index}}\n        />\n\n        <span local-class=\"radio\"></span>\n\n        <label local-class=\"text\">\n          {{option.label}}\n        </label>\n      </label>\n    </li>\n  {{/each}}\n</ul>","moduleName":"@clarksource/client/components/demandcheck/radio-button-input.hbs","parseOptions":{"srcName":"@clarksource/client/components/demandcheck/radio-button-input.hbs"}});
/* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/no-observers, ember/require-tagless-components */
import type {
  DemandcheckOption,
  DemandcheckQuestion,
} from '@clark-utils/enums-and-types';
import Component from '@ember/component';
import { action, observer, set } from '@ember/object';
import { tagName } from '@ember-decorators/component';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface DemandcheckRadioButtonInputSignature {
  Args: {
    checkIfNextDisabled: () => void;
    moveToNextQuestion: () => Promise<void>;
    question: {
      [key: string]: unknown;
      type: DemandcheckQuestion;
    };
  };
}

@tagName('')
export default class DemandcheckRadioButtonInputComponent extends Component.extend(
  {
    questionObserver: observer(
      'question',
      function (this: DemandcheckRadioButtonInputComponent) {
        this.updateGroupValue();
      },
    ),
    selectedOptionObserver: observer(
      'selectedOption',
      function (this: DemandcheckRadioButtonInputComponent) {
        this.checkIfNextDisabled();
      },
    ),
  },
) {
  // Added for IE11 fix
  defaultLayout: any = null;

  groupValue = '';

  value = '';

  selectedOption: any = null;

  checkIfNextDisabled!: () => void;

  // eslint-disable-next-line ember/no-component-lifecycle-hooks
  didReceiveAttrs() {
    super.didReceiveAttrs();
    this.updateGroupValue();
  }

  updateGroupValue() {
    // @ts-expect-error TS(2339) FIXME: Property 'question' does not exist on type 'RadioB... Remove this comment to see the full error message
    const { options } = this.question;
    const option = options.find((option: DemandcheckOption) => option.selected);

    if (option) {
      this.updateSelectedOption(option);
    }
  }

  updateSelectedOption(option: DemandcheckOption) {
    set(this, 'selectedOption', option);
    set(this, 'groupValue', option.value);
  }

  @action
  clickOption(option: DemandcheckOption) {
    // @ts-expect-error TS(2339) FIXME: Property 'question' does not exist on type 'RadioB... Remove this comment to see the full error message
    const options = this.question.options as DemandcheckOption[];

    options.forEach((choice) => {
      set(choice, 'selected', choice === option);
    });

    this.updateSelectedOption(option);

    // @ts-expect-error TS(2339) FIXME: Property 'moveToNextQuestion' does not exist on ty... Remove this comment to see the full error message
    this.moveToNextQuestion();
  }
}
