import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { MandateStep } from '@clarksource/client/services/mandate-state';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class VoucherJourneyRoute extends ClarkRoute {
  @service declare session: Services['session'];
  @service declare mandateState: Services['mandate-state'];
  @service declare experiments: Services['experiments'];
  @service declare features: Services['features'];

  async beforeModel() {
    if (this.session.load.isRunning) {
      // first try to load the session since the task might be running when the
      // page is loaded directly
      await this.session.load.last;
    }

    if (!this.isCodelessCampaignUser) {
      this.experiments.forceVariant(
        '2024Q3IncentiveInfoMandateFunnel',
        'control',
      );
      return;
    }

    // This means the user is part of codeless campaign, and can skip the voucher journey
    this.mandateState.completeAndNavigateToNextStep(MandateStep.VoucherJourney);
  }

  get isCodelessCampaignUser() {
    const { currentMandate } = this.session;

    return (
      currentMandate?.codeless_campaign &&
      this.isAlreadyPartOfIncentiveInfoMandateFunnelExperiment
    );
  }

  get isAlreadyPartOfIncentiveInfoMandateFunnelExperiment(): boolean {
    if (!this.features.isEnabled('INCENTIVE_INFO_MANDATE_FUNNEL')) {
      return false;
    }
    return (
      this.experiments.hasVariant('2024Q3IncentiveInfoMandateFunnel') &&
      this.experiments.getVariant('2024Q3IncentiveInfoMandateFunnel') === 'v1'
    );
  }
}
