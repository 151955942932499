/* eslint-disable max-classes-per-file */
import type { HomeModalName } from '@clark-home/ui/services/home/modal-manager';
import type {
  RatingAggregate,
  RatingModalTrigger,
  RatingReminderCycle,
  User,
} from '@clark-utils/enums-and-types';
import { RatingEvent } from '@clark-utils/enums-and-types';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask, restartableTask, task } from 'ember-concurrency';
import { DateTime } from 'luxon';

class RatingSettings {
  POSITIVE_RATED_FIRST_REMINDER = 0;
  POSITIVE_RATED_SECOND_REMINDER = 0;
  NOT_RATED_REMINDER = 0;
  NEGATIVE_RATED_REMINDER = 0;
  NATIVE_MODAL_ENABLED = false;

  constructor(cycles: RatingReminderCycle, isNativeModalEnabled = false) {
    this.POSITIVE_RATED_FIRST_REMINDER = cycles.positive1view;
    this.POSITIVE_RATED_SECOND_REMINDER = cycles.positive2view;
    this.NOT_RATED_REMINDER = cycles.no_rating;
    this.NEGATIVE_RATED_REMINDER = cycles.bad_rating;
    this.NATIVE_MODAL_ENABLED = isNativeModalEnabled;
  }
}

const USER_STATUS_CRITIC = 'critic';

const modalName: HomeModalName = 'RatingModal';

export default class RatingRatingService extends Service {
  @service declare config: Services['config'];
  @service declare features: Services['features'];

  // Property 'api' does not exist on type 'Registry'.
  @service declare journey: Services['journey'];
  @service declare tracking: Services['tracking'];
  @service('home/modal-manager')
  declare homeModalManager: Services['home/modal-manager'];

  // Property 'api' does not exist on type 'Registry'.
  @service declare user: Services['user'];

  userObject!: User;

  ratingSettings!: RatingSettings;

  @tracked showModal = false;

  @tracked showFeedbackModal = false;

  get ratingFeatureFlag() {
    return this.features.isEnabled('RATING_MODAL');
  }

  checkUserEligibility = task(async () => {
    // cache the user once it is fetched.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this.userObject) {
      this.userObject = (await this.user.getUser()) as unknown as User;
    }

    const userCritic = this.userObject.mandate.variety
      ? this.userObject.mandate.variety !== USER_STATUS_CRITIC
      : true;

    return !(this.userObject as User & { lead: unknown }).lead && userCritic;
  });

  checkTriggerCycle(state: RatingAggregate): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this.ratingSettings) {
      this.ratingSettings = new RatingSettings(
        state.reminder_cycle_times,
        state.native_modal_enabled,
      );
    }

    // static rules
    const ruleRated = state.rated && !state.dismissed && state.triggered;
    const ruleNeverRated = state.triggered && !state.rated && !state.dismissed;
    const ruleDismissed = state.triggered && state.rated && state.dismissed;
    const rulePositiveRated = ruleRated && state.last_rating_positive;
    const ruleNegativeRated = ruleRated && !state.last_rating_positive;

    // dynamic rules
    const ruleBehindCycle = (period: number) => {
      const { days } = DateTime.local().diff(
        DateTime.fromJSDate(state.last_shown),
        'days',
      );

      return days < period;
    };

    const ruleAheadCycle = (period: number) => {
      const { days } = DateTime.local().diff(
        DateTime.fromJSDate(state.last_shown),
        'days',
      );

      return days > period;
    };

    const ruleWithinCycle = (startPeriod: number, endPeriod: number) =>
      ruleAheadCycle(startPeriod) && ruleBehindCycle(endPeriod);

    const ruleCycleOver = (rule: boolean | null, period: number) =>
      rule && ruleAheadCycle(period);

    const cycleConditions = [
      // STATE: never rated before, first trigger occured.
      ruleNeverRated,

      // STATE: rated before, left positive rating, first positive trigger cycle check, trigger occurred
      rulePositiveRated &&
        ruleWithinCycle(
          this.ratingSettings.POSITIVE_RATED_FIRST_REMINDER,
          this.ratingSettings.POSITIVE_RATED_SECOND_REMINDER,
        ) &&
        state.positive_ratings_count === 1,

      // STATE: rated before, left positive rating, second positive trigger cycle check, trigger occurred
      ruleCycleOver(
        rulePositiveRated,
        this.ratingSettings.POSITIVE_RATED_SECOND_REMINDER,
      ) && state.positive_ratings_count > 1,

      // STATE: rated before, left negative rating, negative trigger cycle check, trigger occurred
      ruleCycleOver(
        ruleNegativeRated,
        this.ratingSettings.NEGATIVE_RATED_REMINDER,
      ),

      // STATE: rated before, left no rating, not rated trigger cycle check, trigger occurred
      ruleCycleOver(ruleDismissed, this.ratingSettings.NOT_RATED_REMINDER),
    ];

    return cycleConditions.some(Boolean);
  }

  evaluateTriggerCyclePeriod = task(async () => {
    const rating =
      (await this.fetchRatingState.perform()) as unknown as RatingAggregate;
    return this.checkTriggerCycle(rating);
  });

  canRate = dropTask(async () => {
    const [...ratingRules] = await Promise.all([
      this.ratingFeatureFlag,
      this.checkUserEligibility.perform(),
      this.evaluateTriggerCyclePeriod.perform(),
    ]);

    const show = ratingRules.every(Boolean);

    if (show) {
      await this.show();
    }

    return show;
  });

  fetchRatingState = restartableTask(async () => {
    const ratingAggregate = (await this.journey.readAggregate(
      'rating',
    )) as RatingAggregate;

    return ratingAggregate;
  });

  openWebRating() {
    const url = this.config.getConfig('rating.webUrl') as string | undefined;
    if (!url) {
      return;
    }

    window.open(url, '_blank')?.focus();
  }

  @action closeFeedbackModal() {
    this.showFeedbackModal = false;
  }

  async dismiss() {
    this.homeModalManager.closeModal(modalName);
    await this.journey.dispatchEvent(RatingEvent.DISMISSED);
  }

  registerModal() {
    this.homeModalManager.registerModal({
      name: modalName,
      show: () => this.onModalShown(),
      hide: () => this.onModalHidden(),
    });
  }

  @action
  async onModalShown() {
    await this.journey.dispatchEvent(RatingEvent.SHOWN);

    this.showModal = true;

    this.tracking.track('rating_modal_shown');
  }

  @action
  onModalHidden() {
    this.showModal = false;
  }

  async show() {
    this.registerModal();
    this.homeModalManager.addModalToQueue(modalName);
  }

  async rate(choice: boolean) {
    await this.journey.dispatchEvent(RatingEvent.RATED, { positive: choice });
    this.homeModalManager.closeModal(modalName);
  }

  async triggerEvent(cause: RatingModalTrigger) {
    return this.journey.dispatchEvent(RatingEvent.TRIGGERED, {
      cause,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'rating/rating': RatingRatingService;
  }
}
