import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Shell::SettingsRoute::Toggle\n  @label={{t \"native.settings.options.tracking_enabled\"}}\n  @checked={{this.shellSettingsUserTracking.isEnabled}}\n  @update={{this.update}}\n/>", {"contents":"<Shell::SettingsRoute::Toggle\n  @label={{t \"native.settings.options.tracking_enabled\"}}\n  @checked={{this.shellSettingsUserTracking.isEnabled}}\n  @update={{this.update}}\n/>","moduleName":"@clarksource/client/components/shell/settings-route/user-tracking-toggle.hbs","parseOptions":{"srcName":"@clarksource/client/components/shell/settings-route/user-tracking-toggle.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import type ShellSettingsUserTrackingService from '../../../services/shell/settings/user-tracking';

interface ShellSettingsRouteUserTrackingToggleSignature {
  Args: {};
}

export default class ShellSettingsRouteUserTrackingToggleComponent extends Component<ShellSettingsRouteUserTrackingToggleSignature> {
  @service('shell/settings/user-tracking')
  declare shellSettingsUserTracking: ShellSettingsUserTrackingService;

  @action async update(isChecked: boolean): Promise<void> {
    await this.shellSettingsUserTracking.toggle(isChecked);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Shell::SettingsRoute::UserTrackingToggle': typeof ShellSettingsRouteUserTrackingToggleComponent;
    'shell/settings-route/user-tracking-toggle': typeof ShellSettingsRouteUserTrackingToggleComponent;
  }
}
