import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';

export default class JourneyService extends Service {
  @service declare api: Services['api'];

  readAggregate(aggregate: string) {
    return this.api.get(`journey/${aggregate}`);
  }

  dispatchEvent(event: string, data?: object) {
    return this.api.put('journey/event', {
      event,
      payload: data,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    journey: JourneyService;
  }
}
