import Route from '@ember/routing/route';
import { type Registry as Services, service } from '@ember/service';

export default class IndexIndexRoute extends Route {
  @service declare router: Services['router'];

  beforeModel() {
    this.router.replaceWith('index.manager');
  }
}
