export type Reason = {
  id: string;
  subreasonIds?: string[];
};

export function getReasons(): Reason[] {
  return [
    {
      id: 'friends',
    },
    {
      id: 'online-advertisement',
    },
    {
      id: 'partner',
      subreasonIds: ['bank', 'bargain-website', 'bonus-program'],
    },
    {
      id: 'poster-or-flyer',
    },
    {
      id: 'press',
    },
    {
      id: 'social-media',
    },
    {
      id: 'youtube',
      subreasonIds: ['advertisement', 'influencer'],
    },
    {
      id: 'others',
    },
  ];
}
