import { helper as buildHelper } from '@ember/component/helper';

// @ts-expect-error TS(7006) FIXME: Parameter 'parameters' implicitly has an 'any' typ... Remove this comment to see the full error message
export function deTildr(parameters) {
  if (!parameters[0]) {
    return;
  }
  return parameters[0].replace(/~/g, '&shy;');
}

export default buildHelper(deTildr);
