import type { PlatformService } from '@clark-shell/ember';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class MessengerRoute extends Route {
  @service('messenger/window')
  declare messengerWindow: Services['messenger/window'];
  @service declare router: RouterService;
  @service('shell/platform') declare platform: PlatformService;

  beforeModel() {
    if (this.platform.isWeb) {
      this.router.transitionTo('index.manager');
      return;
    }

    this.messengerWindow.show();
  }
}
