import type { OptionalPlugins, PlatformService } from '@clark-shell/ember';
import { plugin } from '@clark-shell/ember';
import type { TrackingAuthorizationStatus } from '@clark-shell/plugin.adjust';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

export default class IosTrackingConsentService extends Service {
  @plugin({ optional: true })
  private declare adjust: OptionalPlugins['Adjust'];

  @service declare localStorage: Services['local-storage'];
  @service declare tracking: Services['tracking'];

  @service('shell/platform') declare platform: PlatformService;

  async getConsent(): Promise<boolean | undefined> {
    return this.getConsentTask.perform();
  }

  getConsentTask = dropTask(async () => {
    const localStorageStatus = this.localStorage.getData(
      'ios-tracking-consent',
    ) as TrackingAuthorizationStatus | false;
    const iosStatus = (await this.getStatusFromIos()) as
      | TrackingAuthorizationStatus
      | undefined;

    if (iosStatus && iosStatus !== localStorageStatus) {
      this.localStorage.setData('ios-tracking-consent', iosStatus);
      this.tracking.track('shell/app:apple-tracking-consent', {
        status: iosStatus,
      });
    }

    switch (iosStatus ?? localStorageStatus) {
      case 'authorized':
        return true;
      case 'denied':
        return false;
      default:
        return undefined;
    }
  });

  private async getStatusFromIos() {
    if (this.platform.operatingSystem !== 'ios') {
      return;
    }
    if (!this.adjust?.getTrackingAuthorizationStatus) {
      return;
    }

    const status = await this.adjust.getTrackingAuthorizationStatus().then(
      (data) => data.status,
      () => 'not-determined',
    );

    return status;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'ios-tracking-consent': IosTrackingConsentService;
  }
}
