/* eslint-disable ember/no-actions-hash */
import type { PlatformService } from '@clark-shell/ember';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { service } from '@ember/service';

export default class Iban extends ClarkRoute {
  @service('shell/platform') declare platform: PlatformService;

  model() {
    return { user: this.modelFor('index') };
  }

  didTransition() {
    if (this.platform.isNativeAndroid) {
      // @ts-expect-error: Property '_super' does not exist on type '{}'
      super.didTransition(false);
    } else {
      // @ts-expect-error: Property '_super' does not exist on type '{}'
      super.didTransition();
    }
  }
}
