import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"container\">\n  <ModalLayouts::StandardContent\n    @image={{component\n      \"modal-layouts/image\"\n      svg=\"updateNativeApp\"\n    }}\n    @title={{t \"update_native_app.heading\"}}\n    local-class=\"body\"\n  >\n    <p>\n      {{t \"update_native_app.copytext\" store=this.textForStore}}\n    </p>\n  </ModalLayouts::StandardContent>\n\n  <Ui::Button\n    @onClick={{this.appStore.openAppInStore}}\n    @type=\"primary\"\n    data-test-button=\"Update\"\n    local-class=\"update-button\"\n  >\n    {{t \"update_native_app.cta\"}}\n  </Ui::Button>\n</div>", {"contents":"<div local-class=\"container\">\n  <ModalLayouts::StandardContent\n    @image={{component\n      \"modal-layouts/image\"\n      svg=\"updateNativeApp\"\n    }}\n    @title={{t \"update_native_app.heading\"}}\n    local-class=\"body\"\n  >\n    <p>\n      {{t \"update_native_app.copytext\" store=this.textForStore}}\n    </p>\n  </ModalLayouts::StandardContent>\n\n  <Ui::Button\n    @onClick={{this.appStore.openAppInStore}}\n    @type=\"primary\"\n    data-test-button=\"Update\"\n    local-class=\"update-button\"\n  >\n    {{t \"update_native_app.cta\"}}\n  </Ui::Button>\n</div>","moduleName":"@clarksource/client/components/update-native-app-modal.hbs","parseOptions":{"srcName":"@clarksource/client/components/update-native-app-modal.hbs"}});
import type { AppStoreService, PlatformService } from '@clark-shell/ember';
import { type Registry as Services, service } from '@ember/service';
import Component from '@glimmer/component';

interface UpdateNativeAppModalSignature {
  Args: {};
}

export default class UpdateNativeAppModalComponent extends Component<UpdateNativeAppModalSignature> {
  @service declare intl: Services['intl'];

  // eslint-disable-next-line ember/no-unused-services
  @service('shell/app-store') declare appStore: AppStoreService;
  @service('shell/platform') declare platform: PlatformService;

  get textForStore(): string {
    if (this.platform.isNativeAndroid) {
      return this.intl.t('update_native_app.store_android');
    }

    if (this.platform.isNativeIOS) {
      return this.intl.t('update_native_app.store_ios');
    }

    return this.intl.t('update_native_app.store_generic');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    UpdateNativeAppModal: typeof UpdateNativeAppModalComponent;
    'update-native-app-modal': typeof UpdateNativeAppModalComponent;
  }
}
