import type Recommendation from '@clark-home/ui/models/recommendation';
import type APIService from '@clarksource/ember-api/services/api';
import Service, { service } from '@ember/service';

/**
 * @see https://github.com/ClarkSource/application/blob/449a217f3722f85513c2515e8a0fae84f54fea8d/app/api/clark_api/v2/opportunities.rb#L17
 */
export enum SourceType {
  ADVICE = 'Interaction::Advice',
  APPOINTMENT = 'Appointment',
  QUESTIONNAIRE_RESPONSE = 'Questionnaire::Response',
  RECOMMENDATION = 'Recommendation',
}

/**
 * @see https://github.com/ClarkSource/application/blob/449a217f3722f85513c2515e8a0fae84f54fea8d/app/models/opportunity.rb#L45-L49
 */
export enum OpportunityState {
  COMPLETED = 'completed',
  CREATED = 'created',
  INITIATION_PHASE = 'initiation_phase',
  LOST = 'lost',
  OFFER_PHASE = 'offer_phase',
}

export enum OpportunityOrigin {
  CONTRACT_OVERVIEW = 'contract_overview',
  OPTIMIZATION = 'optimization',
  RETIREMENT_OVERVIEW = 'retirement_overview',
  SELECT_CATEGORY = 'select_category',
}

export interface OpportunityMetadata {
  originated_from?: OpportunityOrigin;
}

/**
 * @see https://github.com/ClarkSource/application/blob/449a217f3722f85513c2515e8a0fae84f54fea8d/app/api/clark_api/v2/opportunities.rb#L15-L24
 */
export interface OpportunityParams {
  category_ident: string;
  metadata?: OpportunityMetadata;
  old_product_id?: number;
  source_id: number;
  source_type: SourceType;
  state?: Exclude<
    OpportunityState,
    | OpportunityState.OFFER_PHASE
    | OpportunityState.COMPLETED
    | OpportunityState.LOST
  >;
}

/**
 * @see https://github.com/ClarkSource/application/blob/449a217f3722f85513c2515e8a0fae84f54fea8d/app/models/offer.rb#L28-L33
 */
export enum OfferState {
  ACCEPTED = 'accepted',
  ACTIVE = 'active',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  IN_CREATION = 'in_creation',
  REJECTED = 'rejected',
}

/**
 * @see https://github.com/ClarkSource/application/blob/449a217f3722f85513c2515e8a0fae84f54fea8d/app/api/clark_api/v2/entities/opportunity.rb
 */
interface Opportunity {
  category: {
    id: number;
    ident: string;
    name: string;
    name_hyphenated: string;
  };
  created_at: string;

  id: number;

  offer?: {
    id: number;
    state: OfferState;
  };
}

export default class OpportunitiesService extends Service {
  @service declare api: APIService;

  async createOpportunity(payload: OpportunityParams) {
    const { opportunity }: { opportunity: Opportunity } = await this.api.post(
      'opportunities',
      payload,
    );
    return opportunity;
  }

  async createOpportunityFromRecommendation(
    recommendation: Recommendation,
    metadata?: OpportunityMetadata,
  ) {
    return this.createOpportunity({
      source_type: SourceType.RECOMMENDATION,
      source_id: recommendation.id,
      category_ident: recommendation.category.ident,
      state: OpportunityState.CREATED,
      metadata,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    opportunities: OpportunitiesService;
  }
}
