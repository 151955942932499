import { ClarkVersion } from '@clark-customer/clark-version.js';
import type { RedirectionError } from '@clark-customer/ui/components/customer/onboarding/fde/error-message-screen';
import { localStorage, StorageKey } from '@clark-utils/utils.js/browser';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import type { QueryParams } from 'ember-link';

type FormData = {
  email: string;
  isTCConfirmed: boolean;
  password: string;
};

type APIFormError = {
  code: 'password' | 'tc_confirmed' | 'email';
  title: string;
};

type SignupAuthParams = {
  fde_jwt?: string;
  fde_mrmo_order_id?: string;
  fde_token?: string;
  fde_uuid?: string;
  visit: {
    utm_campaign?: string;
    utm_source?: string;
  };
};

type FormErrors = {
  email?: string[];
  password?: string[];
  tc_confirmed?: string[];
};

export default class FdeOnboardingController extends Controller {
  @service declare api: Services['api'];
  @service declare clarkVersion: Services['clark-version'];
  @service declare experiments: Services['experiments'];
  @service declare router: Services['router'];
  @service declare linkManager: Services['link-manager'];

  @service('mandate/fde-onboarding')
  declare fdeOnboarding: Services['mandate/fde-onboarding'];

  queryParams = [
    'fde_mrmo_order_id',
    'fde_jwt',
    'fde_token',
    'fde_uuid',
    'utm_campaign',
    'utm_source',
  ];

  @tracked fde_mrmo_order_id?: string;
  @tracked fde_jwt?: string;
  @tracked fde_token?: string;
  @tracked utm_campaign?: string;
  @tracked utm_source?: string;
  @tracked fde_uuid?: string;
  @tracked fieldErrors: FormErrors = {};
  @tracked isFormSubmitting = false;

  migrateCustomer = dropTask(async (formData: FormData) => {
    this.fieldErrors = {};

    try {
      await this.api.post('customer/fde', {
        ...this.signupAuthParams,
        password: formData.password,
        tc_confirmed: formData.isTCConfirmed,
      });

      await this.fdeOnboarding.migrateContracts();

      this.convertUserToClark2();

      this.router.transitionTo('index.manager');
    } catch (error) {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      const formErrors = error?.body?.errors as APIFormError[] | undefined;
      if (!formErrors) {
        this.redirectToErrorPage();
        return;
      }

      const mappedFormErrors: FormErrors = {};
      formErrors.forEach((err: APIFormError) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!err.code) {
          this.redirectToErrorPage();
          return;
        }
        mappedFormErrors[err.code] = [err.title];
      });

      /**
       * Temporary solution to handle all email errors until BE api is available
       */
      if (mappedFormErrors.email) {
        this.redirectToErrorPage('emailExists');
        return;
      }

      this.fieldErrors = mappedFormErrors;
    }
  });

  get signupAuthParams(): SignupAuthParams {
    return {
      fde_uuid: this.fde_uuid,
      fde_jwt: this.fde_jwt,
      fde_mrmo_order_id: this.fde_mrmo_order_id,
      fde_token: this.fde_token,
      visit: {
        utm_campaign: this.utm_campaign,
        utm_source: this.utm_source,
      },
    };
  }

  convertUserToClark2(): void {
    // Update the Clark version in the client app
    this.clarkVersion.setClarkVersion(ClarkVersion.Clark2);

    this.experiments.forceVariant('business-strategy', 'clark2');

    localStorage.setItem(StorageKey.ClarkVersion, ClarkVersion.Clark2);
  }

  redirectToErrorPage(errorType: RedirectionError = 'generic') {
    this.router.transitionTo('customer.onboarding.fde.error', {
      queryParams: {
        error_type: errorType,
      },
    });
  }

  @action
  buildAddContractsLink(query = {}) {
    return this.linkManager.createLink({
      route: 'contracts.create',
      query,
    });
  }

  @action
  buildCustomerRegistrationLink(query: QueryParams) {
    return this.linkManager.createLink({
      route: 'customer.registration',
      query,
    });
  }

  @action
  buildCustomerUpgradeLink(query: QueryParams) {
    return this.linkManager.createLink({
      route: 'customer.upgrade',
      query,
    });
  }
}
