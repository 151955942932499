import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { type Registry as Services, service } from '@ember/service';

export default class InquiryDetails extends Route {
  @service declare router: Services['router'];

  beforeModel(transition: Transition): void {
    const { id } = transition.to?.params as Record<string, unknown>;
    const { category, hasUploadAction } = transition.to?.queryParams as Record<
      string,
      unknown
    >;

    const queryParams = {
      startUpload: hasUploadAction && hasUploadAction === 'true',
    };

    this.router.replaceWith('contracts.details', `inquiry-${id}-${category}`, {
      queryParams,
    });
  }
}
