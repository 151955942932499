import type { AllPlugins } from '@clark-shell/ember';
import { plugin } from '@clark-shell/ember';
import {
  CookieBannerName,
  CookieBannerTrackingEvent,
} from '@clark-utils/enums-and-types';
import { notifyPropertyChange } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';
import { isDevelopingApp, macroCondition } from '@embroider/macros';

declare global {
  interface Window {
    /**
     * Calling this function injects the Google Tag Manager script,
     * if the opt-in cookie is set and if the script has not been
     * injected yet. You can safely call this function repeatedly.
     *
     * This global function is defined in `public/clark-gtm.js`.
     *
     * @note Only injected in production builds. `undefined` for dev builds.
     */
    loadClarkTrackingScript?: () => void;
  }
}

/**
 * This service is responsible for toggling if user tracking is enabled for
 * native devices.
 */
export default class ShellSettingsUserTrackingService extends Service {
  @plugin private declare modals: AllPlugins['Modals'];

  @service declare api: Services['api'];
  @service declare global: Services['global'];
  @service declare intl: Services['intl'];
  @service declare tracking: Services['tracking'];

  async toggle(newEnabled = !this.isEnabled): Promise<void> {
    await this.updatePrivacySettings(newEnabled);
    this.setEnabled(newEnabled);
    this.trackEnabled(newEnabled);
  }

  get isEnabled(): boolean {
    return this.global.cookie.includes(
      `${CookieBannerName.BANNER_TRACKING_MARKETING}=true;`,
    );
  }

  private setEnabled(enabled: boolean) {
    if (enabled) {
      this.loadClarkTrackingScript();
    } else {
      this.notifyDisabled();
    }

    notifyPropertyChange(this, 'isEnabled');
  }

  private notifyDisabled() {
    this.modals.alert({
      title: this.intl.t('native.settings.tracking_modal.title'),
      message: this.intl.t('native.settings.tracking_modal.message'),
    });
  }

  private trackEnabled(enabled: boolean) {
    const event = enabled
      ? CookieBannerTrackingEvent.COOKIE_MARKETING_ACTIVATE
      : CookieBannerTrackingEvent.COOKIE_MARKETING_DEACTIVATE;

    this.tracking.track(event, { source: 'app-settings' });
  }

  private async updatePrivacySettings(enabled: boolean) {
    await this.api.post('customer/current/privacy_settings', {
      third_party_tracking: {
        accepted_at: new Date().toISOString(),
        enabled,
      },
    });
  }

  /**
   * Calls `window.loadClarkTrackingScript()`, if it has been injected.
   *
   * @TODO Extract into extra service / package.
   */
  loadClarkTrackingScript(): void {
    if (typeof this.global.scope.loadClarkTrackingScript !== 'function') {
      if (macroCondition(isDevelopingApp())) {
        console.info(
          'Tried calling `loadClarkTrackingScript()` in a development environment, but `clark-gtm.js` is only injected for production builds.',
        );
      } else {
        console.error(
          'Tried calling `loadClarkTrackingScript()` in a production environment, but `clark-gtm.js` script was not injected.',
        );
      }
      return;
    }

    this.global.scope.loadClarkTrackingScript();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'shell/settings/user-tracking': ShellSettingsUserTrackingService;
  }
}
