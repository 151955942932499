import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';
import * as Sentry from '@sentry/browser';
import { task } from 'ember-concurrency';

export default class InvitationDataHandlerService extends Service {
  @service declare api: Services['api'];

  async getCustomerInvitees() {
    let data: any;
    if (this.fetchCustomerInvites.lastSuccessful?.value) {
      data = this.fetchCustomerInvites.lastSuccessful.value;
    } else {
      data = await this.fetchCustomerInvites.perform();
    }
    return data;
  }

  fetchCustomerInvites = task(async () => {
    try {
      const response = (await this.api.get('customer/current/invitees', {
        version: 'v5',
      })) as {
        data: [];
      };
      return response.data;
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          message: `Could not fetch the customer invitees`,
          component: 'invitation-data-handler service',
        });

        Sentry.captureException(e);
      });
    }
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'invitation-data-handler': InvitationDataHandlerService;
  }
}
