import type { Category } from '@clark-utils/enums-and-types';
import { CategoryIdent, MarginLevel } from '@clark-utils/enums-and-types';
import { retryOnNetworkFailure } from '@clarksource/ember-api/utils';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';

export default class CategoryService extends Service {
  @service declare api: Services['api'];
  @service declare config: Services['config'];
  @service declare experiments: Services['experiments'];
  @service declare features: Services['features'];

  // find category by ident
  findByIdent(ident: string) {
    return retryOnNetworkFailure(() =>
      this.api.get(`categories/ident/${ident}`),
    );
  }

  findById(id: number) {
    return retryOnNetworkFailure(() => this.api.get(`categories/${id}`));
  }

  // get all the details for the category page
  getCategoryPage(id: number) {
    return retryOnNetworkFailure(() => this.api.get(`category_pages/${id}`));
  }

  // get active categories from the API
  activeCategories() {
    return retryOnNetworkFailure(() =>
      this.api.get<{ categories: Category[] }>('categories/active', {
        version: 'v4',
      }),
    );
  }

  // get company id's for the category
  companies(ident: string) {
    return retryOnNetworkFailure(() =>
      this.api.get(`categories/${ident}/companies`),
    );
  }

  getPopularCategoryIdents() {
    if (this.features.isEnabled('TARGETING_MOST_COMMON_INSURANCE_CHANGES')) {
      const variant = this.experiments.getVariant(
        '2024Q4TargetingMostCommonInsuranceChanges',
      );
      if (variant === 'v1') {
        return [
          '03b12732', // Privathaftpflicht
          '15f6b555', // KFZ-Versicherung
          'e251294f', // Hausrat
          '5bfa54ce', // Rechtsschutz
          '3659e48a', // Gesetzliche Krankenversicherung
        ];
      } else if (variant === 'v2') {
        return [
          '03b12732', // Privathaftpflicht
          '15f6b555', // KFZ-Versicherung
          'e251294f', // Hausrat
          '5bfa54ce', // Rechtsschutz
          '377e1f7c', // Zahnzusatz
        ];
      }
    }
    return this.config.getConfig('popularCategoryIdents') || [];
  }

  isFastForwardCheckout(category: Category) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return category?.fast_forward_checkout;
  }

  isLowMarginCategory(category: Category) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return category?.margin_level === MarginLevel.Low;
  }

  isHighMarginCategory(category: Category) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return category?.margin_level === MarginLevel.High;
  }

  isMidMarginCategory(category: Category) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return category?.margin_level === MarginLevel.Medium;
  }

  isGKV(category: Category) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return category?.ident === CategoryIdent.Gkv;
  }

  isKfzAutomation(category: Category) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return category?.ident === CategoryIdent.KfzAutomation;
  }

  isAvailableForCheckout(category: Category) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return category?.available_for_checkout;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    category: CategoryService;
  }
}
