import type { User } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { MandateStep } from '@clarksource/client/services/mandate-state';
import { action } from '@ember/object';
import { type Registry as Services, service } from '@ember/service';

export default class MandateFinishedRoute extends ClarkRoute {
  @service declare mandateState: Services['mandate-state'];

  @service('mandate/home24') declare home24: Services['mandate/home24'];

  // @ts-expect-error TS(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
  beforeModel(...args) {
    // @ts-expect-error: A spread argument must either have a tuple type or be passed to a rest parameter
    super.beforeModel(...args);

    const user = this.modelFor('index') as User;

    this.mandateState.gotoMissingStep(user, MandateStep.Finished);
  }

  async model() {
    const home24CustomerData = await this.home24.getCustomerData();
    return {
      user: this.modelFor('index'),
      customFunnelParams: this.modelFor('index.mandate.index'),
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      isHome24Customer: Boolean(home24CustomerData?.home24Source),
    };
  }

  @action
  didTransition() {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    super.actions.didTransition.call(this, false);
  }
}
