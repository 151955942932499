import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class ClarkTourRoute extends Route {
  @service declare session: Services['session'];

  beforeModel(transition: Transition) {
    if (this.session.isAuthenticated) {
      transition.abort();
    }
  }
}
