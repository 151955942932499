import {
  localStorage,
  StorageKey,
} from '@clark-utils/utils.js/browser/local-storage';
import { isDevelopingApp, isTesting, macroCondition } from '@embroider/macros';

function logMessage(message: string): void {
  if (macroCondition(isDevelopingApp() && !isTesting())) {
    console.log(`%cInfo: ${message}`, 'color:green;');
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
function getParameterByName(name, url) {
  name = name.replace(/\[]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function transformRoute(route: string): string {
  // remove the starting '/' on the url (just in case there are some)
  return (
    route
      .replace(/^\//, '')
      // remove the starting de on the url
      .replace(/^de(\/)/, '')
      // remove the starting app on the url
      .replace(/^ap(p\/|p)/, '')
      // remove trailing slash at the end (just in case there are some)
      .replace(/\/$/, '')
      // now swap the /'s out for dots so its a proper route
      .replace(/\//g, '.')
  );
}

/**
 * prepends routeName with `index` if not a TopLevel route
 * @param route - processes route
 */
function handleTopLevelRoutes(route: string): string {
  // only add index if not in top level routes
  const topLevelRoutes = new Set([
    'errors',
    'login',
    'blank',
    'access',
    'password-reset',
  ]);
  if (topLevelRoutes.has(route)) {
    return route;
  }
  return `index.${route}`;
}

function managerRoutes(route: string) {
  if (
    route === 'index.manager.optimisation' ||
    route === 'index.manager.index.landing.cockpit.recommendations' ||
    route.includes('index.manager.landing.recommendations') ||
    route.includes('recommendations')
  ) {
    return 'recommendations';
  }
  return route;
}

// @ts-expect-error TS(7006) FIXME: Parameter 'route' implicitly has an 'any' type.
function transformTransition(route, queryRoute, hashRoute, parameterRoute) {
  // define the route we will try to transition  to
  let transitionAttempt = queryRoute || route;

  transitionAttempt = hashRoute && !queryRoute ? hashRoute : transitionAttempt;

  // use full path request for route that ends in numbers also
  transitionAttempt =
    parameterRoute && !queryRoute && !hashRoute
      ? parameterRoute
      : transitionAttempt;

  // fixes 'index.index' and so on
  return transitionAttempt.replace(/^(?:index\.){2,}/, 'index.');
}

function removeLeadingSlash(route: string) {
  return route.replace(/(^\/)/, '');
}

// @ts-expect-error TS(7006) FIXME: Parameter 'instance' implicitly has an 'any' type.
export function initialize(instance) {
  const applicationRouter = instance.lookup('service:router');

  const isClark2 = localStorage.getItem(StorageKey.ClarkVersion) === '2';

  // when we cant find the route
  // @ts-expect-error TS(7006) FIXME: Parameter 'route' implicitly has an 'any' type.
  function transitionAway(route, section) {
    logMessage(
      `Called transition to and DID NOT find the route in ember: ${route}, calling navigate to on ${route} with section ${section}`,
    );

    applicationRouter.transitionTo('blank');
  }

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  window.clark = window.clark || {};

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  window.clark.webapp = window.clark.webapp || {};

  // overide the window getPageMeta to function so we can call set title without interuption
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  window.clark.webapp.getPageMeta = function () {
    // backward compatibility to app version pre 1.6.0
    if (window.location.pathname === '/de/app/feed') {
      return {
        title: 'Clark',
        back: false,
        section: 'feed',
      };
    }

    return '{}';
  };

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  window.clark.webapp.back = () => window.history.back();

  // Stash any potential `transitionTo` from Firestarter
  // @ts-expect-error TS(2339) FIXME: Property 'transitionTo' does not exist on type 'Cl... Remove this comment to see the full error message
  const { transitionTo } = window.clark.webapp;

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  window.clark.webapp.transitionTo = (route, section) => {
    transitionTo?.(route, section);

    if (isClark2) {
      switch (route) {
        case '/de/app/mandate':
          window.history.back();
          return;
        case 'app/blank':
          return;
        default:
      }
    }

    // if we have a an iframe URL
    if (route.includes('i-frame')) {
      route = route.replace('/de/app/', '');
      const [routeClean] = route.split('?');

      const parameters = {
        url: getParameterByName('url', route),
      };

      const title = getParameterByName('title', route);

      if (title) {
        // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type '{ url: st... Remove this comment to see the full error message
        parameters.title = title;
      }

      applicationRouter.transitionTo(routeClean, {
        queryParams: parameters,
      });
      return;
    }

    // keep ref for old route (in case we need it)
    const routeOld = route;
    // this is false unless a qs route was passed in
    let queryRoute = false;

    let hashRoute = false;

    // need a flag for also checking on an ID at the end of a request so offer/1 z.b
    let parameterRoute = false;

    route = transformRoute(route);

    // check if qs route
    const routeParts = route.split('?');

    if (routeParts[1]) {
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'boolean'.
      queryRoute = `/${route.replace(/\./g, '/')}`;
      [route] = routeParts;
    }

    // param route parts
    const parts = route.split('.');
    if (!Number.isNaN(Number.parseInt(parts[parts.length - 1], 2))) {
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'boolean'.
      parameterRoute = `/${route.replace(/\./g, '/')}`;
    }

    // checking for hash routes
    const hashRegex = new RegExp(/#/g);
    if (hashRegex.test(parts[parts.length - 1])) {
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'boolean'.
      hashRoute = `/${route.replace(/\./g, '/')}`;
    }

    // add new index. prefixing etc
    if (!parameterRoute && !queryRoute && !hashRoute) {
      if (route !== 'feed') {
        // only add index if not in top level routes
        route = handleTopLevelRoutes(route);
      } else {
        route = 'index.messenger';
        section = 'feed';
      }

      // if the route starts with index.mandate, rename it to index.mandate.index
      const regex = new RegExp(/^index\.mandate(.*)/g);
      const matches = regex.exec(route);

      if (matches) {
        route = `index.mandate.index${matches[1]}`;
      }

      // recommendations route is a strange beast we need to handle it here (also old app routes)
      route = managerRoutes(route);
    }

    let transitionAttempt = transformTransition(
      route,
      queryRoute,
      hashRoute,
      parameterRoute,
    );

    logMessage(`Transition attempt is:${transitionAttempt}`);

    let skipTransitionAway = false;
    if (transitionAttempt === 'index.login') {
      transitionAttempt = 'login';
      skipTransitionAway = true;
    }
    // if route contains the with ember or login its not in our app
    if (
      !skipTransitionAway &&
      (transitionAttempt.includes('ember') ||
        transitionAttempt.includes('login'))
    ) {
      transitionAway(routeOld, section);
    } else {
      // try to do a transition to the ember route
      try {
        if (transitionAttempt.includes('index.questionnaire')) {
          // @TODO
          // This is just a QUICK-FIX for the QUESTIONNAIRES, since the route is not properly being handled
          /**
           * CHANGE THIS CODE PLEASEE!!!!
           *
           * JIRA ISSUE FOR TECHNICAL DEBT: JCLARK-9340
           *
           */

          // Adding this to check if this hack is needed at all
          // TODO: Remove this piece of code if its already fixed
          // https://clarkteam.atlassian.net/browse/JCLARK-43173
          const identifierQuestion = transitionAttempt.slice(20);
          applicationRouter.transitionTo(
            'index.questionnaire.index',
            identifierQuestion,
          );
        } else {
          (async () => {
            try {
              await applicationRouter.transitionTo(transitionAttempt);
            } catch (error) {
              // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
              if (error.message === 'TransitionAborted') {
                return;
              }

              const temporaryRoute = removeLeadingSlash(transitionAttempt);
              applicationRouter.transitionTo(
                `/${temporaryRoute
                  .replace(/\./g, '/')
                  .replace(/index\//g, '')}`,
              );
            }
          })().then(() => {
            logMessage('-------- ACTUAL PAGE TRANSITION --------');
            logMessage(`Doing a transition to route: ${transitionAttempt}`);
            // wait until the page has been rendered before showing the webview
            // eslint-disable-next-line ember/no-runloop
          });

          logMessage(
            `Called transition to and found the route in ember: ${route}`,
          );
        }
      } catch {
        transitionAway(routeOld, section);
      }
    }

    return false;
  };

  const windowOnErrorBackup = window.onerror as OnErrorEventHandler;
  window.onerror = function (message, url, lineNo, columnNo, error) {
    if (message === 'ResizeObserver loop limit exceeded') {
      return false;
    }
    return windowOnErrorBackup?.(message, url, lineNo, columnNo, error);
  };
}

export default {
  initialize,
  name: 'application',
};
