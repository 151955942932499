import ClarkRoute from '@clarksource/client/routes/-clark-route';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class UpdateAccountRoute extends ClarkRoute {
  @service declare session: Services['session'];

  async beforeModel() {
    await this.waitForSession();
  }

  private async waitForSession(): Promise<void> {
    if (this.session.load.isRunning) {
      await this.session.load.last;
    } else if (!this.session.isAuthenticated && !this.session.load.last) {
      await this.session.load.perform();
    }
  }
}
