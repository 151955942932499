/**
 * WARNING! WARNING! WARNING! WARNING!
 *
 * Do not rename this file to `.ts`. It _must_ use the `.js` file extension.
 *
 * @see https://github.com/babel/ember-cli-babel/issues/240
 */

import Helper from '@ember/component/helper';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
// @ts-expect-error TS(2307) FIXME: Cannot find module 'ember-svg-jar/utils/make-svg' ... Remove this comment to see the full error message
import makeSVG from 'ember-svg-jar/utils/make-svg';
// eslint-disable-next-line import/no-unresolved
import require from 'require';

// @ts-expect-error TS(7006) FIXME: Parameter 'brand' implicitly has an 'any' type.
const getInlineAsset = (brand) => (assetId) => {
  const potentialNames = [
    `ember-svg-jar/inlined/${assetId}.${brand}`,
    `ember-svg-jar/inlined/${assetId}`,
  ];

  const name = potentialNames.find((potentialName) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    require.has(potentialName),
  );
  if (name) {
    return require(name).default;
  }
  return null;
};

/**
 * Overrides the vendor `{{svg-jar}}` helper to support brand overrides.
 *
 * To override an SVG for a specific brand, insert the brand name into the file
 * name, like so:
 *
 * - `example.svg`
 * - `example.weasley.svg`
 * - `example.vkb.svg`
 *
 * For the `weasley` and `vkb` brands, `{{svg-jar "example"}}` will load the
 * customized versions. For all other brands, `example.svg` will be loaded.
 *
 * Keep brand overrides to an _absolute minimum_! They inflate the bundle size
 * and introduce variance / unwanted complexity. When you only need to changes
 * colors, rather apply classes to the paths and customize the colors via CSS.
 */
export default class SVGJarHelper extends Helper {
  @service declare config: Services['config'];

  // @ts-expect-error TS(7031) FIXME: Binding element 'assetId' implicitly has an 'any' ... Remove this comment to see the full error message
  compute([assetId], svgAttributes) {
    const brand = this.config.getConfig('brand');
    return makeSVG(assetId, svgAttributes, getInlineAsset(brand));
  }
}
