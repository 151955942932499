/* eslint-disable ember/no-actions-hash */
import type { User } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { service } from '@ember/service';

export default class IndexMamRoute extends ClarkRoute.extend({
  platform: service('shell/platform'),

  model() {
    // @ts-expect-error: Property 'modelFor' does not exist on type 'Mixin | Record<string, unknown>'
    const user = this.modelFor('index') as User;

    return {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      mamAccountNumber: user.mandate?.loyalty?.mam?.mmAccountNumber,
    };
  },

  actions: {
    didTransition() {
      // @ts-expect-error TS(2339) FIXME: Property 'isNativeAndroid' does not exist on type ... Remove this comment to see the full error message
      if (this.platform.isNativeAndroid) {
        // @ts-expect-error: Property '_super' does not exist on type '{}'
        this._super(false);
      } else {
        // @ts-expect-error: Property '_super' does not exist on type '{}'
        this._super();
      }
    },
  },
}) {}
