import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"custom-checkbox__label cucumber-accept-terms-checkbox\n    {{if @isSelected 'custom-checkbox__label--active'}}\"\n>\n  <span class=\"custom-checkbox__label__tick\">\n    {{svg-jar \"checkbg\"}}\n    <span class=\"custom-checkbox__label__tick__middle\">\n      {{svg-jar \"checked\"}}\n    </span>\n  </span>\n</div>", {"contents":"<div\n  class=\"custom-checkbox__label cucumber-accept-terms-checkbox\n    {{if @isSelected 'custom-checkbox__label--active'}}\"\n>\n  <span class=\"custom-checkbox__label__tick\">\n    {{svg-jar \"checkbg\"}}\n    <span class=\"custom-checkbox__label__tick__middle\">\n      {{svg-jar \"checked\"}}\n    </span>\n  </span>\n</div>","moduleName":"@clarksource/client/components/checkbox-label.hbs","parseOptions":{"srcName":"@clarksource/client/components/checkbox-label.hbs"}});
import templateOnlyComponent from '@ember/component/template-only';

interface CheckboxLabelSignature {
  Args: {
    isSelected?: boolean;
  };
}

const CheckboxLabelComponent = templateOnlyComponent<CheckboxLabelSignature>();

export default CheckboxLabelComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CheckboxLabel: typeof CheckboxLabelComponent;
    'checkbox-label': typeof CheckboxLabelComponent;
  }
}
