import type { User } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { type Registry as Services, service } from '@ember/service';

type Model = {
  code?: string;
  user?: User;
};

export default class ErrorsRoute extends ClarkRoute {
  @service declare user: Services['user'];

  queryParams = {
    code: {},
  };

  async model(params: { code: string }): Promise<Model> {
    const code = params.code ? params.code : undefined;

    try {
      const user = await this.user.getUser();

      return {
        code,
        user,
      };
    } catch {
      return {
        code,
        user: undefined,
      };
    }
  }
}
