import type { ConsentCookie, ConsentCookieCategory } from '../cookie';
import { CONSENT_COOKIE_NAME } from '../cookie';

export function updatedConsentCookie(mandateConsent: ConsentCookie): string {
  const { categories, version } = mandateConsent;

  const consentCookieValue = JSON.stringify({
    categories: categories.map((category) => {
      return {
        enabled: category.enabled,
        identifier: category.identifier,
      };
    }),
    version,
  });

  const maxAge = 60 * 60 * 24 * 365; // 1 year in seconds

  return [
    `${CONSENT_COOKIE_NAME}=${consentCookieValue}`,
    `max-age=${maxAge}`,
    `path=/`,
  ].join(';');
}

export function hasMandateConsentChanged(
  consent1: ConsentCookie,
  consent2: ConsentCookie | null,
): boolean {
  if (!consent2 || consent1.categories.length !== consent2.categories.length) {
    return true;
  }

  const consent2CategoriesMap = new Map(
    consent2.categories.map((category) => [
      category.identifier,
      category.enabled,
    ]),
  );

  return consent1.categories.some((category1) => {
    const category2Enabled = consent2CategoriesMap.get(category1.identifier);
    // Check if the identifier exists and the enabled status matches
    return (
      category2Enabled === undefined || category1.enabled !== category2Enabled
    );
  });
}

export function getCategoryFromConsent(
  consent: ConsentCookie,
  identifier: string,
): ConsentCookieCategory | undefined {
  return consent.categories.find(
    (category) => category.identifier === identifier,
  );
}
