import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  local-class=\"floater-container\"\n  {{in-viewport\n    onEnter=(fn this.updateFloating false)\n    onExit=(fn this.updateFloating true)\n    viewportUseIntersectionObserver=false\n    viewportEnabled=true\n    viewportUseRAF=true\n    viewportSpy=true\n    viewportScrollSensitivity=1\n    viewportRefreshRate=150\n    intersectionThreshold=0\n    viewportTolerance=(hash top=0 bottom=-70 left=0 right=0)\n  }}\n>\n  <div\n    local-class=\"floater {{if this.isFloating 'is-floating'}}\"\n  >\n    <div local-class=\"content\" ...attributes>\n      {{yield}}\n    </div>\n  </div>\n</div>", {"contents":"<div\n  local-class=\"floater-container\"\n  {{in-viewport\n    onEnter=(fn this.updateFloating false)\n    onExit=(fn this.updateFloating true)\n    viewportUseIntersectionObserver=false\n    viewportEnabled=true\n    viewportUseRAF=true\n    viewportSpy=true\n    viewportScrollSensitivity=1\n    viewportRefreshRate=150\n    intersectionThreshold=0\n    viewportTolerance=(hash top=0 bottom=-70 left=0 right=0)\n  }}\n>\n  <div\n    local-class=\"floater {{if this.isFloating 'is-floating'}}\"\n  >\n    <div local-class=\"content\" ...attributes>\n      {{yield}}\n    </div>\n  </div>\n</div>","moduleName":"@clarksource/client/components/floater.hbs","parseOptions":{"srcName":"@clarksource/client/components/floater.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface FloaterSignature {
  Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

export default class FloaterComponent extends Component<FloaterSignature> {
  @tracked isFloating = false;

  @action updateFloating(isFloating: boolean) {
    this.isFloating = isFloating;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Floater: typeof FloaterComponent;
    floater: typeof FloaterComponent;
  }
}
