import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Home::Customer::Rating\n  @isOpen={{this.rating.showModal}}\n  @onClose={{this.onClose}}\n  @onDislike={{this.onDislike}}\n  @onLike={{this.onLike}}\n/>\n\n<RatingFeedback />", {"contents":"<Home::Customer::Rating\n  @isOpen={{this.rating.showModal}}\n  @onClose={{this.onClose}}\n  @onDislike={{this.onDislike}}\n  @onLike={{this.onLike}}\n/>\n\n<RatingFeedback />","moduleName":"@clarksource/client/components/rating.hbs","parseOptions":{"srcName":"@clarksource/client/components/rating.hbs"}});
import type { AppStoreService, PlatformService } from '@clark-shell/ember';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import Component from '@glimmer/component';

interface RatingSignature {
  Args: {};
}

export default class RatingComponent extends Component<RatingSignature> {
  @service declare tracking: Services['tracking'];

  @service('rating/rating') declare rating: Services['rating/rating'];

  @service('shell/app-store') declare appStore: AppStoreService;
  @service('shell/platform') declare platform: PlatformService;

  @action async onClose(): Promise<void> {
    this.tracking.track('click_close_x_rate_us_modal');

    this.rating.dismiss();
  }

  // @ts-expect-error TS(2355) FIXME: A function whose declared type is neither 'void' n... Remove this comment to see the full error message
  @action onDislike(): Promise<void> {
    this.tracking.track('negative_rating_selected');

    this.rating.rate(false);

    // eslint-disable-next-line ember/no-runloop
    later(() => {
      this.rating.showFeedbackModal = !this.rating.showFeedbackModal;
    }, 300);
  }

  @action async onLike(): Promise<void> {
    this.tracking.track('positive_rating_selected');

    this.rating.rate(true);

    if (this.platform.isWeb) {
      this.rating.openWebRating();
    } else if (this.rating.ratingSettings.NATIVE_MODAL_ENABLED) {
      this.appStore.requestReviewOrOpenAppInStore();
    } else {
      this.appStore.openAppInStore();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Rating: typeof RatingComponent;
    rating: typeof RatingComponent;
  }
}
