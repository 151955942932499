import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#unless this.isLoaded}}\n  <span local-class=\"spinner\">\n    <Ui::Spinner @size=\"large\" />\n  </span>\n{{/unless}}\n\n<iframe\n  local-class=\"iframe\"\n  title={{@title}}\n  src={{@src}}\n  frameborder=\"0\"\n  ...attributes\n  {{on \"load\" this.onLoadIframe}}\n>\n</iframe>", {"contents":"{{#unless this.isLoaded}}\n  <span local-class=\"spinner\">\n    <Ui::Spinner @size=\"large\" />\n  </span>\n{{/unless}}\n\n<iframe\n  local-class=\"iframe\"\n  title={{@title}}\n  src={{@src}}\n  frameborder=\"0\"\n  ...attributes\n  {{on \"load\" this.onLoadIframe}}\n>\n</iframe>","moduleName":"@clarksource/client/components/iframe.hbs","parseOptions":{"srcName":"@clarksource/client/components/iframe.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface IframeSignature {
  Args: {
    src: unknown;
    title: unknown;
  };
  Element: HTMLIFrameElement;
}

export default class IframeComponent extends Component<IframeSignature> {
  @tracked isLoaded = false;

  @action
  onLoadIframe() {
    this.isLoaded = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Iframe: typeof IframeComponent;
    iframe: typeof IframeComponent;
  }
}
