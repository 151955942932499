import ClarkRoute from '@clarksource/client/routes/-clark-route';
import type Transition from '@ember/routing/transition';
import { type Registry as Services, service } from '@ember/service';
import * as Sentry from '@sentry/browser';

export default class IndexSelectCategoryRoute extends ClarkRoute {
  @service declare router: Services['router'];

  beforeModel(transition: Transition) {
    Sentry.withScope((scope) => {
      scope.setLevel('warning');
      scope.setExtras({
        message: `Transition from: ${transition.from?.name}`,
      });

      Sentry.captureMessage(
        'Route "index.select-category" is deprecated in favor of "offers.request"',
      );
    });

    this.router.replaceWith('offers.request');
  }
}
