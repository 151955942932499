import Controller from '@ember/controller';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import type { QueryParams } from 'ember-link';

export default class IndexContractsTabController extends Controller {
  @service declare linkManager: Services['link-manager'];

  @action
  buildAddContractLink(query = {}) {
    return this.linkManager.createLink({
      route: 'contracts.create',
      query,
    });
  }

  @action
  buildCustomerRegistrationLink(query: QueryParams) {
    return this.linkManager.createLink({
      route: 'customer.registration',
      query,
    });
  }

  @action
  buildCustomerUpgradeLink(query: QueryParams) {
    return this.linkManager.createLink({
      route: 'customer.upgrade',
      query,
    });
  }
}
