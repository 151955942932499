import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"ember-modal__body__content health-data-consent-modal\"\n>\n  <h4 class=\"ember-modal__body__content__title\">\n    {{t \"account.mandates.consent_modal.title\"}}\n  </h4>\n  <p class=\"ember-modal__body__content__descr\">\n    {{t \"account.mandates.consent_modal.content_one\"}}\n  </p>\n  <p class=\"ember-modal__body__content__descr\">\n    {{t \"account.mandates.consent_modal.content_two\"}}\n  </p>\n</div>", {"contents":"<div\n  class=\"ember-modal__body__content health-data-consent-modal\"\n>\n  <h4 class=\"ember-modal__body__content__title\">\n    {{t \"account.mandates.consent_modal.title\"}}\n  </h4>\n  <p class=\"ember-modal__body__content__descr\">\n    {{t \"account.mandates.consent_modal.content_one\"}}\n  </p>\n  <p class=\"ember-modal__body__content__descr\">\n    {{t \"account.mandates.consent_modal.content_two\"}}\n  </p>\n</div>","moduleName":"@clarksource/client/components/mandate/consent-modal.hbs","parseOptions":{"srcName":"@clarksource/client/components/mandate/consent-modal.hbs"}});
import templateOnlyComponent from '@ember/component/template-only';

interface MandateConsentModalSignature {
  Args: {};
}

const MandateConsentModalComponent =
  templateOnlyComponent<MandateConsentModalSignature>();

export default MandateConsentModalComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Mandate::ConsentModal': typeof MandateConsentModalComponent;
    'mandate/consent-modal': typeof MandateConsentModalComponent;
  }
}
