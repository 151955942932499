import type { RedirectionError } from '@clark-customer/ui/components/customer/onboarding/fde/error-message-screen';
import type { Profile } from '@clarksource/client/services/mandate/fde-onboarding';
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class FdeOnboardingRoute extends Route {
  @service declare router: Services['router'];

  @service('mandate/fde-onboarding')
  declare fdeOnboarding: Services['mandate/fde-onboarding'];

  userProfile?: Profile;

  queryParams = {
    fde_mrmo_order_id: { replace: false },
    fde_jwt: { replace: false },
    fde_token: { replace: false },
    fde_uuid: { replace: false },
    utm_campaign: { replace: false },
    utm_source: { replace: false },
  };

  async beforeModel(transition: Transition): Promise<any> {
    try {
      // pick required properties from query params
      const { fde_mrmo_order_id, fde_jwt, fde_token, fde_uuid, utm_campaign } =
        transition.to?.queryParams as Record<string, string>;

      const response = await this.fdeOnboarding.getProfile({
        fde_mrmo_order_id,
        fde_jwt,
        fde_token,
        fde_uuid,
        utm_campaign,
      });

      if (!response || !response.first_name) {
        // FIXME: extract the exact error type and pass errorType as param later
        this.redirectToErrorPage();
        return;
      }

      this.userProfile = response;
    } catch {
      // FIXME: extract the exact error type and pass errorType as param later
      this.redirectToErrorPage();
    }
  }

  redirectToErrorPage(errorType: RedirectionError = 'generic') {
    this.router.transitionTo('customer.onboarding.fde.error', {
      queryParams: {
        error_type: errorType,
      },
    });
  }

  model() {
    return {
      initialFormValues: {
        email: this.userProfile?.email,
        firstName: this.userProfile?.first_name,
      },
    };
  }
}
