import type { Voucher } from '@clark-customer/entities';
import type { FormFieldErrors } from '@clark-utils/forms';
import { MandateStep } from '@clarksource/client/services/mandate-state';
import { UNPROCESSABLE_ENTITY } from '@clarksource/ember-api/status-codes';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

export default class VoucherJourneyController extends Controller {
  @service declare mandateState: Services['mandate-state'];
  @service declare router: Services['router'];
  @service declare api: Services['api'];
  @service declare sessionStorage: Services['session-storage'];
  @service declare tracking: Services['tracking'];

  @service('mandate/user') declare mandateUser: Services['mandate/user'];

  // @ts-expect-error: TS2339: Property 'api' does not exist on type 'Registry'.
  @service declare router: Services['engine-router'];

  @tracked showVoucherModal = false; // Open Voucher modal
  @tracked voucherNavigationRoute = ''; // Navigation route after Voucher modal
  @tracked voucherTermsConditions = {};

  @tracked
  formFieldErrors: FormFieldErrors<any> = {};

  @action redirectUser(): void {
    this.mandateState.completeAndNavigateToNextStep(MandateStep.VoucherJourney);
  }

  @action onSkipStep(): void {
    this.tracking.track('customer/mandate/promo-code:skip');
    this.mandateState.completeAndNavigateToNextStep(MandateStep.VoucherJourney);
  }

  setupVoucherModal(params: {
    redirectTo: string;
    voucherTermsConditions: any;
  }): void {
    const { redirectTo, voucherTermsConditions } = params;

    const numTermsAndConditions = Object.keys(voucherTermsConditions).length;
    if (numTermsAndConditions === 0) {
      this.router.transitionTo(redirectTo);
      return;
    }

    const { terms_conditions_link, terms_conditions_title } =
      voucherTermsConditions;

    if (!terms_conditions_link || !terms_conditions_title) {
      this.router.transitionTo(redirectTo);
      return;
    }

    this.sessionStorage.setAttr('promotional-voucher-modal', 'seen', true);

    this.showVoucherModal = true;
    this.voucherNavigationRoute = redirectTo;
    this.voucherTermsConditions = voucherTermsConditions;
  }

  @action closeVoucherModal(): void {
    this.router.transitionTo(this.voucherNavigationRoute);
  }

  handleVoucherFormSubmit = task(async (formData: Voucher) => {
    try {
      let { voucherCode } = formData;
      voucherCode = voucherCode.toUpperCase();

      if (!voucherCode) {
        this.tracking.track('customer/mandate/promo-code:skip');
        this.mandateState.completeAndNavigateToNextStep(
          MandateStep.VoucherJourney,
        );
        return;
      }
      const response = (await this.api.patch('customer/voucher', {
        voucher_code: voucherCode,
      })) as any;

      this.tracking.track('customer/mandate/promo-code:entry-success', {
        voucherCode,
      });

      /**
       * We need to reload the session's currentUser so that the user's
       * step completion is reflected elsewhere.
       */
      await this.mandateUser.reloadCurrentUser();

      this.mandateState.completeMandateStep(MandateStep.VoucherJourney);
      const redirectTo = this.mandateState.nextStepRoute();
      const voucherTermsConditions =
        response.data.attributes.voucherTermsConditions ?? {};
      this.setupVoucherModal({
        redirectTo,
        voucherTermsConditions,
      });
    } catch (error: any) {
      if (error.response?.status === UNPROCESSABLE_ENTITY) {
        const errors = error.body?.errors ?? [];
        this.formFieldErrors =
          errors.length > 0
            ? {
                voucherCode: [errors[0].title],
              }
            : {};
      }
    }
  });
}
