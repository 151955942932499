import type { RouteInfoWithAttributes } from '@ember/routing/route-info';
import type Transition from '@ember/routing/transition';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';

/**
 * FIXME:: index.mandate is not really an anon accessible route but it's a special route
 * that the native apps and some routes redirect anonymous/lead users to.
 * Since index.mandate has it's own restriction logic, we keep it out of the protect logic for now.
 */
const anonAccessibleRoutes = [
  'index.mandate',
  'index.imprint',
  'index.data-protection',
];

const rootUrlPrefix = '/de/app';

const loginRoute = 'login';

export default class AuthRedirectService extends Service {
  @service declare experiments: Services['experiments'];
  @service declare session: Services['session'];
  @service declare router: Services['router'];

  async protectRoute(transition: Transition): Promise<void> {
    await this.waitForSession();

    if (!this.shouldRedirectToLogin(transition)) {
      return;
    }

    if (this.session.currentUser?.lead) {
      this.redirectToMandate();
      return;
    }

    this.fetchUrlAndRedirectToLogin(transition);
  }

  fetchUrlAndRedirectToLogin(transition: Transition): void {
    // @ts-expect-error TS(2339) FIXME: Property 'intent' does not exist on type 'Transiti... Remove this comment to see the full error message
    let url = transition.intent?.url;

    /**
     * Transition.intent is not documented in Ember,
     * but it exists at runtime and is the only solution to get the correct URL in most cases. So try it first.
     * Note that Transition.intent.url is not available when an internal redirection using transitionTo/replaceWith occurs.
     */
    if (url) {
      // Add the rootUrlPrefix for consistency
      this.redirectToLogin(`${rootUrlPrefix}${url}`);
      return;
    }

    const { name, params, queryParams } =
      transition.to as RouteInfoWithAttributes;

    try {
      // handle URL with dynamic segments
      if (Object.keys(params as object).length > 0) {
        url = this.router.urlFor(name, params, { queryParams });
      } else {
        url = this.router.urlFor(name, { queryParams });
      }
    } catch {
      url = '';
    }

    this.redirectToLogin(url);
  }

  private redirectToLogin(callbackUrl: string): void {
    const callback = encodeURIComponent(callbackUrl);
    // Redirect to the login route with the intended route as a query parameter
    this.router.replaceWith(loginRoute, { queryParams: { callback } });
  }

  private redirectToMandate(): void {
    this.router.replaceWith('index.mandate');
  }

  private async waitForSession(): Promise<void> {
    if (this.session.load.isRunning) {
      await this.session.load.last;
    } else if (!this.session.isAuthenticated && !this.session.load.last) {
      await this.session.load.perform();
    }
  }

  private shouldRedirectToLogin(transition: Transition): boolean {
    if (transition.to?.name === loginRoute) {
      return false;
    }

    if (this.session.isAuthenticated) {
      return false;
    }

    const isClark1 =
      this.experiments.getVariant('business-strategy') === 'control';

    if (!isClark1) {
      return false;
    }

    const isAnonAccessibleRoute = anonAccessibleRoutes.some((route) =>
      transition.to?.name.startsWith(route),
    );

    if (isAnonAccessibleRoute) {
      return false;
    }

    const isLeadAndRedirectedToManager =
      this.session.currentUser?.lead &&
      transition.to?.name.startsWith('index.manager');

    if (isLeadAndRedirectedToManager) {
      return false;
    }

    /**
     * For all other cases, user needs to login
     */
    return true;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'auth-redirect': AuthRedirectService;
  }
}
