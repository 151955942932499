import Controller from '@ember/controller';

export default class ArticleController extends Controller {
  queryParams = [
    'cta',
    'goBack',
    'ctaText',
    'articleLink',
    'title',
    'notificationCentreOpen',
    'appointmentBooking',
  ];

  cta?: string;
  goBack?: string;
  ctaText?: string;
  articleLink?: string;
  title?: string;
  notificationCentreOpen?: string;
  appointmentBooking?: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    articles: ArticleController;
  }
}
