import type { User } from '@clark-utils/enums-and-types';
import { retryOnNetworkFailure } from '@clarksource/ember-api/utils';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';
import * as Sentry from '@sentry/browser';

// eslint-disable-next-line import/namespace
export interface SentryUserContextData extends Sentry.User {
  lead_id?: number;
  user_id?: number;
}

export default class UserService extends Service {
  @service declare api: Services['api'];
  @service declare config: Services['config'];
  @service declare session: Services['session'];

  // @TODO we need to replace this when we merge the users
  // the route-restrictionservice (which I think was the only beneficiary of this method) is no longer used,
  //  but since this is setting user in session service,
  // make sure it doesn't affect others before removing.
  private setCurrentUserOnRouteRestrictionService(user: User) {
    this.session.currentUser = user;
  }

  async getUser(): Promise<User> {
    try {
      const response = (await retryOnNetworkFailure(() =>
        this.api.get('current_user'),
      )) as { lead?: User; user?: User };

      const user = (response.user || response.lead) as User;
      (user as User & { lead: boolean }).lead = 'installation_id' in user;

      this.setUserContext(user);

      this.setCurrentUserOnRouteRestrictionService(user);
      return user;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  private setUserContext(user: User) {
    if (!this.config.getConfig('allowSentryUserIds')) {
      return;
    }

    const userType = (user as User & { lead?: boolean }).lead ? 'lead' : 'user';
    const userId = user.id;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const mandateId = user.mandate && user.mandate.id;

    const userContext: SentryUserContextData = {};

    if (mandateId) {
      this.api.userID = `mandate=${mandateId}`;
      userContext.id = `${mandateId}`;
    }

    if (userId) {
      if (!mandateId) {
        this.api.userID = `${userType}=${userId}`;
        userContext.id = `${userType}=${userId}`;
      }

      userContext[
        (user as User & { lead?: boolean }).lead ? 'lead_id' : 'user_id'
      ] = userId;
    }

    Sentry.getCurrentScope().setUser(userContext);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    user: UserService;
  }
}
