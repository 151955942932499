import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { action } from '@ember/object';
import { type Registry as Services, service } from '@ember/service';

export default class MandateCustomFunnel extends ClarkRoute {
  @service declare router: Services['router'];

  model() {
    return {
      user: this.modelFor('index'),
    };
  }

  @action
  navigateTo(route: string) {
    this.router.transitionTo(route);
  }

  @action
  didTransition() {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    super.actions.didTransition.call(this, false);
  }
}
