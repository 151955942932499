/* eslint-disable ember/classic-decorator-no-classic-methods */
import type Opportunity from '@clark-home/ui/models/opportunity';
import type { Questionnaire } from '@clark-utils/enums-and-types';
import { retryOnNetworkFailure } from '@clarksource/ember-api/utils';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';

type OpportunityCreator = (
  questionnaire: Questionnaire,
  response: { id: number; responseId: string },
) => Promise<Opportunity>;

export default class QuestionnaireService extends Service {
  @service declare api: Services['api'];
  @service declare tracking: Services['tracking'];

  response = '';

  responseId = '';

  subResponses = null;

  private responseIdMap: { [responseId: string]: number } = {};

  private questionnaires: { [id: string]: Questionnaire } = {};

  private opportunityCreatorsByIdentifier: {
    [id: string]: OpportunityCreator;
  } = {};

  private opportunityCreatorsByCategory: {
    [id: string]: OpportunityCreator;
  } = {};

  /**
   * Registers an opportunity creator function for a questionnaire that will be
   * used once for explicitly creating an opportunity for a questionnaire.
   *
   * Make sure that the created opportunity matches the server-side filter
   * criteria for reassignment.
   *
   * @see https://github.com/ClarkSource/application/blob/e17272211558fe47909592600fff10a641399435/app/api/clark_api/v1/questionnaires.rb#L251-L261
   *
   * @param id The identifier of the questionnaire
   * @param createOpportunity The function to call before finishing the
   *  questionnaire. Receives the questionnaire and response ID.
   */
  registerOpportunityByIdentifier(
    id: string,
    createOpportunity: OpportunityCreator,
  ) {
    this.opportunityCreatorsByIdentifier[id] = createOpportunity;
  }

  /**
   * Registers an opportunity creator function for a questionnaire matched by
   * its category that will be used once for explicitly creating an opportunity
   * for a questionnaire.
   *
   * Make sure that the created opportunity matches the server-side filter
   * criteria for reassignment.
   *
   * @see https://github.com/ClarkSource/application/blob/e17272211558fe47909592600fff10a641399435/app/api/clark_api/v1/questionnaires.rb#L251-L261
   *
   * @param id The identifier of the category
   * @param createOpportunity The function to call before finishing the
   *  questionnaire. Receives the questionnaire and response ID.
   */
  registerOpportunityByCategory(
    id: string,
    createOpportunity: OpportunityCreator,
  ) {
    this.opportunityCreatorsByCategory[id] = createOpportunity;
  }

  /**
   * Creates an opportunity for the questionnaire response, if a matching
   * opportunity creator function has been registered.
   *
   * If the creation was successful, the creator is de-registered, so that it is
   * only ever used once.
   *
   * @param questionnaire
   * @param responseId
   */
  private async createOpportunity(
    questionnaire: Questionnaire,
    responseId: string,
  ) {
    let opportunityCreator;
    if (questionnaire.identifier in this.opportunityCreatorsByIdentifier) {
      opportunityCreator =
        this.opportunityCreatorsByIdentifier[questionnaire.identifier];
      delete this.opportunityCreatorsByIdentifier[questionnaire.identifier];
    } else if (
      questionnaire.category_ident in this.opportunityCreatorsByCategory
    ) {
      opportunityCreator =
        this.opportunityCreatorsByCategory[questionnaire.category_ident];
      delete this.opportunityCreatorsByCategory[questionnaire.category_ident];
    }

    if (!opportunityCreator) {
      return null;
    }

    const id = this.responseIdMap[responseId];
    const opportunity = await opportunityCreator(questionnaire, {
      responseId,
      // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
      id,
    });
    delete this.responseIdMap[responseId];

    return opportunity;
  }

  /**
   * checking for jump condition in the question, if its satisfied, index of the jump question is returned
   *
   * @param question
   * @param questionnaire
   * @param identifier
   *
   * @returns { default increment by 1 if no condition for jump is satisfied else returns the index of the question which satisfies the jump question condition}
   */
  jumpLogicNextIndex(question: any, questionnaire: any, identifier: string) {
    if (!question.jumps) {
      return -1;
    }
    const { jumps } = question;
    let destination = '';
    for (const element of jumps) {
      destination = element.destination.id;
      if (element.conditions === 'true') {
        const nextQuestionId = this.findElementInArrayObject(
          questionnaire.questions,
          identifier,
          destination,
        );
        return nextQuestionId;
      }
      for (const conditon of element.conditions) {
        const conditionalQuestionIndex = this.findElementInArrayObject(
          questionnaire.questions,
          identifier,
          conditon.field,
        );
        if (
          questionnaire.questions[conditionalQuestionIndex].question_type !==
          'text'
        ) {
          const options =
            questionnaire.questions[conditionalQuestionIndex].options ||
            questionnaire.questions[conditionalQuestionIndex].choices;
          for (const conditionalChoice of options) {
            if (
              conditionalChoice.value === conditon.value &&
              conditionalChoice.selected
            ) {
              const nextQuestionId = this.findElementInArrayObject(
                questionnaire.questions,
                identifier,
                destination,
              );
              return nextQuestionId;
            }
          }
        }
      }
    }
    return -1;
  }

  /**
   *
   * @param array
   * @param key
   * @param value
   * @returns {index of the searched value in key and if nothing is found return -1}
   */
  findElementInArrayObject(array: unknown[], key: string, value: string) {
    return array.findIndex((q: any) => q[key] === value);
  }

  /**
   * Loads a questionnaire by identifier and stores it in memory
   * for faster access. Subsequent requests for the same questionnaire
   * will be served from memory.
   *
   * @param questionnaireId - The questionnaire to load.
   */
  async getQuestionnaire(questionnaireId: string) {
    if (questionnaireId in this.questionnaires) {
      return this.questionnaires[questionnaireId];
    }

    // @ts-expect-error TS(2339) FIXME: Property 'questionnaire' does not exist on type 'R... Remove this comment to see the full error message
    const { questionnaire } = await retryOnNetworkFailure(() =>
      this.api.get(`questionnaires/${questionnaireId}`, { version: 'v1' }),
    );

    this.questionnaires[questionnaireId] = questionnaire;
    return questionnaire;
  }

  /**
   * Finishes a questionnaire. If an opportunity creator has been registered, it
   * will be run beforehand. This means that, if the opportunity matches the
   * server-side filter criteria for reassignment, this questionnaire response
   *  will be linked to  the previously created opportunity.
   *
   * @see https://github.com/ClarkSource/application/blob/e17272211558fe47909592600fff10a641399435/app/api/clark_api/v1/questionnaires.rb#L251-L261
   *
   * @param questionnaireId - The identifier of the questionnaire to finisch/
   * @param responseId - The response ID that was generated by the server to use
   *  when finishing this questionnaire.
   */
  async finishQuestionnaire(questionnaireId: string, responseId: string) {
    await this.createOpportunity(
      await this.getQuestionnaire(questionnaireId),
      responseId,
    );

    return retryOnNetworkFailure(() =>
      this.api.patch(
        `questionnaires/${questionnaireId}/responses/${responseId}/finish`,
        undefined,
        { version: 'v1' },
      ),
    );
  }

  /*
   * Methods for Retirement and Demandcheck Services
   */

  setResponseId(responseId: any) {
    this.set('responseId', responseId);
  }

  initquestionnaire(
    questionnaireId: string,

    // @ts-expect-error TS(2304) FIXME: Cannot find name 'User'.
    _user: User,
    trackingEvent: string,
  ) {
    // clark tracking
    this.tracking.track('init_questionnaire', {
      source: trackingEvent,
    });

    return retryOnNetworkFailure(() =>
      this.api.post(`questionnaires/${questionnaireId}/responses`),
    );
  }

  /**
   * finishes the Demandcheck and Retirementcheck ONLY
   * @return RSVP Promise
   */
  finish(id: string) {
    return retryOnNetworkFailure(() =>
      this.api.patch(`questionnaires/${id}/finish`),
    );
  }

  sendUpdatedSalaryToMarketingCloud() {
    retryOnNetworkFailure(() =>
      this.api.post('questionnaires/bedarfcheck/finish/salary-updated'),
    );
  }

  /**
   * answers a single question
   * ONLY for Demandcheck and Retirementcheck
   * @return RSVP Promise
   */
  answer(questionnaireid: string, questionId: any, answerText: any) {
    return retryOnNetworkFailure(() =>
      this.api.patch(`questionnaires/${questionnaireid}/answers`, {
        answers: [
          {
            answer: {
              text: `${answerText}`,
            },
            question_id: `${questionId}`,
          },
        ],
      }),
    );
  }

  /**
   * submits multiple answers, with sub-options
   * ONLY for Demandcheck and Retirementcheck
   * @return RSVP Promise
   */

  // @ts-expect-error TS(7006) FIXME: Parameter 'answers' implicitly has an 'any' type.
  answers(questionnaireid: string, answers) {
    return retryOnNetworkFailure(() =>
      this.api.patch(`questionnaires/${questionnaireid}/answers`, {
        answers,
      }),
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    questionnaire: QuestionnaireService;
  }
}
