import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"card\">\n  <Ui::Badge @appearance=\"primary\">\n    {{t \"mandate.intro_short.badge\"}}\n  </Ui::Badge>\n\n  <Ui::Icon\n    @size=\"free\"\n    @source={{concat \"icon-\" @campaign \"-clark-joined-logos\"}}\n  />\n\n  <h2 local-class=\"title\">\n    {{t \"mandate.fde-consent.headline\"}}\n  </h2>\n\n  <p>\n    {{t \"mandate.fde-consent.teaser\" campaign=@campaign}}\n  </p>\n\n  <p\n    local-class=\"consent\"\n    data-test-mandate-fde-consent=\"consent\"\n  >\n    <Shell::SettingsRoute::Toggle\n      @label={{t \"mandate.fde-consent.disclaimer\"}}\n      @checked={{@isChecked}}\n      @update={{@onUpdate}}\n    />\n  </p>\n</div>", {"contents":"<div local-class=\"card\">\n  <Ui::Badge @appearance=\"primary\">\n    {{t \"mandate.intro_short.badge\"}}\n  </Ui::Badge>\n\n  <Ui::Icon\n    @size=\"free\"\n    @source={{concat \"icon-\" @campaign \"-clark-joined-logos\"}}\n  />\n\n  <h2 local-class=\"title\">\n    {{t \"mandate.fde-consent.headline\"}}\n  </h2>\n\n  <p>\n    {{t \"mandate.fde-consent.teaser\" campaign=@campaign}}\n  </p>\n\n  <p\n    local-class=\"consent\"\n    data-test-mandate-fde-consent=\"consent\"\n  >\n    <Shell::SettingsRoute::Toggle\n      @label={{t \"mandate.fde-consent.disclaimer\"}}\n      @checked={{@isChecked}}\n      @update={{@onUpdate}}\n    />\n  </p>\n</div>","moduleName":"@clarksource/client/components/mandate/fde-consent.hbs","parseOptions":{"srcName":"@clarksource/client/components/mandate/fde-consent.hbs"}});
import templateOnlyComponent from '@ember/component/template-only';

/**
 * A card which contains optional badge, logo of {campaign}<3CLARK, headline,
 * teaser and toggle, allowing user to accept/refuse something
 * (e.g. fde consent).
 */
interface MandateFdeConsentSignature {
  Args: {
    /**
     * Which campaign the user came via.
     * E.g. anna or cp
     */
    campaign: string;
    /**
     * Initial value for the checkbox.
     */
    isChecked: boolean;
    /**
     * When the checkbox's value changes.
     */
    onUpdate: () => boolean;
  };
}

const MandateFdeConsentComponent =
  templateOnlyComponent<MandateFdeConsentSignature>();

export default MandateFdeConsentComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Mandate::FdeConsent': typeof MandateFdeConsentComponent;
    'mandate/fde-consent': typeof MandateFdeConsentComponent;
  }
}
