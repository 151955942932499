import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"list-container\" data-test-mandate-terms>\n  <p local-class=\"terms-title\">\n    {{t \"mandate.intro_short.features.header\"}}\n  </p>\n\n  <ul local-class=\"list\">\n    {{#each this.mandateTerms as |term|}}\n      <li local-class=\"list-item\" data-test-mandate-terms-item>\n        <div local-class=\"item\">\n          <Ui::Icon\n            local-class=\"item-svg\"\n            @source={{term.icon}}\n            @size=\"free\"\n          />\n        </div>\n\n        <div local-class=\"term-title\">\n          {{term.title}}\n        </div>\n\n        <p local-class=\"item-description\">\n          {{term.description}}\n        </p>\n      </li>\n    {{/each}}\n  </ul>\n</div>", {"contents":"<div local-class=\"list-container\" data-test-mandate-terms>\n  <p local-class=\"terms-title\">\n    {{t \"mandate.intro_short.features.header\"}}\n  </p>\n\n  <ul local-class=\"list\">\n    {{#each this.mandateTerms as |term|}}\n      <li local-class=\"list-item\" data-test-mandate-terms-item>\n        <div local-class=\"item\">\n          <Ui::Icon\n            local-class=\"item-svg\"\n            @source={{term.icon}}\n            @size=\"free\"\n          />\n        </div>\n\n        <div local-class=\"term-title\">\n          {{term.title}}\n        </div>\n\n        <p local-class=\"item-description\">\n          {{term.description}}\n        </p>\n      </li>\n    {{/each}}\n  </ul>\n</div>","moduleName":"@clarksource/client/components/mandate/signature/intro/mandate-terms.hbs","parseOptions":{"srcName":"@clarksource/client/components/mandate/signature/intro/mandate-terms.hbs"}});
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import Component from '@glimmer/component';

type MandateTerm = {
  description: string;
  icon: string;
  title: string;
};

interface MandateSignatureIntroMandateTermsSignature {
  Args: {};
}

export default class MandateSignatureIntroMandateTermsComponent extends Component<MandateSignatureIntroMandateTermsSignature> {
  @service declare intl: Services['intl'];

  get mandateTerms(): MandateTerm[] {
    return [
      {
        description: this.intl.t(
          'mandate.intro_short.features.well_insured.description',
          {
            htmlSafe: true,
          },
        ),
        icon: 'marked-shield-2',
        title: this.intl.t('mandate.intro_short.features.well_insured.title', {
          htmlSafe: true,
        }),
      },
      {
        description: this.intl.t(
          'mandate.intro_short.features.one_app.description',
          {
            htmlSafe: true,
          },
        ),
        icon: 'laptop-smartphone',
        title: this.intl.t('mandate.intro_short.features.one_app.title', {
          htmlSafe: true,
        }),
      },
      {
        description: this.intl.t(
          'mandate.intro_short.features.free_service.description2',
          {
            htmlSafe: true,
          },
        ),
        icon: 'deal',
        title: this.intl.t('mandate.intro_short.features.free_service.title', {
          htmlSafe: true,
        }),
      },
      {
        description: this.intl.t(
          'mandate.intro_short.features.full_control.description1',
          {
            htmlSafe: true,
          },
        ),
        icon: 'presentation',
        title: this.intl.t('mandate.intro_short.features.full_control.title', {
          htmlSafe: true,
        }),
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Mandate::Signature::Intro::MandateTerms': typeof MandateSignatureIntroMandateTermsComponent;
    'mandate/signature/intro/mandate-terms': typeof MandateSignatureIntroMandateTermsComponent;
  }
}
