import type { DocumentViewerService } from '@clark-shell/ember';
import Helper from '@ember/component/helper';
import { service } from '@ember/service';

// @TODO: pull out as own addon

export default class OpenPdfHelper extends Helper {
  @service('shell/document-viewer')
  declare documentViewer: DocumentViewerService;

  compute([url]: [string]) {
    return () => {
      this.documentViewer.open(url);
    };
  }
}
