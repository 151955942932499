import type { User } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { action } from '@ember/object';
// eslint-disable-next-line import/no-unresolved
import { hash } from 'rsvp';

export default class MandateSignUpRoute extends ClarkRoute {
  model() {
    return hash({
      mandate: (this.modelFor('index') as User).mandate,
      user: this.modelFor('index') as User,
      customFunnelParams: this.modelFor('index.mandate.index'),
    });
  }

  @action
  didTransition() {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    super.actions.didTransition.call(this, false);
  }
}
