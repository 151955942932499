import type { Offer, OfferOption } from '@clark-utils/enums-and-types';
import { retryOnNetworkFailure } from '@clarksource/ember-api/utils';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';

interface RejectOfferData {
  mandate_id: number;
  reasons: string;
}

export default class OfferService extends Service {
  @service declare api: Services['api'];

  async getOffer(id: number) {
    const offer = await retryOnNetworkFailure(
      () =>
        this.api.get(`offers/${id}`, { version: 'v5' }) as {
          data?: { attributes?: {} };
        },
    );

    return offer.data?.attributes;
  }

  async getUpdatedOfferOptions(id: number): Promise<OfferOption[]> {
    const response = await retryOnNetworkFailure(
      () =>
        this.api.put(`odin_offers/coverage_features/mr_money/${id}`, {
          version: 'v5',
        }) as unknown as Offer,
    );

    return response.options as OfferOption[];
  }

  async getNewRegeneratedOfferId(id: number): Promise<number> {
    const response = await retryOnNetworkFailure(
      () =>
        this.api.put(`odin_offers/regenerate_offer/${id}`, {
          version: 'v5',
        }) as unknown as { id: number },
    );

    return response.id;
  }

  updateOfferOptions(offer: Offer, offerOptions: OfferOption[]) {
    const offerOptionsMap: { [x: number]: OfferOption } = offerOptions.reduce(
      (accumulator, offerOption: OfferOption) => {
        return { ...accumulator, [offerOption.id]: offerOption };
      },
      {},
    );

    offer.options.forEach((offerOption: OfferOption) => {
      const updatedOfferOption: OfferOption | undefined =
        offerOptionsMap[offerOption.id];

      if (
        updatedOfferOption &&
        offerOption.product.id === updatedOfferOption.product.id
      ) {
        offerOption.product.coverages = updatedOfferOption.product.coverages;
        offerOption.product.detailed_coverages =
          updatedOfferOption.product.detailed_coverages;
      }
    });
  }

  checkCoveragesExist(offer: Offer) {
    const hasNoCoverages = offer.options.some((offerOption) => {
      const { coverages, detailed_coverages } = offerOption.product;

      /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
      if (!coverages || Object.keys(coverages).length === 0) {
        return true;
      }

      /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
      if (!detailed_coverages || Object.keys(detailed_coverages).length === 0) {
        return true;
      }

      return false;
    });

    return !hasNoCoverages;
  }

  rejectOffer(id: number, mailData: RejectOfferData | boolean) {
    return retryOnNetworkFailure(() => {
      if (mailData) {
        // if there is a reason for the cancelation, send it on down
        return this.api.post(`offer/${id}/reject`, mailData);
      }

      return this.api.patch(`offers/${id}/reject`);
    });
  }

  acceptOffer(id: number, productId: number, sendEmail = false) {
    return retryOnNetworkFailure(() =>
      this.api.patch(`offer/${id}/accept/${productId}`, {
        send_email: sendEmail,
      }),
    );
  }

  sendEventOnOffer(id: number, productId: number) {
    return this.api.post(`offer/${id}/accept/${productId}/accept`, {
      offer_id: id,
    });
  }

  markOfferRead(id: number) {
    return retryOnNetworkFailure(() =>
      this.api.patch(`offers/${id}/mark_as_read`),
    );
  }

  sendOfferEmail(id: number) {
    return retryOnNetworkFailure(() =>
      this.api.patch(`offer/${id}/send_offer_email`),
    );
  }

  selectOfferAsActive(id: number) {
    return retryOnNetworkFailure(() =>
      this.api.patch(`offer/${id}/select_as_active`),
    );
  }

  generateOfferDocuments(id: number, productId: number) {
    return retryOnNetworkFailure(() =>
      this.api.patch(`offer/${id}/generate_documents/${productId}`),
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    offer: OfferService;
  }
}
