import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  local-class=\"legal-links-container\"\n  data-test-legal-links\n  {{native-external-link}}\n  ...attributes\n>\n  <a\n    href=\"/de/impressum\"\n    rel=\"noopener noreferrer\"\n    target=\"_blank\"\n    local-class=\"link\"\n    {{on\n      \"click\"\n      (track\n        \"V2_mini_footer_click_impressum\" url=this.currentURL\n      )\n    }}\n  >\n    {{t \"legal_links.imprint\"}}\n  </a>\n  <a\n    href=\"/de/datenschutz\"\n    rel=\"noopener noreferrer\"\n    target=\"_blank\"\n    local-class=\"link\"\n    {{on\n      \"click\"\n      (track\n        \"V2_mini_footer_click_datasecurity\" url=this.currentURL\n      )\n    }}\n  >\n    {{t \"legal_links.privacy\"}}\n  </a>\n</div>", {"contents":"<div\n  local-class=\"legal-links-container\"\n  data-test-legal-links\n  {{native-external-link}}\n  ...attributes\n>\n  <a\n    href=\"/de/impressum\"\n    rel=\"noopener noreferrer\"\n    target=\"_blank\"\n    local-class=\"link\"\n    {{on\n      \"click\"\n      (track\n        \"V2_mini_footer_click_impressum\" url=this.currentURL\n      )\n    }}\n  >\n    {{t \"legal_links.imprint\"}}\n  </a>\n  <a\n    href=\"/de/datenschutz\"\n    rel=\"noopener noreferrer\"\n    target=\"_blank\"\n    local-class=\"link\"\n    {{on\n      \"click\"\n      (track\n        \"V2_mini_footer_click_datasecurity\" url=this.currentURL\n      )\n    }}\n  >\n    {{t \"legal_links.privacy\"}}\n  </a>\n</div>","moduleName":"@clarksource/client/components/legal-links.hbs","parseOptions":{"srcName":"@clarksource/client/components/legal-links.hbs"}});
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import Component from '@glimmer/component';

interface LegalLinksSignature {
  Args: {};
  Element: HTMLDivElement;
}

export default class LegalLinksComponent extends Component<LegalLinksSignature> {
  @service declare router: Services['router'];

  get currentURL() {
    return this.router.currentURL;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    LegalLinks: typeof LegalLinksComponent;
    'legal-links': typeof LegalLinksComponent;
  }
}
