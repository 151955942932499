import type { PlatformService } from '@clark-shell/ember';
import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class DocumentUrlHelper extends Helper {
  @service('shell/platform') declare platform: PlatformService;

  compute([routeUrl, pdfUrl]: [string, string]): string {
    if (this.platform.isNative && pdfUrl) {
      return `/${pdfUrl}`;
    }

    // FIXME:: Support localized urls rather than having static `de` locale
    return routeUrl ? `/de/${routeUrl}` : '';
  }
}
