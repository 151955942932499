import Route from '@ember/routing/route';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import * as Sentry from '@sentry/browser';

type ArticleRouteModel = {
  appointmentBooking?: string;
  articleUrl: string;
  cta: string;
  ctaText: string;
  goBack: string;
  id?: string;
  notificationCentreOpen?: string;
  title?: string;
};

type QueryParams = {
  appointmentBooking?: string;
  articleLink?: string;
  cta: string;
  ctaText: string;
  goBack: string;
  id?: string;
  notificationCentreOpen?: string;
  title?: string;
};

/**
 *
 * @param UrlParam
 *
 * URL to be validated if its a valid clark URL and we don't load any external URL
 * as an article in our iframe
 *
 * @returns
 *
 * boolean based on conditions. If false, we raise a sentry error with the URL used to track the error
 *
 */
function validArticleLink(UrlParam: URL): boolean {
  return (
    UrlParam.protocol === 'https:' &&
    UrlParam.host === 'www.clark.de' &&
    UrlParam.hostname === 'www.clark.de' &&
    UrlParam.origin === `${UrlParam.protocol}//www.clark.de`
  );
}

export default class ArticleRoute extends Route {
  @service declare config: Services['config'];
  @service declare router: Services['router'];

  model(params: QueryParams): ArticleRouteModel | void {
    const {
      appointmentBooking,
      articleLink,
      cta,
      ctaText,
      goBack,
      id,
      notificationCentreOpen,
      title,
    } = params;

    let articleUrl = this.config.getConfig(`articles.${id}`) as string;

    if (articleLink && validArticleLink(new URL(articleLink))) {
      articleUrl = articleLink;
    }

    if (articleUrl && cta && goBack && ctaText) {
      return {
        appointmentBooking,
        articleUrl,
        cta,
        ctaText,
        goBack,
        id,
        notificationCentreOpen,
        title,
      };
    }

    Sentry.captureMessage('Invalid article', {
      extra: {
        articleUrl,
        cta,
        ctaText,
        goBack,
        id,
      },
      level: 'error',
    });

    this.router.transitionTo('index');
  }
}
