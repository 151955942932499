import type { Mandate } from '@clark-utils/enums-and-types';
import { MandateStep } from '@clarksource/client/services/mandate-state';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const supportedIntentsForCustomIntro: NonNullable<
  Mandate['customer_intent']
>[] = [
  'buying-journey',
  'mandate-funnel-understand-needs',
  'mandate-funnel-insurance-overview',
  'mandate-funnel-get-voucher',
  'open-wallet',
];

export const intentsEligibleForNewCustomIntro: Partial<
  Mandate['customer_intent']
>[] = ['buying-journey', 'mandate-funnel-understand-needs', 'open-wallet'];

export default class MandateIndexStatusController extends Controller {
  @service declare mandateState: Services['mandate-state'];
  @service declare features: Services['features'];
  @service declare experiments: Services['experiments'];
  @tracked variant: string | undefined;

  get intentionKey(): Mandate['customer_intent'] {
    // @ts-expect-error: Property 'user' does not exist on type 'unknown'
    const { user } = this.model;

    return user?.mandate?.customer_intent;
  }

  get shouldShowCustomizedIntroPage(): boolean {
    if (!this.intentionKey) {
      return false;
    }

    if (!supportedIntentsForCustomIntro.includes(this.intentionKey)) {
      return false;
    }

    if (intentsEligibleForNewCustomIntro.includes(this.intentionKey)) {
      return this.shouldShowNewCustomIntro();
    }

    return true;
  }

  private get personalizedBuyingIntentionExperimentVariant(): string {
    if (
      !this.features.isEnabled('MANDATE_FUNNEL_PERSONALIZED_BUYING_INTENTION')
    ) {
      return 'control';
    }
    return this.experiments.getVariant('2023Q2PersonalizedBuyingIntention');
  }

  private get personalizedDemandCheckIntentionExperimentVariant(): string {
    if (
      !this.features.isEnabled(
        'MANDATE_FUNNEL_PERSONALIZED_DEMANDCHECK_INTENTION',
      )
    ) {
      return 'control';
    }
    return this.experiments.getVariant(
      '2023Q2PersonalizedDemandCheckIntention',
    );
  }

  private shouldShowNewCustomIntro(): boolean {
    switch (this.intentionKey) {
      case 'buying-journey': {
        this.variant = this.personalizedBuyingIntentionExperimentVariant;
        // for buying intention, we only show the new custom intro for v2 and
        // control, v1 users the old version
        return ['v2', 'control'].includes(this.variant);
      }
      case 'mandate-funnel-understand-needs': {
        this.variant = this.personalizedDemandCheckIntentionExperimentVariant;
        // for demandcheck intention, we only show the new custom intro for v2, v3, and
        // control, v1 users the old version
        return ['v2', 'v3', 'control'].includes(this.variant);
      }
      default:
        return true;
    }
  }

  @action
  onSubmit() {
    this.mandateState.completeAndNavigateToNextStep(MandateStep.Status);
  }
}
