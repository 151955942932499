import type { CensusDemandCheck } from '@clark-utils/enums-and-types';
import Route from '@ember/routing/route';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class DemandcheckPrimerRoute extends Route {
  @service declare api: Services['api'];
  @service declare features: Services['features'];

  @tracked templateId?: number;
  @tracked censusTemplateName?: string;

  queryParams = {
    source: {
      refreshModel: false,
      replace: true,
    },
    goBack: {
      refreshModel: false,
      replace: true,
    },
  };

  async beforeModel() {
    if (!this.features.isEnabled('DEMANDCHECK_CENSUS_QUESTIONNAIRE')) {
      return;
    }

    const response = (await this.api.get(
      'questionnaires/bedarfcheck/demand_check_template',
      {
        version: 'v5',
      },
    )) as CensusDemandCheck;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (response?.active) {
      this.templateId = response.template_id;
      this.censusTemplateName = response.template_name;
    }
  }

  model() {
    if (this.templateId) {
      return {
        censusTemplateId: this.templateId,
        censusTemplateName: this.censusTemplateName,
      };
    }
  }
}
