import type { User } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { MandateStep } from '@clarksource/client/services/mandate-state';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class PhoneVerification extends ClarkRoute {
  @service declare mandateState: Services['mandate-state'];
  @service declare router: Services['router'];

  @service('mandate/fde-onboarding')
  declare fdeOnboarding: Services['mandate/fde-onboarding'];

  // @ts-expect-error TS(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
  async beforeModel(...args) {
    // @ts-expect-error: A spread argument must either have a tuple type or be passed to a rest parameter
    super.beforeModel(...args);

    if (await this.fdeOnboarding.importPhoneNumber()) {
      const user = this.modelFor('index') as User;

      user.mandate.primary_phone_verified = true;

      this.mandateState.completeAndNavigateToNextStep(
        MandateStep.PhoneVerification,
        user,
      );
    }
  }

  model() {
    return {
      user: this.modelFor('index'),
      customFunnelParams: this.modelFor('index.mandate.index'),
    };
  }

  @action
  navigateTo(route: string) {
    this.router.transitionTo(route);
  }

  @action
  didTransition() {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    super.actions.didTransition.call(this, false);
  }
}
