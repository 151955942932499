import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-at-ember-render-modifiers no-invalid-interactive }}\n<section\n  class=\"cucumber-messenger-window\"\n  data-test-messenger-window\n  local-class=\"messenger-window {{if this.window.open 'open'}}\"\n>\n  <span\n    data-test-messenger-window-pixel\n    local-class=\"pixel\"\n    {{did-insert this.setupPixel}}\n  ></span>\n\n  {{#unless this.platform.isNative}}\n    <span\n      data-test-messenger-window-close\n      local-class=\"close\"\n      {{on \"click\" this.window.close}}\n    >\n      {{svg-jar \"close\"}}\n    </span>\n  {{/unless}}\n\n  <Messenger @navigate={{this.navigate}} />\n</section>", {"contents":"{{! template-lint-disable no-at-ember-render-modifiers no-invalid-interactive }}\n<section\n  class=\"cucumber-messenger-window\"\n  data-test-messenger-window\n  local-class=\"messenger-window {{if this.window.open 'open'}}\"\n>\n  <span\n    data-test-messenger-window-pixel\n    local-class=\"pixel\"\n    {{did-insert this.setupPixel}}\n  ></span>\n\n  {{#unless this.platform.isNative}}\n    <span\n      data-test-messenger-window-close\n      local-class=\"close\"\n      {{on \"click\" this.window.close}}\n    >\n      {{svg-jar \"close\"}}\n    </span>\n  {{/unless}}\n\n  <Messenger @navigate={{this.navigate}} />\n</section>","moduleName":"@clarksource/client/components/messenger-window.hbs","parseOptions":{"srcName":"@clarksource/client/components/messenger-window.hbs"}});
import type { PlatformService } from '@clark-shell/ember';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import Component from '@glimmer/component';

interface MessengerWindowSignature {
  Args: {};
}

export default class MessengerWindowComponent extends Component<MessengerWindowSignature> {
  @service('messenger/window') declare window: Services['messenger/window'];

  // eslint-disable-next-line ember/no-unused-services
  @service('shell/platform') declare platform: PlatformService;

  @action setupPixel(element: HTMLElement): void {
    this.window.pixel = element;
  }

  @action navigate(): void {
    if (this.window.isMobileWebBrowser) {
      this.window.close();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MessengerWindow: typeof MessengerWindowComponent;
    'messenger-window': typeof MessengerWindowComponent;
  }
}
