import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showProgressBar}}\n  <div local-class=\"container\">\n    <div local-class=\"progress-bar-container\">\n      <div\n        data-test-progress-bar\n        local-class=\"progress-bar\"\n        {{style width=(concat this.percentageDone \"%\")}}\n      >\n      </div>\n    </div>\n  </div>\n{{/if}}", {"contents":"{{#if this.showProgressBar}}\n  <div local-class=\"container\">\n    <div local-class=\"progress-bar-container\">\n      <div\n        data-test-progress-bar\n        local-class=\"progress-bar\"\n        {{style width=(concat this.percentageDone \"%\")}}\n      >\n      </div>\n    </div>\n  </div>\n{{/if}}","moduleName":"@clarksource/client/components/mandate/mandate-process-progress.hbs","parseOptions":{"srcName":"@clarksource/client/components/mandate/mandate-process-progress.hbs"}});
import { WizardStep } from '@clark-utils/enums-and-types';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import Component from '@glimmer/component';

interface MandateMandateProcessProgressSignature {
  Args: {};
}

export default class MandateMandateProcessProgressComponent extends Component<MandateMandateProcessProgressSignature> {
  @service declare mandateState: Services['mandate-state'];
  @service declare session: Services['session'];

  get percentageDone(): number {
    const { currentMandateStep, mandateStepsForProgress } = this.mandateState;
    // @ts-expect-error TS(2345) FIXME: Argument of type 'MandateStep | undefined' is not ... Remove this comment to see the full error message
    const stepIndex = mandateStepsForProgress.indexOf(currentMandateStep);

    return 100 * ((stepIndex + 1) / mandateStepsForProgress.length);
  }

  get showProgressBar(): boolean {
    const { currentMandateStep } = this.mandateState;
    const { currentMandate } = this.session;

    if (!currentMandateStep) {
      return false;
    }

    if (!currentMandate) {
      return true;
    }

    // If the user didn't complete their mandate, show the progress bar
    const isMandateIncomplete =
      this.mandateState.state(currentMandate) !== WizardStep.confirming;

    return isMandateIncomplete;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Mandate::MandateProcessProgress': typeof MandateMandateProcessProgressComponent;
    'mandate/mandate-process-progress': typeof MandateMandateProcessProgressComponent;
  }
}
