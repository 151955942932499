export const CONSENT_COOKIE_NAME = 'consent';

export interface ConsentCookieCategory {
  enabled: boolean;
  identifier: string;
}

export interface ConsentCookie {
  categories: ConsentCookieCategory[];
  version: number;
}

export function getCookieValue(
  cookie: string,
  name: string,
): string | undefined {
  const mappings = new Map<string, string | undefined>();

  cookie.split(';').forEach((line: string) => {
    const [key, value] = line.trim().split('=');
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. Remove this comment to see the full error message
    mappings.set(key, value);
  });

  return mappings.get(name);
}

export function getCookieValueAsJson(cookie: string, name: string) {
  try {
    const cookieValue = getCookieValue(cookie, name);
    return cookieValue && JSON.parse(cookieValue);
  } catch {
    // Do Nothing
  }
}
