import type { Mandate } from '@clark-utils/enums-and-types';
import { retryOnNetworkFailure } from '@clarksource/ember-api/utils';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';

export default class MandateMandateService extends Service {
  @service declare api: Services['api'];

  // update the mandate details (done on profiling step)
  update(mandate: Mandate) {
    return this.api.put(`mandates/${mandate.id}`, mandate);
  }

  // set the mandate signature
  sendSignature(mandate: Mandate, signature: any) {
    return retryOnNetworkFailure(() =>
      this.api.post(`mandates/${mandate.id}/signatures`, { signature }),
    );
  }

  // set the toc's etc on the mandate after sending the signature data
  confirmMandate(mandate: Mandate) {
    return retryOnNetworkFailure(() =>
      this.api.patch(`mandates/${mandate.id}/confirming`, {
        info: { ...mandate.info },
      }),
    );
  }

  // accept the terms of service on the mandate
  // @TODO I am aware I am using the custom store here and not the API service
  // however the API service does not allow /mandates of v2 and v4
  // secondly I cannot specify a more specific route as the part of the
  // route is dynamic. personally I would just like to pass the route and
  // API version and avoid all these issues like we did with custom store
  // much simpler and easier / faster to work with.
  acceptTerms(mandate: Mandate) {
    return retryOnNetworkFailure(() =>
      this.api.patch(`mandates/${mandate.id}/termsacceptance`, undefined, {
        version: 'v4',
      }),
    );
  }

  validateMam(mmAccountNumber: string) {
    return retryOnNetworkFailure(() => {
      return this.api.put(
        'partnerships/mam/customer',
        { mam_account_number: mmAccountNumber },
        {
          version: 'v5',
        },
      );
    });
  }

  acceptHealthConsent(mandate: Mandate) {
    return retryOnNetworkFailure(() =>
      this.api.patch(`mandates/${mandate.id}/health_consent`),
    );
  }

  revokeMandate() {
    return retryOnNetworkFailure(() =>
      this.api.patch('customer/current/profile/revoke'),
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'mandate/mandate': MandateMandateService;
  }
}
