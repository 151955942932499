import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-at-ember-render-modifiers }}\n<div\n  {{in-viewport\n    onEnter=this.didEnterViewport\n    onExit=this.didExitViewport\n    viewportUseIntersectionObserver=false\n    viewportEnabled=true\n    viewportUseRAF=true\n    viewportSpy=true\n    viewportScrollSensitivity=1\n    viewportRefreshRate=150\n    intersectionThreshold=0\n    viewportTolerance=(hash top=0 bottom=-70 left=0 right=0)\n  }}\n  {{will-destroy this.didExitViewport}}\n>\n  <div\n    local-class=\"mini-footer {{if this.sticked 'sticked'}}\"\n    data-test-footer-mini\n  >\n    <LegalLinks />\n  </div>\n</div>", {"contents":"{{! template-lint-disable no-at-ember-render-modifiers }}\n<div\n  {{in-viewport\n    onEnter=this.didEnterViewport\n    onExit=this.didExitViewport\n    viewportUseIntersectionObserver=false\n    viewportEnabled=true\n    viewportUseRAF=true\n    viewportSpy=true\n    viewportScrollSensitivity=1\n    viewportRefreshRate=150\n    intersectionThreshold=0\n    viewportTolerance=(hash top=0 bottom=-70 left=0 right=0)\n  }}\n  {{will-destroy this.didExitViewport}}\n>\n  <div\n    local-class=\"mini-footer {{if this.sticked 'sticked'}}\"\n    data-test-footer-mini\n  >\n    <LegalLinks />\n  </div>\n</div>","moduleName":"@clarksource/client/components/app-footer/mini-footer.hbs","parseOptions":{"srcName":"@clarksource/client/components/app-footer/mini-footer.hbs"}});
import { action, set } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface AppFooterMiniFooterSignature {
  Args: {};
}

export default class AppFooterMiniFooterComponent extends Component<AppFooterMiniFooterSignature> {
  readonly pageMain = document.querySelector<HTMLElement>('main.page-main');

  @tracked sticked = false;

  @action
  didEnterViewport() {
    set(this, 'sticked', true);

    this.pageMain?.classList.add('footer-sticky');
  }

  @action
  didExitViewport() {
    set(this, 'sticked', false);
    this.pageMain?.classList.remove('footer-sticky');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AppFooter::MiniFooter': typeof AppFooterMiniFooterComponent;
    'app-footer/mini-footer': typeof AppFooterMiniFooterComponent;
  }
}
