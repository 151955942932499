import type { PlatformService } from '@clark-shell/ember';
import type { User } from '@clark-utils/enums-and-types';
import { Partner } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { ADJUST_PAYBACK_SOURCE } from '@clarksource/client/services/mandate/payback';
import { action } from '@ember/object';
import type { RouteInfoWithAttributes } from '@ember/routing/route-info';
import type Transition from '@ember/routing/transition';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class MandateRoute extends ClarkRoute {
  @service declare config: Services['config'];
  @service declare localStorage: Services['local-storage'];
  @service declare router: Services['router'];
  @service declare session: Services['session'];
  @service declare tracking: Services['tracking'];
  @service declare authEventManager: Services['auth-event-manager'];

  @service('mandate/payback') declare payback: Services['mandate/payback'];
  @service('mandate/fde-onboarding')
  declare fdeOnboarding: Services['mandate/fde-onboarding'];
  @service('shell/platform') declare platform: PlatformService;

  async beforeModel(transition: Transition) {
    const { restorationToken } = transition.to?.queryParams as Record<
      string,
      unknown
    >;

    if (restorationToken) {
      this.trackMandateFunnelRestoration();
    }

    if (!this.session.currentUser) {
      this.authEventManager.didLogout();
      if (transition.isActive) {
        if (restorationToken) {
          this.router.replaceWith('login', {
            queryParams: { restorationToken },
          });

          return;
        }

        this.router.replaceWith('login');
      }
      return;
    }

    this.turnOnPushNotifications();

    if (this.platform.isMobile) {
      const { adjust }: User = this.session.currentUser;
      // @ts-expect-error TS(4111) FIXME: Property 'network' comes from an index signature, ... Remove this comment to see the full error message
      if (adjust?.network === ADJUST_PAYBACK_SOURCE) {
        const paybackCustomer = await this.payback.getUserPayback.perform();
        if (
          // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type 'Response'.
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          !paybackCustomer?.data?.attributes?.payback_enabled ||
          // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type 'Response'.
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          Object.keys(paybackCustomer?.data?.attributes?.payback_data)
            .length === 0
        ) {
          this.router.replaceWith('index.mandate.index.payback');
        }
        // @ts-expect-error TS(4111) FIXME: Property 'network' comes from an index signature, ... Remove this comment to see the full error message
      } else if (adjust?.network === Partner.milesAndMore) {
        const { mandate } = this.session.currentUser;

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (mandate?.loyalty?.mam?.mmAccountNumber) {
          return;
        }

        this.router.replaceWith('index.mandate.index.mam');
      }
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'parameters' implicitly has an 'any' typ... Remove this comment to see the full error message
  async model(parameters, transition: Transition) {
    // Partner and UTM Parameters are coming from query string and indicate
    // Customised funnel starts.
    // details are here -> https://clarkteam.atlassian.net/wiki/spaces/JCLARK/pages/1588691029/Insurance+Incentive+Specific+Mandate+Funnel

    const persistedCustomParameters = this.localStorage.getData(
      'incentive_specific_funnel',
      null,
    );

    if (!isEmpty(persistedCustomParameters)) {
      return persistedCustomParameters;
    }

    // Early return for other routes.
    if (transition.to?.localName !== 'custom-funnel') {
      return;
    }

    // To better handling of partners I passed transition.to.params into payload
    // which should be { partner: "SOME PARTNER NAME" }

    const { params, queryParams } = transition.to as RouteInfoWithAttributes;
    const payload = { ...params, ...queryParams };

    this.localStorage.setData('incentive_specific_funnel', payload);

    if (payload['partner'] === 'fde') {
      this.fdeOnboarding.profile = await this.fdeOnboarding.getProfile();
    }

    return payload;
  }

  trackMandateFunnelRestoration() {
    this.tracking.track('customer/onboarding/retargetting:redirect-user');
  }

  @action
  navigateTo(route: string) {
    this.router.transitionTo(route);
  }

  private turnOnPushNotifications() {
    if (!this.config.getConfig('pushNotifications')) {
      return;
    }

    if (this.localStorage.getData('pushEnabled')) {
      return;
    }

    this.localStorage.setData('pushEnabled', true);
  }
}
