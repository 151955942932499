import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (media \"isBelowMedium\")}}\n  <Shell::TitleBar::Renderer\n    {{root-property name=\"--title-bar-offset\" value=\"45px\"}}\n  >\n    <:more>\n      {{#if this.MoreComponent}}\n        <div data-test-title-bar-more local-class=\"more\">\n          <this.MoreComponent />\n        </div>\n      {{/if}}\n    </:more>\n  </Shell::TitleBar::Renderer>\n{{/if}}", {"contents":"{{#if (media \"isBelowMedium\")}}\n  <Shell::TitleBar::Renderer\n    {{root-property name=\"--title-bar-offset\" value=\"45px\"}}\n  >\n    <:more>\n      {{#if this.MoreComponent}}\n        <div data-test-title-bar-more local-class=\"more\">\n          <this.MoreComponent />\n        </div>\n      {{/if}}\n    </:more>\n  </Shell::TitleBar::Renderer>\n{{/if}}","moduleName":"@clarksource/client/components/title-bar.hbs","parseOptions":{"srcName":"@clarksource/client/components/title-bar.hbs"}});
import CustomerMoreOptions from '@clark-customer/ui/components/customer/more-options';
import RecommendationsOpenDemandcheckIntroMoreOptions from '@clark-recommendations/ui/components/recommendations/open-demandcheck-intro/more-options';
import MandateMandateMoreSheet from '@clarksource/client/components/mandate/mandate-more-sheet';
import { type Registry as Services, service } from '@ember/service';
import Component from '@glimmer/component';

const mapping = {
  'customer/more-options': CustomerMoreOptions,
  'mandate/mandate-more-sheet': MandateMandateMoreSheet,
  'recommendations/open-demandcheck-intro/more-options':
    RecommendationsOpenDemandcheckIntroMoreOptions,
} as const;

interface TitleBarSignature {
  Args: {};
}

export default class TitleBarComponent extends Component<TitleBarSignature> {
  @service declare titleBar: Services['title-bar'];

  get MoreComponent() {
    const key = this.titleBar.currentState?.more;

    if (key === undefined) {
      return undefined;
    }

    return mapping[key];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    TitleBar: typeof TitleBarComponent;
    'title-bar': typeof TitleBarComponent;
  }
}
