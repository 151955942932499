import type { PlatformService } from '@clark-shell/ember';
import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class ClarkAppHelper extends Helper {
  @service('shell/platform') declare platform: PlatformService;

  compute() {
    return this.platform.isNative;
  }
}
