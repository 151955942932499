import Service from '@ember/service';

export default class GlobalService extends Service {
  scope = window;

  /**
   * Proxies the `document.cookie` property
   */
  get cookie(): string {
    return this.scope.document.cookie;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    global: GlobalService;
  }
}
