import type { PlatformService } from '@clark-shell/ember';
import Route from '@ember/routing/route';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class SignupRoute extends Route {
  @service declare customer: Services['customer'];
  @service declare mandateState: Services['mandate-state'];
  @service declare router: Services['router'];
  @service declare session: Services['session'];
  @service('shell/platform') declare platform: PlatformService;

  async beforeModel(): Promise<void> {
    await this.waitForSession();
  }

  async afterModel(): Promise<void> {
    // handle nonymous users on non native app
    if (!this.session.currentUser && !this.platform.isNative) {
      await this.customer.createAnonymousLead();
      const nextStep = this.mandateState.nextStepRoute();
      this.router.transitionTo(nextStep);
      return;
    }

    // handle anonymous users on native platforms
    if (this.platform.isNative && !this.session.isAuthenticated) {
      this.router.transitionTo('clark-tour');
    }

    this.router.transitionTo('index.mandate.index');
  }

  private async waitForSession(): Promise<void> {
    if (this.session.load.isRunning) {
      await this.session.load.last;
    } else if (!this.session.isAuthenticated && !this.session.load.last) {
      await this.session.load.perform();
    }
  }
}
