import { UNAUTHORIZED } from '@clarksource/ember-api/status-codes';
import Route from '@ember/routing/route';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import * as Sentry from '@sentry/browser';
import { task } from 'ember-concurrency';

export default class Invitations extends Route {
  @service declare localStorage: Services['local-storage'];
  @service declare router: Services['router'];
  @service declare user: Services['user'];
  @service declare api: Services['api'];
  @service declare invitationDataHandler: Services['invitation-data-handler'];

  @service declare redirectOnCompletion: Services['redirect-on-completion'];

  @service declare sectionsHandler: Services['sections-handler'];

  activate() {
    // @ts-expect-error: Expected 1 arguments, but got 0
    super.activate();

    if (!this.sectionsHandler.hasSectionAccess(this.routeName)) {
      this.router.replaceWith('index.manager');
    }
  }

  async model() {
    try {
      const user = await this.user.getUser();
      const invitees = await this.invitationDataHandler.getCustomerInvitees();
      if (user.lead) {
        // where we want to come back to after registering on the finished page
        this.redirectOnCompletion.setRedirectUrl(
          'index.invitations',
          'redirect-after-action',
        );

        // now we send the user to the mandate version of the register page as it is all in ember, so we can do
        // session storage checks on redirect and so on. Also is faster
        this.router.replaceWith('index.mandate.index.register');
      } else {
        // Temporary Merging rating modal information with user object at initial time.
        const info = this.localStorage.getAttr('mandate', 'info', {}) || {};
        Object.assign(user.mandate.info, info);

        return { user, invitees };
      }
    } catch (error) {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      if (error && error.status !== UNAUTHORIZED) {
        Sentry.withScope((scope) => {
          scope.setExtras({
            message: 'Could not get user form API - Invitation page',
            component: 'invitations',
          });

          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          Sentry.captureException(error.error);
        });
      }

      this.router.replaceWith('index.mandate.index.register');
    }
  }

  fetchCustomerInvites = task(async () => {
    try {
      const response = (await this.api.get('customer/current/invitees', {
        version: 'v5',
      })) as {
        data: [];
      };
      return response.data;
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          message: `Could not fetch the customer invitees`,
          component: 'Invite Friend',
        });

        Sentry.captureException(e);
      });
    }
  });
}
