import type { AllPlugins, OptionalPlugins } from '@clark-shell/ember';
import { plugin } from '@clark-shell/ember';
import type { TrackingAuthorizationStatus } from '@clark-shell/plugin.adjust';
import Controller from '@ember/controller';
import { type Registry as Services, service } from '@ember/service';
import * as Sentry from '@sentry/browser';
import { dropTask } from 'ember-concurrency';

type AttributionData = {
  adjustAttribution: any;
  adjustIdentifiers: any;
  installationId: string;
};

export default class ConsentPrimerController extends Controller {
  @service declare api: Services['api'];
  @service declare router: Services['router'];
  @service declare session: Services['session'];

  @plugin({ optional: true })
  private declare adjust: OptionalPlugins['Adjust'];

  @plugin private declare auth: AllPlugins['Auth'];

  private async getCurrentConsent(): Promise<
    TrackingAuthorizationStatus | undefined
  > {
    if (!this.adjust?.getTrackingAuthorizationStatus) {
      return 'not-determined';
    }

    const status = (await this.adjust.getTrackingAuthorizationStatus().then(
      (data) => data.status,
      () => 'not-determined',
    )) as TrackingAuthorizationStatus | undefined;

    return status ?? 'not-determined';
  }

  async fetchAttributionData(): Promise<AttributionData> {
    const [{ installationId }, adjustIdentifiers, adjustAttribution] =
      await Promise.all([
        this.auth.getInstallationId(),
        this.adjust?.getIdentifier(),
        this.adjust?.getAttribution(),
      ]);

    return {
      installationId,
      adjustIdentifiers,
      adjustAttribution,
    };
  }

  updateAdjustAttribution({
    attributionData,
    consent,
  }: {
    attributionData: AttributionData;
    consent: TrackingAuthorizationStatus | undefined;
  }): Promise<void> {
    /**
     * If the user did not consent to tracking (consent === 'denied'),
     * we don't need to update the attribution, since adjust was already enabled in online mode
     * with whatever attribution data we could get via the cookie banner's marketing consent.
     * See client/app/components/cookie-banner.ts
     */

    if (!attributionData.adjustAttribution) {
      return Promise.resolve();
    }

    /**
     * If user consented to the ATT consent prompt, it means we have the idfa available now
     * and we need to update the adjust attribution with the new idfa
     */
    if (consent === 'authorized') {
      return this.sendAdjustAttributionRequest(
        attributionData.adjustAttribution,
        attributionData.installationId,
      );
    }

    /**
     * If user denied the ATT consent prompt, it means we won't have the idfa but the adjust attribution
     * that was done when the user accepted marketing cookie can remain, and adjust can remain enabled in online mode.
     */

    return Promise.resolve();
  }

  private sendAdjustAttributionRequest(
    attribution: any,
    installationId: string,
  ): Promise<void> {
    return this.api.patch('bi/tracking/adjust-attribution', {
      installation_id: installationId,
      ...attribution,
    });
  }

  async register(attributionData: AttributionData): Promise<void> {
    if (this.session.isAuthenticated) {
      /**
       * If the user is already authenticated, we should not register them again.
       */
      return;
    }

    const { installationId, adjustIdentifiers, adjustAttribution } =
      attributionData;

    const request: {
      adjust?: Exclude<typeof adjustAttribution, undefined>;
      gps_adid?: string;
      idfa?: string;
      installation_id: string;
    } = { installation_id: installationId };

    if (adjustIdentifiers?.idfa) {
      request.idfa = adjustIdentifiers.idfa;
    }
    if (adjustIdentifiers?.gpsAdId) {
      request.gps_adid = adjustIdentifiers.gpsAdId;
    }
    if (adjustAttribution) {
      request.adjust = adjustAttribution;
    }

    await this.api.post('app/anonauth', request);

    // Trigger event to sync cookies with the backend
    this.session.triggerMandateCreated();
  }

  getConsentAndRegisterUserTask = dropTask(async () => {
    try {
      const currentConsent = await this.getCurrentConsent();

      if (currentConsent !== 'not-determined') {
        const attributionData = await this.fetchAttributionData();
        await this.register(attributionData);
        this.router.transitionTo('index.mandate');
        return;
      }

      const trackingAuthStatus =
        await this.adjust?.requestTrackingAuthorization?.();

      // Once user consents or declines ATT, we can load adjust and enable it
      await this.adjust?.setEnabled({ tracking: true, online: true });

      const consent = trackingAuthStatus?.status ?? 'not-determined';

      const attributionData = await this.fetchAttributionData();

      await this.updateAdjustAttribution({ attributionData, consent });

      await this.register(attributionData);

      this.router.transitionTo('index.mandate');
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          message: `Could not submit consent`,
          component: 'Customer::ConsentPrimerScreen',
        });
        Sentry.captureException(error);
      });
    }
  });
}
