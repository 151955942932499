import Evented from '@ember/object/evented';
import Service from '@ember/service';

export default class UserEventManagerService extends Service.extend(Evented) {
  didRegister() {
    // @ts-expect-error: Property 'trigger' does not exist on type 'UserEventManagerService'
    this.trigger('didRegister');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'user-event-manager': UserEventManagerService;
  }
}
