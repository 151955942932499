import type { PlatformService } from '@clark-shell/ember';
import { MandateStep } from '@clarksource/client/services/mandate-state';
import { retryOnNetworkFailure } from '@clarksource/ember-api/utils';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class IndexMandateIndexExitPollController extends Controller {
  @service declare mandateState: Services['mandate-state'];
  @service declare router: Services['router'];
  @service declare api: Services['api'];

  @service('shell/platform') declare platform: PlatformService;

  @action redirectUser(): void {
    this.mandateState.completeMandateStep(MandateStep.ExitPoll);

    /**
     * FIXME:: Following logic should be taken out into the mandatestate
     * service later.
     */
    // @ts-expect-error: Object is of type 'unknown'
    if (this.model.user.lead) {
      this.router.transitionTo('index.mandate.index.register');
      return;
    }

    if (this.platform.isNative) {
      this.router.transitionTo('index.messenger');
      return;
    }

    this.router.transitionTo('index.mandate.index.finished');
  }

  @action submitAnswer(answer: string) {
    retryOnNetworkFailure(() =>
      this.api.post(`customer/surveys/exit_poll`, {
        exit_poll: { answer },
      }),
    );
  }
}
