/* eslint-disable default-case, no-useless-return */
import type { Mandate, User, WizardStep } from '@clark-utils/enums-and-types';
import { Partner } from '@clark-utils/enums-and-types';
import { retryOnNetworkFailure } from '@clarksource/ember-api/utils';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';

const partnershipsWithIbanStep = new Set([
  Partner.bunq,
  Partner.dkb,
  Partner.finanzblick,
  Partner.primoco,
]);

const partnershipsExcludedForOpenJourneys = new Set([
  Partner.milesAndMore,
  Partner.payback,
  Partner.bunq,
  Partner.dkb,
  Partner.home24,
]);

const networksExcludedForOpenJourneys = new Set([
  'avow',
  'lidlplus',
  'lidl',
  'mystipendium',
  'mystipendiumbedarfscheck',
  'sovendus_checkout',
  'sovendus_sale',
  'sovendus',
  'sparwelt',
]);

type MandateStepRouteMapping = {
  [key in MandateStep]: string;
};

export enum MandateStep {
  CockpitPreview = 'cockpit-preview',
  Confirming = 'confirming',
  CustomFunnel = 'custom-funnel',
  ExitPoll = 'exit-poll',
  Finished = 'finished',
  IBAN = 'iban',
  PhoneVerification = 'phone-verification',
  Profiling = 'profiling',
  Registration = 'registration',
  SignUp = 'sign-up',
  Status = 'status',
  Targeting = 'targeting',
  VoucherJourney = 'voucher-journey',
}

const mandateStepRouteMappings: MandateStepRouteMapping = {
  [MandateStep.CockpitPreview]: 'index.mandate.index.cockpit-preview',
  [MandateStep.Confirming]: 'index.mandate.index.confirming',
  [MandateStep.CustomFunnel]: 'index.mandate.index.custom-funnel',
  [MandateStep.ExitPoll]: 'index.mandate.index.exit-poll',
  [MandateStep.Finished]: 'index.mandate.index.finished',
  [MandateStep.IBAN]: 'index.mandate.index.iban',
  [MandateStep.PhoneVerification]: 'index.mandate.index.phone-verification',
  [MandateStep.Profiling]: 'index.mandate.index.profiling',
  [MandateStep.Registration]: 'index.mandate.index.register',
  [MandateStep.Status]: 'index.mandate.index.status',
  [MandateStep.Targeting]: 'index.mandate.index.targeting',
  [MandateStep.SignUp]: 'index.mandate.index.sign-up',
  [MandateStep.VoucherJourney]: 'index.mandate.index.voucher-journey',
};

export default class MandateStateService extends Service {
  @service declare api: Services['api'];
  @service declare localStorage: Services['local-storage'];
  @service declare router: Services['router'];
  @service declare session: Services['session'];
  @service declare experiments: Services['experiments'];
  @service declare features: Services['features'];

  get isVoucherIntention(): boolean {
    const { currentMandate } = this.session;
    return (
      currentMandate?.customer_intent === 'mandate-funnel-get-voucher' ||
      this.voucherPromoCode ||
      this.isCodelessCampaignUser
    );
  }

  get isCodelessCampaignUser(): boolean {
    const { currentMandate } = this.session;

    if (!currentMandate?.codeless_campaign) {
      return false;
    }

    return this.isPartOfIncentiveInfoMandateFunnelExperiment;
  }

  get isPartOfIncentiveInfoMandateFunnelExperiment(): boolean {
    if (!this.features.isEnabled('INCENTIVE_INFO_MANDATE_FUNNEL')) {
      return false;
    }
    return (
      this.experiments.getVariant('2024Q3IncentiveInfoMandateFunnel') === 'v1'
    );
  }

  get voucherPromoCode(): boolean {
    const promoCode = this.localStorage.getData('voucher-promo-code');
    return promoCode ? true : false;
  }

  get currentMandateStep(): MandateStep | undefined {
    const { currentRouteName } = this.router;

    if (!currentRouteName) {
      return;
    }

    if (currentRouteName.startsWith('index.mandate.index.confirming')) {
      return MandateStep.Confirming;
    }

    if (currentRouteName.startsWith('index.mandate.index.iban')) {
      return MandateStep.IBAN;
    }

    if (currentRouteName.startsWith('index.mandate.index.phone-verification')) {
      return MandateStep.PhoneVerification;
    }

    if (currentRouteName.startsWith('index.mandate.index.profiling')) {
      return MandateStep.Profiling;
    }

    if (currentRouteName.startsWith('index.mandate.index.register')) {
      return MandateStep.Registration;
    }

    if (currentRouteName.startsWith('index.mandate.index.targeting')) {
      return MandateStep.Targeting;
    }

    if (currentRouteName.startsWith('index.mandate.index.voucher-journey')) {
      return MandateStep.VoucherJourney;
    }

    return;
  }

  sortStepsInCurrentFunnelOrder(steps: MandateStep[]): MandateStep[] {
    /**
     * Sorting to reorder the steps to the same order that the
     * funnelSteps for this user is in.
     */
    return steps.sort((a, b) => {
      return this.funnelSteps.indexOf(a) - this.funnelSteps.indexOf(b);
    });
  }

  get mandateStepsForProgress(): MandateStep[] {
    const user = this.session.currentUser;

    // Steps common to the mandate funnel
    const progressSteps = [
      MandateStep.PhoneVerification,
      MandateStep.Targeting,
      MandateStep.Profiling,
      MandateStep.Confirming,
    ];

    if (this.isVoucherIntention) {
      progressSteps.push(MandateStep.VoucherJourney);
    }

    if (!user) {
      return this.sortStepsInCurrentFunnelOrder(progressSteps);
    }

    if (this.hasExtraIbanStep(user.mandate)) {
      progressSteps.push(MandateStep.IBAN);
    }

    if (user.lead) {
      progressSteps.push(MandateStep.Registration);
    }

    return this.sortStepsInCurrentFunnelOrder(progressSteps);
  }

  isStepPartOfFunnel(step: MandateStep): boolean {
    return this.funnelSteps.includes(step);
  }

  /**
   * Caution:
   * Before you override an existing funnel sequence with a new one, make sure
   * that you review the experiments triggered in that section. You may have to
   * disable experiments beforehand in addition to overriding an existing sequence.
   */
  get funnelSteps(): MandateStep[] {
    const user = this.session.currentUser;

    const partnerFunnel = user && user.mandate.partner;

    const isPartnerFunnelWithIban =
      partnerFunnel && this.hasExtraIbanStep(user.mandate);

    // Default states for partnership funnels with IBAN step
    if (isPartnerFunnelWithIban) {
      return [
        MandateStep.Status,
        MandateStep.Targeting,
        MandateStep.PhoneVerification,
        MandateStep.Profiling,
        MandateStep.Confirming,
        MandateStep.IBAN,
        MandateStep.ExitPoll,
        MandateStep.Registration,
        MandateStep.Finished,
      ];
    }

    if (partnerFunnel === 'fde') {
      // The FDE Onboarding journey is at the moment bind to the funnel steps. It should change in the future
      return [
        MandateStep.Status,
        MandateStep.Targeting,
        MandateStep.PhoneVerification,
        MandateStep.Profiling,
        MandateStep.Confirming,
        MandateStep.ExitPoll,
        MandateStep.Registration,
        MandateStep.Finished,
      ];
    }

    if (this.isVoucherIntention) {
      return [
        MandateStep.SignUp,
        MandateStep.VoucherJourney,
        MandateStep.Targeting,
        MandateStep.PhoneVerification,
        MandateStep.Profiling,
        MandateStep.Confirming,
        MandateStep.ExitPoll,
        MandateStep.Registration,
        MandateStep.Finished,
      ];
    }

    // The default funnel sequence for clark 1 mandate funnel
    return [
      MandateStep.SignUp,
      MandateStep.Status,
      MandateStep.Targeting,
      MandateStep.PhoneVerification,
      MandateStep.Profiling,
      MandateStep.Confirming,
      MandateStep.ExitPoll,
      MandateStep.Registration,
      MandateStep.Finished,
    ];
  }

  get isNetworkOrPartnerExcludedForOpenJourneys(): boolean {
    const user = this.session.currentUser;

    if (!user) {
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const { partner, info } = user.mandate || {};

    // N26 user
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (info && info.freyr) {
      return true;
    }

    // Restricted Partner
    if (
      partner &&
      // @ts-expect-error TS(2345) FIXME: Argument of type 'Partner' is not assignable to p... Remove this comment to see the full error message
      partnershipsExcludedForOpenJourneys.has(partner as Partner)
    ) {
      return true;
    }

    // restricted network
    if (
      // @ts-expect-error TS(4111) FIXME: Property 'network' comes from an index signature, ... Remove this comment to see the full error message
      user.adjust?.network &&
      // @ts-expect-error TS(4111) FIXME: Property 'network' comes from an index signature, ... Remove this comment to see the full error message
      networksExcludedForOpenJourneys.has(user.adjust.network as string)
    ) {
      return true;
    }

    return false;
  }

  isStepCompleteInLocalStorage(stepName: MandateStep): boolean {
    const completedSteps: MandateStep[] = this.localStorage.getData(
      'completed-mandate-steps',
      [],
    );

    return completedSteps.includes(stepName);
  }

  isStepCompleteInLegacy(stepName: MandateStep, user: User): boolean {
    const completedWizardSteps: MandateStep[] = user.mandate.info
      .wizard_steps as unknown as MandateStep[];

    if (completedWizardSteps.includes(stepName)) {
      return true;
    }

    const lastCompletedStep =
      completedWizardSteps[completedWizardSteps.length - 1];

    // @ts-expect-error TS(2345) FIXME: Argument of type 'MandateStep | undefined' is not ... Remove this comment to see the full error message
    const lastStepIndex = this.funnelSteps.indexOf(lastCompletedStep);
    const stepIndex = this.funnelSteps.indexOf(stepName);

    return stepIndex >= 0 && stepIndex <= lastStepIndex;
  }

  isStepComplete(stepName: MandateStep, user: User): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!user || !user.mandate) {
      return false;
    }

    switch (stepName) {
      case MandateStep.CockpitPreview:
      case MandateStep.Confirming:
      case MandateStep.CustomFunnel:
      case MandateStep.ExitPoll:
      case MandateStep.Finished:
      case MandateStep.SignUp:
      case MandateStep.Status:
      case MandateStep.VoucherJourney:
        return (
          this.isStepCompleteInLocalStorage(stepName) ||
          this.isStepCompleteInLegacy(stepName, user)
        );

      case MandateStep.Targeting:
      case MandateStep.Profiling:
        return this.isStepCompleteInLegacy(stepName, user); // To support legacy flow - TODO recheck

      case MandateStep.PhoneVerification:
        return user.mandate.primary_phone_verified;

      case MandateStep.IBAN:
        return user.mandate.secure_iban !== null;

      case MandateStep.Registration:
        return !user.lead;
      default:
        return false;
    }
  }

  /**
   * Method to navigate to missed funnel step if any
   */
  gotoMissingStep(user: User, currentStep: MandateStep): boolean {
    if (user.mandate.partner === Partner.malburg) {
      return false;
    }

    const currentStepIndex = this.funnelSteps.indexOf(currentStep);

    let missingStep = null;

    for (let i = 0; i < currentStepIndex; i++) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'MandateStep | undefined' is not ... Remove this comment to see the full error message
      if (!this.isStepComplete(this.funnelSteps[i], user)) {
        missingStep = this.funnelSteps[i];
        break;
      }
    }

    if (missingStep) {
      this.router.transitionTo(mandateStepRouteMappings[missingStep]);
      return true;
    }

    return false;
  }

  hasExtraIbanStep(mandate: Mandate): boolean {
    if (mandate.requires_iban) {
      return true;
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'Partner' is not assignable to p... Remove this comment to see the full error message
    return partnershipsWithIbanStep.has(mandate.partner as Partner);
  }

  /**
   * FIXME:: This function shouldn't be needed as it's only consumer expects a
   * boolean and it could be replaced with nextStepRoute
   * @param user
   */
  nextMandateStep(user: User): string | undefined {
    const { lead: isLead, mandate } = user;
    const step = this.state(mandate);

    switch (step) {
      case '': {
        if (mandate.primary_phone_verified) {
          return 'targeting';
        }

        return 'phone-verification';
      }

      case 'targeting': {
        if (isLead === false) {
          return 'rejected';
        }

        return 'profiling';
      }

      case 'profiling': {
        return 'confirming';
      }

      case 'confirming': {
        if (!isLead) {
          break;
        }

        return 'registration';
      }
    }

    return undefined;
  }

  previousStepRoute(currentStep: MandateStep): string {
    const currentStateIndex = this.funnelSteps.indexOf(currentStep);
    const previousState =
      this.funnelSteps[currentStateIndex - 1] ?? this.funnelSteps[0];

    // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
    return mandateStepRouteMappings[previousState];
  }

  nextStepRoute(user?: User): string {
    const step = this.nextStep(user);
    return mandateStepRouteMappings[step];
  }

  completeAndNavigateToNextStep(currentStep: MandateStep, user?: User): void {
    this.completeMandateStep(currentStep);
    const route = this.nextStepRoute(user);
    this.router.transitionTo(route);
  }

  nextStep(user?: User): MandateStep {
    /**
     * because in some cases updates to user objects are not immediately present
     * in the session's user object due to a deep clone done in the index route.
     * So this method expects that the caller send along the user object if it
     * is updated internally
     */
    if (!user) {
      user = this.session.currentUser;
    }
    let stepToCompleteNext = null;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!user || !user.mandate) {
      // @ts-expect-error TS(2322) FIXME: Type 'MandateStep | undefined' is not assignable t... Remove this comment to see the full error message
      return this.funnelSteps[0];
    }

    for (const step of this.funnelSteps) {
      if (!this.isStepComplete(step, user)) {
        stepToCompleteNext = step;
        break;
      }
    }

    // Just return the last step
    // @ts-expect-error TS(2322) FIXME: Type 'MandateStep | undefined' is not assignable t... Remove this comment to see the full error message
    return stepToCompleteNext ?? this.funnelSteps[this.funnelSteps.length - 1];
  }

  completeMandateStep(stepName: MandateStep): void {
    if (!this.funnelSteps.includes(stepName)) {
      return;
    }

    const completedSteps = this.localStorage.getData(
      'completed-mandate-steps',
      [],
    );

    if (!completedSteps.includes(stepName)) {
      completedSteps.push(stepName);
      this.localStorage.setData('completed-mandate-steps', completedSteps);
    }
  }

  removeCompletedStep(stepName: MandateStep): void {
    let steps: MandateStep[] = this.localStorage.getData(
      'completed-mandate-steps',
      [],
    );

    steps = steps.filter((step) => {
      return step !== stepName;
    });

    this.localStorage.setData('completed-mandate-steps', steps);
  }

  progress(mandate: Mandate, step: WizardStep): Promise<void> {
    if (!mandate.info.wizard_steps.includes(step)) {
      mandate.info.wizard_steps.push(step);
    }

    return retryOnNetworkFailure(() =>
      this.api.patch(`mandates/${mandate.id}/${step}`),
    );
  }

  removeState(mandate: Mandate, step: WizardStep): void {
    const steps = mandate.info.wizard_steps;
    const index = steps.indexOf(step);

    if (index > -1) {
      steps.splice(index, 1);
    }
  }

  state(mandate: Mandate): string {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!mandate.info) {
      return '';
    }

    const steps = mandate.info.wizard_steps;
    const lastStep = steps[steps.length - 1];

    return lastStep ?? '';
  }
}

declare module '@ember/service' {
  interface Registry {
    'mandate-state': MandateStateService;
  }
}
