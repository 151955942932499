import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export enum QuestionnaireModalType {
  offer = 'offer',
  oneDayOffer = 'oneDayOffer',
  threeDayOffer = 'threeDayOffer',
}

export default class QuestionnaireModalService extends Service {
  @tracked
  offerModalVisible = false;

  @tracked
  oneDayOfferModalVisible = false;

  @tracked
  threeDayOfferModalVisible = false;

  showModalFor(modalType: QuestionnaireModalType) {
    switch (modalType) {
      case 'offer':
        this.showOfferModal();
        break;
      case 'oneDayOffer':
        this.showOneDayOfferModal();
        break;
      case 'threeDayOffer':
        this.showThreeDayOfferModal();
        break;
      default:
    }
  }

  hideModelFor(modalType: QuestionnaireModalType) {
    switch (modalType) {
      case 'offer':
        this.hideOfferModal();
        break;
      case 'oneDayOffer':
        this.hideOneDayOfferModal();
        break;
      case 'threeDayOffer':
        this.hideThreeDayOfferModal();
        break;
      default:
    }
  }

  closeOpenModal() {
    if (this.oneDayOfferModalVisible) {
      this.hideOneDayOfferModal();
    }
    if (this.offerModalVisible) {
      this.hideOfferModal();
    }
    if (this.threeDayOfferModalVisible) {
      this.hideThreeDayOfferModal();
    }
  }

  // functions to toggle the modals
  showOfferModal() {
    if (this.aModelIsVisible()) {
      return;
    }
    this.offerModalVisible = true;
  }

  showOneDayOfferModal() {
    if (this.aModelIsVisible()) {
      return;
    }
    this.oneDayOfferModalVisible = true;
  }

  showThreeDayOfferModal() {
    if (this.aModelIsVisible()) {
      return;
    }
    this.threeDayOfferModalVisible = true;
  }

  hideOfferModal() {
    if (!this.aModelIsVisible()) {
      return;
    }
    this.offerModalVisible = false;
  }

  hideOneDayOfferModal() {
    if (!this.aModelIsVisible()) {
      return;
    }
    this.oneDayOfferModalVisible = false;
  }

  hideThreeDayOfferModal() {
    if (!this.aModelIsVisible()) {
      return;
    }
    this.threeDayOfferModalVisible = false;
  }

  aModelIsVisible() {
    return (
      this.oneDayOfferModalVisible ||
      this.threeDayOfferModalVisible ||
      this.offerModalVisible
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'questionnaire/modal': QuestionnaireModalService;
  }
}
