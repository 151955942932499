import ClarkRoute from '@clarksource/client/routes/-clark-route';
import type Transition from '@ember/routing/transition';
import { type Registry as Services, service } from '@ember/service';

export default class IndexManagerInquiryCategoryRoute extends ClarkRoute {
  @service declare router: Services['router'];

  beforeModel(transition: Transition) {
    const category = (transition.to?.params as Record<string, unknown>)[
      'category_id'
    ] as string;
    const inquiry = (transition.to?.params as Record<string, unknown>)[
      'id'
    ] as string;

    this.router.transitionTo('index.manager.inquiry.index', inquiry, {
      queryParams: { category },
    });
  }
}
