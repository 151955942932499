import type { PlatformService } from '@clark-shell/ember';
import type { User } from '@clark-utils/enums-and-types';
import Emberizer from '@clarksource/client/lifters/helpers/emberizer';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { retryOnNetworkFailure } from '@clarksource/ember-api/utils';
import type Transition from '@ember/routing/transition';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import cloneDeep from 'lodash/cloneDeep';

// this needs refactoring but the message dispatcher and all of its dep services would
// also need refactoring so leaving this in the old standard for now
export default class IndexRoute extends ClarkRoute {
  @service declare api: Services['api'];
  @service declare authRedirect: Services['auth-redirect'];
  @service declare experiments: Services['experiments'];
  @service declare localStorage: Services['local-storage'];
  @service declare messenger: Services['messenger'];
  @service declare router: Services['router'];
  @service declare session: Services['session'];
  @service declare tracking: Services['tracking'];

  @service declare redirectOnCompletion: Services['redirect-on-completion'];

  @service('shell/platform') declare platform: PlatformService;

  async beforeModel(transition: Transition) {
    super.beforeModel(transition);

    const { restoration_token, rate_clark } = transition.to
      ?.queryParams as Record<string, unknown>;

    if (restoration_token) {
      await retryOnNetworkFailure(() =>
        this.api.post('current_user/restore_authorization', {
          restoration_token,
        }),
      );
    }

    if (rate_clark) {
      this.tracking.track('after_successful_sale');
    }
  }

  async ensureAuthenticated() {
    // Ensures the user is authenticated or attempts to check authentication
    if (
      !this.session.isAuthenticated &&
      !this.session.checkAuthentication.isRunning
    ) {
      await this.session.checkAuthentication.perform();
    }

    if (this.session.load.isRunning) {
      await this.session.load.last;
    }
  }

  async model(_: any, transition: Transition) {
    // Adding it here to make sure authentication is checked.
    // added as a fix for Android app login issue
    await this.ensureAuthenticated();

    await this.authRedirect.protectRoute(transition);

    if (this.session.currentUser) {
      return Emberizer.emberize(cloneDeep(this.session.currentUser));
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
  async afterModel(user) {
    // return if we are in clark2 experiment
    if (this.experiments.getVariant('business-strategy') === 'clark2') {
      return;
    }

    if (user) {
      this.ratingModalShownFrequencyMigration(user);
    }
  }

  redirect(model: User) {
    // return if we are in clark2 experiment
    if (this.experiments.getVariant('business-strategy') === 'clark2') {
      return;
    }

    // do the 'one time in this route checks'
    if (this.redirectOnCompletion.hasRedirect('redirect-after-action')) {
      this.redirectOnCompletion.redirect('redirect-after-action');
    } else if (this.hasImportantMessages(model) && this.platform.isNative) {
      this.router.replaceWith('index.messenger');
    }
  }

  hasImportantMessages(user: User) {
    // this
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!user) {
      return false;
    }

    // For https://clarkteam.atlassian.net/browse/JCLARK-64542
    // here comes the super hacky hack:

    // we will ask the messenger first if there are any unread messages.
    // At the time `redirect()` is called - especially _AFTER_ the mandate
    // funnel - the `model` was not updated and contains an old count.
    if (this.messenger.numUnreadMessages === 0) {
      return false;
    }

    // ideally, the `model()` was `refresh()`ed after the mandate funnel.
    // This was done and tried, but had no effect, which ended up in the guard
    // check above. If we still do have unread messages, we can continue and see
    // if these are the "important" ones. By important, that means messages
    // created by robo-advisor (that is a property not being hold on messenger
    // service - and to that time not clear if it ever should. Anyway here would
    // be a link to a technical risk ticket instead).

    const importantMessageCount = user.unread_important_messages;
    const isNumber = typeof importantMessageCount === 'number';
    return isNumber && importantMessageCount !== 0;
  }

  ratingModalShownFrequencyMigration(user: User) {
    const localStorageMandateInfo = this.localStorage.getAttr(
      'mandate',
      'info',
    );
    const userMandateInfo = user.mandate.info;
    if (
      localStorageMandateInfo &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      userMandateInfo &&
      (localStorageMandateInfo.click_close_x_rate_us_modal_frequency ||
        // @ts-expect-error TS(2339) FIXME: Property 'click_close_x_rate_us_modal_frequency' d... Remove this comment to see the full error message
        userMandateInfo.click_close_x_rate_us_modal_frequency)
    ) {
      localStorageMandateInfo.rating_modal_shown_frequency =
        localStorageMandateInfo.click_close_x_rate_us_modal_frequency;
      localStorageMandateInfo.rating_modal_shown_timestamp =
        localStorageMandateInfo.click_close_x_rate_us_modal_timestamp;
      delete localStorageMandateInfo.click_close_x_rate_us_modal_frequency;
      delete localStorageMandateInfo.click_close_x_rate_us_modal_timestamp;
      delete localStorageMandateInfo.click_close_x_rate_us_modal;
      this.localStorage.setAttr('mandate', 'info', localStorageMandateInfo);

      // @ts-expect-error TS(2339) FIXME: Property 'rating_modal_shown_frequency' does not e... Remove this comment to see the full error message
      userMandateInfo.rating_modal_shown_frequency =
        // @ts-expect-error TS(2339) FIXME: Property 'click_close_x_rate_us_modal_frequency' d... Remove this comment to see the full error message
        userMandateInfo.click_close_x_rate_us_modal_frequency;
      // @ts-expect-error TS(2339) FIXME: Property 'rating_modal_shown_timestamp' does not e... Remove this comment to see the full error message
      userMandateInfo.rating_modal_shown_timestamp =
        // @ts-expect-error TS(2339) FIXME: Property 'click_close_x_rate_us_modal_timestamp' d... Remove this comment to see the full error message
        userMandateInfo.click_close_x_rate_us_modal_timestamp;
      // @ts-expect-error TS(2339) FIXME: Property 'click_close_x_rate_us_modal_frequency' d... Remove this comment to see the full error message
      delete userMandateInfo.click_close_x_rate_us_modal_frequency;
      // @ts-expect-error TS(2339) FIXME: Property 'click_close_x_rate_us_modal_timestamp' d... Remove this comment to see the full error message
      delete userMandateInfo.click_close_x_rate_us_modal_timestamp;
    }
  }
}
