import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-at-ember-render-modifiers }}\n<Shell::TitleBar @title={{this.title}} @back={{this.goBack}} />\n<div\n  local-class=\"article-screen\"\n  ...attributes\n  data-test-article-screen\n  {{did-insert this.startTimer}}\n  {{will-destroy this.trackCloseEvent}}\n>\n  <Iframe local-class=\"iframe override\" @src={{@articleUrl}} />\n\n  <div local-class=\"button-container\">\n    <Ui::Button\n      data-test-article-screen-cta\n      local-class=\"cta override\"\n      @appearance=\"primary\"\n      @type=\"submit\"\n      @onClick={{this.onClickCta}}\n    >\n      {{@ctaText}}\n    </Ui::Button>\n  </div>\n\n</div>", {"contents":"{{! template-lint-disable no-at-ember-render-modifiers }}\n<Shell::TitleBar @title={{this.title}} @back={{this.goBack}} />\n<div\n  local-class=\"article-screen\"\n  ...attributes\n  data-test-article-screen\n  {{did-insert this.startTimer}}\n  {{will-destroy this.trackCloseEvent}}\n>\n  <Iframe local-class=\"iframe override\" @src={{@articleUrl}} />\n\n  <div local-class=\"button-container\">\n    <Ui::Button\n      data-test-article-screen-cta\n      local-class=\"cta override\"\n      @appearance=\"primary\"\n      @type=\"submit\"\n      @onClick={{this.onClickCta}}\n    >\n      {{@ctaText}}\n    </Ui::Button>\n  </div>\n\n</div>","moduleName":"@clarksource/client/components/article-screen.hbs","parseOptions":{"srcName":"@clarksource/client/components/article-screen.hbs"}});
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { DateTime } from 'luxon';

type OpportunityResponse = {
  data: {
    id: number;
    type: string;
  };
};

export interface ArticleScreenSignature {
  Args: {
    appointmentBooking?: string;
    articleId: string;
    articleUrl: string;
    cta: string;
    ctaText: string;
    goBack: string;
    notificationCentreOpen?: string;
    title?: string;
  };
  Element: HTMLDivElement;
}

export default class ArticleScreenComponent extends Component<ArticleScreenSignature> {
  @service declare api: Services['api'];
  @service declare config: Services['config'];
  @service declare intl: Services['intl'];
  @service declare notificationCentre: Services['notification-centre'];
  @service declare router: Services['router'];
  @service declare tracking: Services['tracking'];

  declare startTime: DateTime;
  articleId: string = this.args.articleId;
  notificationCentreOpen = this.args.notificationCentreOpen;
  @tracked hasClickedCta = false;

  get title() {
    if (this.args.title) {
      return this.args.title;
    }

    if (this.config.getConfig(`articles.${this.args.articleId}`)) {
      return this.intl.t(`article-screen.title.${this.args.articleId}`);
    }

    // if no match, we should consider to return a general article title
  }

  createOpportunity = task(
    async (categoryIdent: string): Promise<OpportunityResponse> => {
      return await this.api.post(
        'sales/opportunities/generate_for_customer',
        {
          opportunity: {
            categoryIdent,
          },
        },
        {
          version: 'v5',
        },
      );
    },
  );

  trackingEventProperties() {
    const now = DateTime.now();

    return {
      article: this.articleId,
      timeSpent: Number(now.diff(this.startTime).toFormat('s')),
    };
  }

  @action
  startTimer() {
    this.startTime = DateTime.now();
  }

  @action
  trackCloseEvent(): void {
    if (this.hasClickedCta) {
      return;
    }

    this.tracking.track(
      'articles/article:close',
      this.trackingEventProperties(),
    );

    if (this.notificationCentreOpen === 'true') {
      this.notificationCentre.show();
    }
  }

  @action
  goBack(): void {
    this.router.transitionTo(this.args.goBack);
  }

  @action
  async onClickCta(): Promise<void> {
    this.hasClickedCta = true;

    this.tracking.track(
      'articles/article:click-cta',
      this.trackingEventProperties(),
    );

    const { appointmentBooking, cta } = this.args;

    if (appointmentBooking) {
      const opportunity =
        await this.createOpportunity.perform(appointmentBooking);

      this.router.transitionTo('customer.appointment', {
        queryParams: {
          opportunityId: opportunity.data.id,
          questionnaireSource: 'article-page',
        },
      });

      return;
    }

    this.router.transitionTo(cta);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ArticleScreen: typeof ArticleScreenComponent;
    'article-screen': typeof ArticleScreenComponent;
  }
}
