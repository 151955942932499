import type { Task, TaskInstance } from 'ember-concurrency';

export enum Behavior {
  /*
    If the task is already running, waits for its succession and returns it.
    If it fails, performs the task.
    If the task is not already running, performs the task.
  */
  JOIN_AND_RETRY,

  /*
    If the task is already running, returns it.
    If it fails, this also fails.
    If the task is not already running, performs the task.
  */
  JOIN_OR_PERFORM,

  /*
    Cancels all instances that are possibly running and performs the task.
  */
  CANCEL_ALL_AND_PERFORM,

  /*
    Cancels the last instance, if is running, and performs the task.
  */
  CANCEL_AND_PERFORM,

  /*
    Waits for the last instance to finish, if is running, and then performs the
    task.
  */
  WAIT_AND_PERFORM,
}

export default function singletonTask<T, Args extends unknown[]>(
  task: Task<T, Args>,
  behavior = Behavior.JOIN_AND_RETRY,
  useCache = false,
): (...args: Args) => Promise<TaskInstance<T>> {
  return async function (...args: Args): Promise<TaskInstance<T>> {
    if (useCache && task.last?.isSuccessful) {
      return task.last;
    }

    switch (behavior) {
      case Behavior.JOIN_AND_RETRY:
        if (task.last?.isRunning) {
          try {
            await task.last;
            return task.last;
          } catch {
            return task.perform(...args);
          }
        }
        return task.perform(...args);

      case Behavior.JOIN_OR_PERFORM:
        if (task.last?.isRunning) {
          return task.last;
        }
        return task.perform(...args);

      case Behavior.CANCEL_ALL_AND_PERFORM:
        task.cancelAll();
        return task.perform(...args);

      case Behavior.CANCEL_AND_PERFORM:
        if (task.last?.isRunning) {
          task.last.cancel();
        }
        return task.perform(...args);

      case Behavior.WAIT_AND_PERFORM:
        if (task.last?.isRunning) {
          try {
            await task.last;
          } catch {
            /* eslint-disable-next-line no-empty */
          }
        }
        return task.perform(...args);

      default:
        throw new TypeError(`Unsupported behavior '${behavior}'.`);
    }
  };
}
