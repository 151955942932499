import Route from '@ember/routing/route';
import { type Registry as Services, service } from '@ember/service';

export default class SelectCategoryRoute extends Route {
  @service declare router: Services['router'];

  beforeModel() {
    const { id } = this.paramsFor(this.routeName);

    this.router.replaceWith('index.manager.category', id);
  }
}
