import { action } from '@ember/object';
import { addListener, removeListener } from '@ember/object/events';
import type Owner from '@ember/owner';
import type Transition from '@ember/routing/transition';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import styles from '../../components/messenger-window.css';

const disableBodyScrollStyle = styles['disable-body-scroll']!;

export default class MessengerWindowService extends Service {
  @service declare router: Services['router'];
  @service declare tracking: Services['tracking'];
  @service declare notificationCentre: Services['notification-centre'];

  @tracked open = false;

  pixel!: HTMLElement;

  constructor(owner?: Owner) {
    super(owner);

    addListener(this.router, 'routeDidChange', this.routeDidChange);
  }

  willDestroy() {
    super.willDestroy();

    removeListener(this.router, 'routeDidChange', this.routeDidChange);
  }

  @action
  routeDidChange(transition: Transition) {
    if (this.isMobileWebBrowser && transition.to?.name !== 'index.messenger') {
      this.close();
    }
  }

  @action
  show() {
    /**
     * https://clarkteam.atlassian.net/browse/JCLARK-59863
     * Do not send a tracking event when the messenger is already opened.
     */
    if (this.open) {
      return;
    }

    this.open = true;
    this.toggleBodyScroll(this.open);
    this.tracking.track('messenger_open');

    this.notificationCentre.checkAndUpdateBadgeCount();
  }

  @action
  close() {
    /**
     * https://clarkteam.atlassian.net/browse/JCLARK-59863
     * Do not send a tracking event when the messenger is already closed.
     */
    if (!this.open) {
      return;
    }

    this.open = false;
    this.toggleBodyScroll(this.open);
    this.tracking.track('messenger_close');
  }

  @action
  toggleMessenger(event: KeyboardEvent) {
    const EnterKeyCode = 13;
    const SpaceKeyCode = 32;
    if (event.keyCode === SpaceKeyCode) {
      // Prevent scrolling down while clicking space key.
      event.preventDefault();
    }

    if (
      event.type === 'keydown' &&
      event.keyCode !== EnterKeyCode &&
      event.keyCode !== SpaceKeyCode
    ) {
      return;
    }

    this.open = !this.open;
    this.toggleBodyScroll(this.open);

    if (this.open) {
      this.tracking.track('messenger_open');
    } else {
      this.tracking.track('messenger_close');
    }

    this.notificationCentre.checkAndUpdateBadgeCount();
  }

  get isMobileWebBrowser(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.pixel) {
      const style = window.getComputedStyle(this.pixel);
      return style.visibility === 'hidden';
    }

    return false;
  }

  private toggleBodyScroll(toggle: boolean) {
    if (!this.isMobileWebBrowser) {
      return;
    }

    if (toggle) {
      document.body.classList.add(disableBodyScrollStyle);
      document.documentElement.classList.add(disableBodyScrollStyle);
    } else {
      document.body.classList.remove(disableBodyScrollStyle);
      document.documentElement.classList.remove(disableBodyScrollStyle);
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'messenger/window': MessengerWindowService;
  }
}
