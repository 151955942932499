import { CategoryIdent, RetirementIdent } from '@clark-utils/enums-and-types';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';
import { task } from 'ember-concurrency';

export const PAYBACK_POINTS = 750;
export const MIN_INQUIRIES_ELIGIBLE_FOR_PAYBACK_POINTS = 2;
export const ADJUST_PAYBACK_SOURCE = 'payback';

export default class MandatePaybackService extends Service {
  @service declare api: Services['api'];

  excludeCategoryList = [CategoryIdent.Gkv, RetirementIdent.Gav];

  enablePayback() {
    return this.api.put('payback/customer/enable');
  }

  getUserPayback = task(async () => {
    const response = await this.api.get('payback/customer');

    return response;
  });

  validatePaybackNum = task(async (number: string) => {
    const response = await this.api.put(`payback/customer`, {
      loyalty: {
        payback: {
          paybackNumber: number,
        },
      },
    });

    return response;
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'mandate/payback': MandatePaybackService;
  }
}
