import { getResponseTimeData } from '@clark-home/ui/utils/models/inquiry';
import { CONFIG } from '@clark-utils/business-de';
import type {
  Category,
  FilteredInquiry,
  Inquiry,
} from '@clark-utils/enums-and-types';
import { Asset, CategoryIdent } from '@clark-utils/enums-and-types';
import Emberizer from '@clarksource/client/lifters/helpers/emberizer';
import { htmlSafe } from '@ember/template';

class InquiryItem {
  categories: Category[];

  constructor() {
    this.categories = [];
  }

  // create all the flags etc for the inquiry cards
  formatInquiries(
    inquiries: Inquiry[],
    willWait: boolean,
    categories: Category[],
  ): FilteredInquiry[] {
    const filteredItems: FilteredInquiry[] = [];

    this.categories = categories;

    // @ts-expect-error TS(2345) FIXME: Argument of type '(inquiry: FilteredInquiry) => vo... Remove this comment to see the full error message
    inquiries.forEach((inquiry: FilteredInquiry) => {
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
      if (inquiry.get('category_names').length > 0) {
        inquiry
          // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
          .get('category_names')
          .forEach((categoryName: string, index: number) => {
            // WE HAVE TO DO THIS UNDER THIS CASE, AS WE CREATE MORE ITEMS NOT JUST FILTER :(
            let inqClone = JSON.parse(JSON.stringify(inquiry));
            inqClone = Emberizer.emberize(inqClone);

            // set the cta ident we use in a few places and for matching the cat back to the obj
            const catIdent = inqClone.get('category_idents').at(index);

            this.generateLogo(inqClone);
            inqClone.set('category', categoryName);
            inqClone.set('categoryIdent', catIdent);
            this.doChecksForCardStyles(inqClone, willWait);
            // need to map the categories to the
            inqClone.set(
              'life_aspect',
              this.attachCategoryLifeAspect(catIdent),
            );
            filteredItems.push(inqClone);
          });
      } else {
        this.generateLogo(inquiry);
        this.doChecksForCardStyles(inquiry, willWait);
        filteredItems.push(inquiry);
      }
    });

    return filteredItems;
  }

  generateLogo(inquiry: FilteredInquiry) {
    let url = '';

    if (
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
      inquiry.get('company') &&
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
      inquiry.get('company').get('logo') &&
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
      inquiry.get('company').get('logo').get('url')
    ) {
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
      url = inquiry.get('company').get('logo').get('url');
    } else {
      url = Asset.defaultCompanyLogoImage;
    }

    // @ts-expect-error TS(2339) FIXME: Property 'set' does not exist on type 'FilteredInq... Remove this comment to see the full error message
    inquiry.set('logo_url', htmlSafe(`background-image: url('${url}');`));

    // @ts-expect-error TS(2339) FIXME: Property 'set' does not exist on type 'FilteredInq... Remove this comment to see the full error message
    inquiry.set(
      'display_state',
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
      inquiry.get('state') === 'in_creation' ? 'pending' : inquiry.get('state'),
    );
  }

  doChecksForCardStyles(inquiry: FilteredInquiry, willWait: boolean) {
    let uploadDocuments = false;
    let hasDocuments = false;

    // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
    if (inquiry.get('categoryIdent')) {
      const inqCategory = inquiry
        // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
        .get('inquiry_categories')
        .filter(
          // @ts-expect-error TS(7006) FIXME: Parameter 'object' implicitly has an 'any' type.
          (object) =>
            // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
            object.get('category_ident') === inquiry.get('categoryIdent'),
        );
      if (inqCategory.length > 0) {
        hasDocuments = inqCategory.at(0).get('documents').length;

        // if the inquiry is a GKV set the flag so we do not see the orange 'upload docs' etc
        if (inqCategory.at(0).get('category_ident') === CategoryIdent.Gkv) {
          // @ts-expect-error TS(2339) FIXME: Property 'set' does not exist on type 'FilteredInq... Remove this comment to see the full error message
          inquiry.set('gkv', true);
        }
      }
    } else {
      hasDocuments = inquiry
        // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
        .get('inquiry_categories')
        .some(this.containsDocuments);
    }

    // set flag for if inquiry has docs
    // @ts-expect-error TS(2339) FIXME: Property 'set' does not exist on type 'FilteredInq... Remove this comment to see the full error message
    inquiry.set('documentUploaded', hasDocuments);

    // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
    if (inquiry.get('blacklist')) {
      if (!hasDocuments) {
        uploadDocuments = true;
      }
    } else if (!willWait && !hasDocuments) {
      uploadDocuments = true;
    }

    // if the brand states always blacklist then they should always see
    // upload docs, regardless of state on the inquiry
    if (CONFIG.inquiryDetails.alwaysBlacklist) {
      uploadDocuments = true;
    }

    // @ts-expect-error TS(2339) FIXME: Property 'set' does not exist on type 'FilteredInq... Remove this comment to see the full error message
    inquiry.set('uploadDocs', uploadDocuments);

    // we show the eta if they have document or will wait
    if (
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
      inquiry.get('documentUploaded') ||
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
      (willWait && !inquiry.get('blacklist'))
    ) {
      // set the eta
      // @ts-expect-error TS(2339) FIXME: Property 'set' does not exist on type 'FilteredInq... Remove this comment to see the full error message
      inquiry.set('eta', getResponseTimeData(inquiry));
    }

    // mark as canceled by clark if the state is canceled and not deleted by customer
    // @ts-expect-error TS(2339) FIXME: Property 'set' does not exist on type 'FilteredInq... Remove this comment to see the full error message
    inquiry.set(
      'cancelledByClark',
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
      inquiry.get('state') === 'canceled' &&
        // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
        !inquiry.get('deleted_by_customer'),
    );

    // @ts-expect-error TS(2339) FIXME: Property 'set' does not exist on type 'FilteredInq... Remove this comment to see the full error message
    inquiry.set(
      'hasNotification',
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
      (inquiry.get('uploadDocs') ||
        // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
        inquiry.get('cancelledByClark') ||
        // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
        (inquiry.get('blacklist') && !inquiry.get('documentUploaded'))) &&
        // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'FilteredInq... Remove this comment to see the full error message
        !inquiry.get('gkv'),
    );

    // check if the inquiry is managed by the company in question
    if (
      CONFIG.companyIdent === inquiry.company.ident &&
      CONFIG.managesOwnDocuments
    ) {
      // @ts-expect-error TS(2339) FIXME: Property 'set' does not exist on type 'FilteredInq... Remove this comment to see the full error message
      inquiry.set('hasNotification', false);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'inquiryCategory' implicitly has an 'any... Remove this comment to see the full error message
  containsDocuments(inquiryCategory) {
    return inquiryCategory.get('documents').length > 0;
  }

  private attachCategoryLifeAspect(catIdent: string) {
    const foundCat = this.categories.find(
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'Category'.
      (category: Category) => category.get('ident') === catIdent,
    );

    if (foundCat) {
      // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'Category'.
      return foundCat.get('life_aspect');
    }
    return '';
  }
}

export default InquiryItem;
