import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

export interface Home24CustomerData {
  home24Source: boolean;
  id: number;
  orderNumber?: string;
}

export interface Home24ResponseError {
  title: string;
}

export default class MandateHome24Service extends Service {
  @service declare api: Services['api'];

  @tracked
  isHome24Customer = false;

  async getCustomerData(): Promise<Home24CustomerData> {
    let data: Home24CustomerData;
    if (this.fetchCustomerData.lastSuccessful?.value) {
      // @ts-expect-error TS(2322) FIXME: Type 'Response' is not assignable to type 'Home24C... Remove this comment to see the full error message
      data = this.fetchCustomerData.lastSuccessful.value;
    } else {
      // @ts-expect-error TS(2322) FIXME: Type 'Response' is not assignable to type 'Home24C... Remove this comment to see the full error message
      data = await this.fetchCustomerData.perform();
    }
    return data;
  }

  fetchCustomerData = task(async () => {
    // FIXME:: https://clarkteam.atlassian.net/browse/JCLARK-93306
    return {
      home24Source: false,
    };
  });

  saveOrderNumber(orderNumber: string) {
    return this.api.patch('home24/customer', {
      loyalty: {
        home24: {
          orderNumber,
        },
      },
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'mandate/home24': MandateHome24Service;
  }
}
