import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span\n  local-class=\"notification-badge\"\n  data-test-messenger-notification-badge\n></span>", {"contents":"<span\n  local-class=\"notification-badge\"\n  data-test-messenger-notification-badge\n></span>","moduleName":"@clarksource/client/components/app-header/notification-badge.hbs","parseOptions":{"srcName":"@clarksource/client/components/app-header/notification-badge.hbs"}});
import templateOnlyComponent from '@ember/component/template-only';

interface AppHeaderNotificationBadgeSignature {
  Args: {};
}

const AppHeaderNotificationBadgeComponent =
  templateOnlyComponent<AppHeaderNotificationBadgeSignature>();

export default AppHeaderNotificationBadgeComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AppHeader::NotificationBadge': typeof AppHeaderNotificationBadgeComponent;
    'app-header/notification-badge': typeof AppHeaderNotificationBadgeComponent;
  }
}
