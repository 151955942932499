import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-triple-curlies }}\n{{#let (unique-id) as |inputId|}}\n  <div local-class=\"cookie-banner-tracking\" ...attributes>\n    <div local-class=\"inner\">\n      <label\n        data-test-label\n        for={{concat inputId \"-toggle-control\"}}\n        local-class=\"header\"\n      >\n        {{@header}}\n      </label>\n\n      <p local-class=\"description\">\n        {{{@description}}}\n      </p>\n    </div>\n\n    <div local-class=\"toggle-button-container\">\n      <input\n        checked={{@isChecked}}\n        data-test-field={{or @header \"\"}}\n        disabled={{@isDisabled}}\n        id={{concat inputId \"-toggle-control\"}}\n        local-class=\"toggle-button\"\n        type=\"checkbox\"\n        {{on \"input\" (pick \"target.checked\" @onClick)}}\n      />\n    </div>\n  </div>\n{{/let}}", {"contents":"{{! template-lint-disable no-triple-curlies }}\n{{#let (unique-id) as |inputId|}}\n  <div local-class=\"cookie-banner-tracking\" ...attributes>\n    <div local-class=\"inner\">\n      <label\n        data-test-label\n        for={{concat inputId \"-toggle-control\"}}\n        local-class=\"header\"\n      >\n        {{@header}}\n      </label>\n\n      <p local-class=\"description\">\n        {{{@description}}}\n      </p>\n    </div>\n\n    <div local-class=\"toggle-button-container\">\n      <input\n        checked={{@isChecked}}\n        data-test-field={{or @header \"\"}}\n        disabled={{@isDisabled}}\n        id={{concat inputId \"-toggle-control\"}}\n        local-class=\"toggle-button\"\n        type=\"checkbox\"\n        {{on \"input\" (pick \"target.checked\" @onClick)}}\n      />\n    </div>\n  </div>\n{{/let}}","moduleName":"@clarksource/client/components/cookie-banner/tracking-option.hbs","parseOptions":{"srcName":"@clarksource/client/components/cookie-banner/tracking-option.hbs"}});
import templateOnlyComponent from '@ember/component/template-only';

interface CookieBannerTrackingOptionSignature {
  Args: {
    description: string;
    header: string;
    isChecked?: boolean;
    isDisabled?: boolean;
    onClick: (isEnabled: boolean) => void;
  };
  Element: HTMLDivElement;
}

const CookieBannerTrackingOptionComponent =
  templateOnlyComponent<CookieBannerTrackingOptionSignature>();

export default CookieBannerTrackingOptionComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CookieBanner::TrackingOption': typeof CookieBannerTrackingOptionComponent;
  }
}
