import Controller from '@ember/controller';
import { action } from '@ember/object';
import { type Registry as Services, service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const rootUrlPrefix = '/de/app';

export default class LoginController extends Controller {
  @service declare router: Services['router'];

  queryParams = ['callback'];

  @tracked callback?: string;

  @action
  transitionToCallback() {
    if (!this.callback) {
      this.router.replaceWith('index');
      return;
    }

    try {
      let url = decodeURIComponent(this.callback);

      /**
       * recognize returns null if the route is not recognized.
       * The return type definition is wrong, so ignore ts error.
       */
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!this.router.recognize(url)) {
        this.router.replaceWith('index');
        return;
      }

      if (url.indexOf(rootUrlPrefix) === 0) {
        url = url.slice(rootUrlPrefix.length);
      }

      this.router.replaceWith(url);
    } catch {
      // if the route was not found, redirect to index
      this.router.replaceWith('index');
    }
  }
}
