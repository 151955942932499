import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<input\n  data-test-iban-form-input\n  type=\"text\"\n  value={{@value}}\n  placeholder={{t \"iban.input_placeholder\"}}\n  autocorrect=\"off\"\n  autocapitalize=\"characters\"\n  autocomplete=\"off\"\n  {{on \"input\" this.updateValue}}\n  ...attributes\n/>", {"contents":"<input\n  data-test-iban-form-input\n  type=\"text\"\n  value={{@value}}\n  placeholder={{t \"iban.input_placeholder\"}}\n  autocorrect=\"off\"\n  autocapitalize=\"characters\"\n  autocomplete=\"off\"\n  {{on \"input\" this.updateValue}}\n  ...attributes\n/>","moduleName":"@clarksource/client/components/iban-input.hbs","parseOptions":{"srcName":"@clarksource/client/components/iban-input.hbs"}});
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import Component from '@glimmer/component';

interface IbanInputSignature {
  Args: {
    update?: (value: string | null) => void;
    value: string;
  };
  Element: HTMLInputElement;
}

export default class IbanInputComponent extends Component<IbanInputSignature> {
  @action
  updateValue(event: any) {
    const { update } = this.args;
    const { target } = event;
    const { value, selectionStart, selectionEnd } = target;
    const newValue = value.replace(/[^\da-z]/gi, '').toUpperCase();

    update?.(newValue);
    target.value = newValue;

    // eslint-disable-next-line ember/no-runloop
    next(this, () => target.setSelectionRange(selectionStart, selectionEnd));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    IbanInput: typeof IbanInputComponent;
    'iban-input': typeof IbanInputComponent;
  }
}
