import type { User } from '@clark-utils/enums-and-types';
import { ErrorCode } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { StatusCodes } from '@clarksource/ember-api/status-codes';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import * as Sentry from '@sentry/browser';
// eslint-disable-next-line import/no-unresolved
import { hash } from 'rsvp';

type Model = {
  user?: User;
};

export default class IndexManagerLandingRoute extends ClarkRoute {
  @service declare authEventManager: Services['auth-event-manager'];

  @service declare experiments: Services['experiments'];
  @service declare router: Services['router'];
  @service declare session: Services['session'];

  model(): Model {
    return {
      user: this.session.currentUser,
    };
  }

  async redirect(model: Model) {
    try {
      await hash(model);
    } catch (error) {
      Sentry.captureException(error);

      const isClark1User =
        this.experiments.getVariant('business-strategy') === 'control';

      if (!isClark1User) {
        return;
      }

      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      if (error.status === StatusCodes.UNAUTHORIZED) {
        this.authEventManager.didLogout();
        this.router.replaceWith('login');

        return;
      }

      this.router.replaceWith('errors', {
        queryParams: { code: ErrorCode.VERTRAG_DETAILS },
      });
    }
  }
}
