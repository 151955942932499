import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (and this.showMiniFooter (media \"isAboveMedium\"))}}\n  <AppFooter::MiniFooter />\n{{/if}}", {"contents":"{{#if (and this.showMiniFooter (media \"isAboveMedium\"))}}\n  <AppFooter::MiniFooter />\n{{/if}}","moduleName":"@clarksource/client/components/app-footer.hbs","parseOptions":{"srcName":"@clarksource/client/components/app-footer.hbs"}});
import type { PlatformService } from '@clark-shell/ember';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import Component from '@glimmer/component';

interface AppFooterSignature {
  Args: {};
}

export default class AppFooterComponent extends Component<AppFooterSignature> {
  @service declare router: Services['router'];
  @service declare session: Services['session'];
  @service('shell/platform') declare platform: PlatformService;

  readonly noFooterRoutes = [
    'index.mandate.index.status',
    'index.mandate.index.custom-funnel',
  ];

  get showMiniFooter(): boolean {
    return (
      !this.platform.isNative &&
      !this.isNoFooterRoute &&
      (this.router.currentRouteName?.startsWith('index.mandate') as boolean) &&
      !this.session.isAuthenticated
    );
  }

  get isNoFooterRoute(): boolean {
    return this.noFooterRoutes.includes(this.router.currentRouteName as string);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AppFooter: typeof AppFooterComponent;
    'app-footer': typeof AppFooterComponent;
  }
}
