import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if\n  (and (media \"isBelowMedium\") this.home24.isHome24Customer)\n}}\n  <Ui::Icon\n    local-class=\"partner-logo\"\n    @source=\"partner-home24\"\n    @size=\"extra-large\"\n    ...attributes\n  />\n{{/if}}", {"contents":"{{#if\n  (and (media \"isBelowMedium\") this.home24.isHome24Customer)\n}}\n  <Ui::Icon\n    local-class=\"partner-logo\"\n    @source=\"partner-home24\"\n    @size=\"extra-large\"\n    ...attributes\n  />\n{{/if}}","moduleName":"@clarksource/client/components/mandate/home24-logo-mobile.hbs","parseOptions":{"srcName":"@clarksource/client/components/mandate/home24-logo-mobile.hbs"}});
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import Component from '@glimmer/component';

interface MandateHome24LogoMobileSignature {
  Args: {};
  Element: HTMLElement;
}

export default class MandateHome24LogoMobileComponent extends Component<MandateHome24LogoMobileSignature> {
  // eslint-disable-next-line ember/no-unused-services
  @service('mandate/home24') declare home24: Services['mandate/home24'];
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Mandate::Home24LogoMobile': typeof MandateHome24LogoMobileComponent;
    'mandate/home24-logo-mobile': typeof MandateHome24LogoMobileComponent;
  }
}
