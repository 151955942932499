import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

class OfferRoute extends Route {
  @service declare router: Services['router'];

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    this.router.transitionTo('offers.offer', transition.to.params.offer_id);
  }

  model() {
    // Do nothing
  }
}

export default OfferRoute;
