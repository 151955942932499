class Clark2CommonQuestions {
  static questions = {
    gender: {
      question: 'Was ist dein Geschlecht?',
      hint: 'Deine Lebenserwartung wird von deinem Geschlecht bestimmt.',
      type: 1,
      options: [
        {
          label: 'Weiblich',
          value: 'female',
          selected: false,
        },
        {
          label: 'Männlich',
          value: 'male',
          selected: false,
        },
      ],
    },
    birthdate: {
      question: 'Wann bist du geboren?',
      hint: 'Dein Geburtsdatum bestimmt, ab wann du ohne Abzüge in Rente gehen kannst.',
      type: 9,
      options: [],
    },
  };

  static getQuestion(type: string, id: string, required: number) {
    return {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ...Clark2CommonQuestions.questions[type],
      required,
      id,
    };
  }
}

export type Source = 'austria' | 'default';

function demandcheckDataForAustria(customer: unknown) {
  const questionnaire = {
    type: 'bedarfcheck',
    questions: [
      {
        question: 'Wo wohnst du?',
        hint: 'Deine Wohnsituation bestimmt, wie du dein Hab und Gut versichern solltest.',
        type: 1,
        lifeaspect: 1,
        id: 'demand_livingplace',
        required: 1,
        options: [
          {
            label: 'In einer gemieteten Wohnung',
            value: 'In einer gemieteten Wohnung',
            selected: false,
          },
          {
            label: 'In meiner eigenen Wohnung',
            value: 'In meiner eigenen Wohnung',
            selected: false,
          },
          {
            label: 'In einem gemieteten Haus',
            value: 'In einem gemieteten Haus',
            selected: false,
          },
          {
            label: 'In meinem eigenen Haus',
            value: 'In meinem eigenen Haus',
            selected: false,
          },
          {
            label: 'Andere Wohnsituation',
            value: 'Andere Wohnsituation',
            selected: false,
          },
        ],
      },
      {
        question: 'Planst du, in den nächsten 6 Monaten ein Haus zu bauen?',
        hint: 'Ein Hausbau birgt für dich als Bauherrn viele Risiken, die du leicht absichern kannst.',
        type: 1,
        lifeaspect: 1,
        id: 'demand_estate',
        required: 1,
        options: [
          { label: 'Ja', value: 'Ja', selected: false },
          { label: 'Nein', value: 'Nein', selected: false },
        ],
      },
      {
        question: 'Besitzt du eines der folgenden Fahrzeuge?',
        hint: 'Deine Fahrzeuge sind entweder per Gesetz versicherungspflichtig oder lassen sich entsprechend des Fahrzeugwerts individuell absichern.',
        type: 2,
        lifeaspect: 1,
        required: 0,
        id: 'demand_vehicle',
        hasSubOptions: false,
        options: [
          {
            label: 'Auto',
            value: 'Auto',
            selected: false,
            enableFlag: false,
          },
          {
            label: 'Motorrad',
            value: 'Motorrad',
            selected: false,
            enableFlag: false,
          },
          {
            label: 'Mofa oder Moped',
            value: 'Mofa oder Moped',
            selected: false,
            enableFlag: false,
          },
          {
            label: 'Wohnwagen oder -mobil',
            value: 'Wohnwagen oder -mobil',
            selected: false,
            enableFlag: false,
          },
          {
            label: 'Anhänger',
            value: 'Anhänger',
            selected: false,
            enableFlag: false,
          },
          {
            label: 'Fahrrad / E-Bike / E-Scooter',
            value: 'Fahrrad / E-Bike / E-Scooter',
            selected: false,
            enableFlag: false,
          },
        ],
      },
      {
        question: 'Wie ist deine Familiensituation?',
        hint: 'Deine Familiensituation entscheidet, ob eine Vorsorge für den Ernstfall für dich infrage kommt.',
        type: 1,
        lifeaspect: 2,
        required: 1,
        id: 'demand_family',
        options: [
          {
            label: 'Ich lebe in einer Partnerschaft',
            value: 'Ich lebe in einer Partnerschaft',
            selected: false,
          },
          {
            label: 'Ich bin verheiratet',
            value: 'Ich bin verheiratet',
            selected: false,
          },
          {
            label: 'Ich lebe alleine',
            value: 'Ich lebe alleine',
            selected: false,
          },
        ],
      },
      {
        question:
          'Hast du Kinder, die unter 18 Jahre alt oder noch in Ausbildung sind?',
        hint: 'Wenn du eine Familie und Kinder hast, solltest du Vorsorge- und Absicherungsmaßnahmen treffen.',
        type: 1,
        lifeaspect: 2,
        required: 1,
        id: 'demand_kids',
        options: [
          { label: 'Ja', value: 'Ja', selected: false },
          { label: 'Nein', value: 'Nein', selected: false },
        ],
      },
      {
        question: 'Was machst du Was ist deine Berufsgruppe?',
        hint: 'Dein Beruf bestimmt, welche Möglichkeiten zur Einkommenssicherung oder Altersvorsorge für dich infrage kommen.',
        type: 1,
        lifeaspect: 2,
        required: 1,
        id: 'demand_job',
        hasSubOptions: false,
        options: [
          {
            label: 'Angestellt',
            value: 'Angestellter oder Arbeiter',
            selected: false,
            answer: '',
          },
          {
            label: 'Selbstständig',
            value: 'Selbstständiger',
            selected: false,
            answer: '',
          },
          {
            label: 'Freiberuflich tätig',
            value: 'Freiberufler',
            selected: false,
            answer: '',
          },
          {
            label: 'Im öffentlichen Dienst tätig',
            value: 'Beamter oder Vertragsbediensteter',
            selected: false,
            answer: '',
          },
          {
            label: 'In Ausbildung',
            value: 'Lehrling oder Student',
            selected: false,
            answer: '',
          },
          {
            label: 'Ich gehe zur Schule',
            value: 'Schüler',
            selected: false,
            answer: '',
          },
          { label: 'Arbeitssuchend', value: 'Arbeitssuchend', selected: false },
          { label: 'Im Ruhestand', value: 'Pensionist', selected: false },
          {
            label: 'Hausfrau oder Hausmann',
            value: 'Hausfrau oder Hausmann',
            selected: false,
          },
        ],
        jumps: [
          {
            destination: {
              id: 'demand_hobby',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Angestellter oder Arbeiter',
              },
            ],
          },
          {
            destination: {
              id: 'demand_hobby',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Selbstständiger',
              },
            ],
          },
          {
            destination: {
              id: 'demand_hobby',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Lehrling oder Student',
              },
            ],
          },
          {
            destination: {
              id: 'demand_hobby',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Schüler',
              },
            ],
          },
          {
            destination: {
              id: 'demand_hobby',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Arbeitssuchend',
              },
            ],
          },
          {
            destination: {
              id: 'demand_hobby',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Pensionist',
              },
            ],
          },
          {
            destination: {
              id: 'demand_hobby',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Hausfrau oder Hausmann',
              },
            ],
          },
        ],
      },
      {
        question: 'Was ist deine Berufsbezeichnung?',
        hint: 'Je nach Berufsfeld bist du unterschiedlichen Risiken ausgesetzt.',
        type: 4,
        answer: '',
        placeholder: 'Berufsbezeichnung',
        lifeaspect: 2,
        required: 0,
        id: 'demand_job_title',
        options: [],
      },
      {
        question: 'Was machst du gerne in deiner Freizeit?',
        hint: 'Deine Freizeitaktivitäten können mit einem Risiko verbunden sein, das sich leicht absichern lässt.',
        type: 2,
        lifeaspect: 2,
        required: 0,
        id: 'demand_hobby',
        options: [
          {
            label: 'Ich reise sehr viel',
            value: 'Ich reise sehr viel',
            selected: false,
          },
          {
            label: 'Ich mache regelmäßig Sport',
            value: 'Ich mache regelmäßig Sport',
            selected: false,
          },
          {
            label: 'Ich verbringe sehr viel Zeit mit meiner Familie',
            value: 'Ich verbringe sehr viel Zeit mit meiner Familie',
            selected: false,
          },
          {
            label: 'Ich arbeite gerne in Haus und Garten',
            value: 'Ich arbeite gerne in Haus und Garten',
            selected: false,
          },
        ],
      },
      {
        question: 'Hast du Tiere?',
        hint: 'Tierversicherungen sind entweder gesetzlich erforderlich oder sorgen für eine optimale und kostengünstige Behandlung deiner Tiere.',
        type: 2,
        lifeaspect: 2,
        required: 0,
        id: 'demand_pets',
        options: [
          { label: 'Hund', value: 'Hund', selected: false },
          { label: 'Katze', value: 'Katze', selected: false },
          { label: 'Pferd', value: 'Pferd', selected: false },
          { label: 'Kleintiere', value: 'Kleintiere', selected: false },
        ],
      },
      {
        question: 'Wie hoch ist dein letztes Jahresbruttogehalt?',
        hint: 'Dein Gehalt ist ein wichtiger Indikator für die optimale Absicherung deiner Gesundheit und Altersvorsorge.',
        type: 5,
        answer: '',
        placeholder: 'in Euro',
        lifeaspect: 3,
        required: 1,
        id: 'demand_annual_salary',
        options: [],
      },
    ],
  };

  if (customer) {
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    if (!customer.birthdate) {
      questionnaire.questions.push(
        Clark2CommonQuestions.getQuestion('birthdate', 'demand_birthdate', 1),
      );
    }

    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    if (!customer.gender) {
      questionnaire.questions.push(
        Clark2CommonQuestions.getQuestion('gender', 'demand_gender', 1),
      );
    }
  }

  return questionnaire;
}

function demandcheckData(customer: unknown) {
  const questionnaire = {
    type: 'bedarfcheck',
    questions: [
      {
        question: 'Wo wohnst du?',
        hint: 'Abhängig von deiner Wohnsituation können verschiedene Versicherungen notwendig sein.',
        type: 1,
        lifeaspect: 1,
        id: 'demand_livingplace',
        required: 1,
        options: [
          {
            label: 'In einer gemieteten Wohnung',
            value: 'In einer gemieteten Wohnung',
            selected: false,
          },
          {
            label: 'In meiner eigenen Wohnung',
            value: 'In meiner eigenen Wohnung',
            selected: false,
          },
          {
            label: 'In einem gemieteten Haus',
            value: 'In einem gemieteten Haus',
            selected: false,
          },
          {
            label: 'In meinem eigenen Haus',
            value: 'In meinem eigenen Haus',
            selected: false,
          },
          { label: 'Nichts davon', value: 'Nichts davon', selected: false },
        ],
      },
      {
        question: 'Besitzt du eines der folgenden Fahrzeuge?',
        hint: 'Deine Fahrzeuge sind entweder per Gesetz versicherungspflichtig oder lassen sich entsprechend des Fahrzeugwerts individuell absichern.',
        type: 2,
        lifeaspect: 1,
        required: 0,
        id: 'demand_vehicle',
        options: [
          { label: 'Auto', value: 'Auto', selected: false, enableFlag: false },
          {
            label: 'Wohnwagen',
            value: 'Wohnwagen',
            selected: false,
            enableFlag: false,
          },
          {
            label: 'Anhänger',
            value: 'Anhanger',
            selected: false,
            enableFlag: false,
          },
          {
            label: 'Motorrad',
            value: 'Motorrad',
            selected: false,
            enableFlag: false,
          },
        ],
      },
      {
        question: 'Wie ist deine Familiensituation?',
        hint: 'Deine Familiensituation bestimmt, ob eine Vorsorge für den Ernstfall sinnvoll ist.',
        type: 1,
        lifeaspect: 2,
        required: 1,
        id: 'demand_family',
        options: [
          {
            label: 'Ich bin in einer Partnerschaft',
            value: 'Ich bin in einer Partnerschaft',
            selected: false,
          },
          {
            label: 'Ich bin verheiratet',
            value: 'Ich bin verheiratet',
            selected: false,
          },
          { label: 'Ich bin Single', value: 'Ich bin Single', selected: false },
        ],
      },
      {
        question:
          'Hast du Kinder, die unter 18 Jahre alt oder noch in Ausbildung sind?',
        hint: 'Wenn du eine Familie und Kinder hast, solltest du Vorsorge- und Absicherungsmaßnahmen treffen.',
        type: 1,
        lifeaspect: 2,
        required: 1,
        id: 'demand_kids',
        options: [
          { label: 'Ja', value: 'Ja', selected: false },
          { label: 'Nein', value: 'Nein', selected: false },
        ],
      },
      {
        question: 'Was machst du beruflich?',
        hint: 'Wir stimmen unsere Empfehlungen auf deine berufliche Situation ab.',
        type: 1,
        lifeaspect: 2,
        required: 1,
        id: 'demand_job',
        hasSubOptions: false,
        options: [
          {
            label: 'Angestellt',
            value: 'Angestellter',
            selected: false,
          },
          {
            label: 'Selbstständig',
            value: 'Selbststandig',
            selected: false,
          },
          {
            label: 'Freiberuflich tätig',
            value: 'Freiberufler',
            selected: false,
          },
          {
            label: 'Verbeamtet',
            value: 'Beamter',
            selected: false,
          },
          {
            label: 'Berufssoldat:in',
            value: 'Freie Heilfursorge',
            selected: false,
          },
          {
            label: 'In Ausbildung',
            value: 'Auszubildender',
            selected: false,
          },
          {
            label: 'Ich studiere',
            value: 'Student',
            selected: false,
          },
          {
            label: 'Ich gehe zur Schule',
            value: 'Schuler',
            selected: false,
          },
          {
            label: 'Arbeitssuchend',
            value: 'Arbeitssuchend',
            selected: false,
          },
          {
            label: 'Im Ruhestand',
            value: 'Ruhestandler',
            selected: false,
          },
          {
            label: 'Hausfrau/Hausmann',
            value: 'Hausfrauen/-manner',
            selected: false,
          },
        ],
        jumps: [
          {
            destination: {
              id: 'demand_job_title',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Angestellter',
              },
            ],
          },
          {
            destination: {
              id: 'demand_job_title',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Selbststandig',
              },
            ],
          },
          {
            destination: {
              id: 'demand_job_renten',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Freiberufler',
              },
            ],
          },
          {
            destination: {
              id: 'demand_job_title',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Beamter',
              },
            ],
          },
          {
            destination: {
              id: 'demand_hobby',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Freie Heilfursorge',
              },
            ],
          },
          {
            destination: {
              id: 'demand_job_title',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Auszubildender',
              },
            ],
          },
          {
            destination: {
              id: 'demand_health_insurance_type',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Student',
              },
            ],
          },
          {
            destination: {
              id: 'demand_health_insurance_type',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Schuler',
              },
            ],
          },
          {
            destination: {
              id: 'demand_hobby',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Arbeitssuchend',
              },
            ],
          },
          {
            destination: {
              id: 'demand_job_title',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Ruhestandler',
              },
            ],
          },
          {
            destination: {
              id: 'demand_hobby',
            },
            conditions: [
              {
                field: 'demand_job',
                value: 'Hausfrauen/-manner',
              },
            ],
          },
        ],
      },
      {
        question: 'Bist du gesetzlich rentenversichert?',
        type: 1,
        lifeaspect: 1,
        id: 'demand_job_renten',
        required: 1,
        options: [
          {
            label: 'Ja',
            value: 'und gesetztlich versichert',
            selected: false,
          },
          {
            label: 'Nein',
            value: 'und nicht gesetztlich versichert',
            selected: false,
          },
        ],
      },
      {
        question: 'Was ist deine Berufsbezeichnung?',
        hint: 'Je nach Berufsfeld bist du unterschiedlichen Risiken ausgesetzt.',
        type: 4,
        answer: '',
        placeholder: 'Berufsbezeichnung',
        lifeaspect: 2,
        required: 0,
        id: 'demand_job_title',
        options: [],
      },
      {
        question: 'Bist du gesetzlich oder privat krankenversichert?',
        hint: '',
        type: 1,
        hasSubOptions: false,
        lifeaspect: 1,
        required: 1,
        id: 'demand_health_insurance_type',
        options: [
          {
            label: 'Gesetzlich',
            value: 'und bin gesetzlich krankenversichert',
            selected: false,
          },
          {
            label: 'Privat',
            value: 'und bin privat krankenversichert',
            selected: false,
          },
        ],
      },
      {
        question: 'Was machst du in deiner Freizeit?',
        hint: 'In Einzelfällen ist es sinnvoll, Risiken auch in der Freizeit abzusichern.',
        type: 2,
        lifeaspect: 2,
        required: 0,
        id: 'demand_hobby',
        options: [
          {
            label: 'Ich reise sehr viel',
            value: 'Ich reise sehr viel',
            selected: false,
          },
          {
            label: 'Ich betreibe eine gefährliche Sportart',
            value: 'Ich treibe intensiv Sport',
            selected: false,
          },
          {
            label: 'Ich verbringe sehr viel Zeit mit meiner Familie',
            value: 'Ich verbringe sehr viel Zeit mit meiner Familie',
            selected: false,
          },
          {
            label: 'Ich arbeite gerne in Haus und Garten',
            value: 'Ich arbeite gerne in Haus und Garten',
            selected: false,
          },
        ],
      },
      {
        question: 'Hast du Tiere?',
        hint: 'Eine Versicherung für deine Tiere kann gesetzlich erforderlich sein.',
        type: 2,
        lifeaspect: 2,
        required: 0,
        id: 'demand_pets',
        options: [
          { label: 'Hund', value: 'Hund', selected: false },
          { label: 'Katze', value: 'Katze', selected: false },
          { label: 'Pferd', value: 'Pferd', selected: false },
          { label: 'Kleintiere', value: 'Kleintiere', selected: false },
        ],
      },
      {
        question: 'Wie hoch ist dein Jahresbruttogehalt?',
        hint: 'Dein Gehalt ist ein wichtiger Indikator für die optimale Absicherung deiner Gesundheit und Altersvorsorge.',
        type: 5,
        answer: '',
        placeholder: 'z.B. 40.000',
        lifeaspect: 3,
        required: 1,
        id: 'demand_annual_salary',
        options: [],
      },
    ],
  };

  if (customer) {
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    if (!customer.birthdate) {
      questionnaire.questions.push(
        Clark2CommonQuestions.getQuestion('birthdate', 'demand_birthdate', 1),
      );
    }

    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    if (!customer.gender) {
      questionnaire.questions.push(
        Clark2CommonQuestions.getQuestion('gender', 'demand_gender', 1),
      );
    }
  }

  return questionnaire;
}

export function getDemandcheckData({
  customer,
  source,
}: {
  customer: unknown;
  source: Source;
}) {
  switch (source) {
    case 'austria': {
      return demandcheckDataForAustria(customer);
    }

    case 'default':
    default: {
      return demandcheckData(customer);
    }
  }
}
