import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (media \"isBelowMedium\")}}\n  {{#unless this.isNavigationHidden}}\n    <AppHeader::MobileMenu\n      ...attributes\n      {{root-property name=\"--tab-bar-offset\" value=\"45px\"}}\n    />\n  {{/unless}}\n{{else if this.isHeaderVisible}}\n  <AppHeader::OldHeader\n    ...attributes\n    {{root-property name=\"--web-header-offset\" value=\"80px\"}}\n    @hideNavigation={{this.isNavigationHidden}}\n    @showLegalLinks={{this.showLegalLinks}}\n  />\n{{/if}}", {"contents":"{{#if (media \"isBelowMedium\")}}\n  {{#unless this.isNavigationHidden}}\n    <AppHeader::MobileMenu\n      ...attributes\n      {{root-property name=\"--tab-bar-offset\" value=\"45px\"}}\n    />\n  {{/unless}}\n{{else if this.isHeaderVisible}}\n  <AppHeader::OldHeader\n    ...attributes\n    {{root-property name=\"--web-header-offset\" value=\"80px\"}}\n    @hideNavigation={{this.isNavigationHidden}}\n    @showLegalLinks={{this.showLegalLinks}}\n  />\n{{/if}}","moduleName":"@clarksource/client/components/app-header.hbs","parseOptions":{"srcName":"@clarksource/client/components/app-header.hbs"}});
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export interface AppHeaderSignature {
  Args: {};
  Element: HTMLElement;
}

export default class AppHeaderComponent extends Component<AppHeaderSignature> {
  @service declare clarkVersion: Services['clark-version'];
  @service declare navBar: Services['nav-bar'];
  @service declare session: Services['session'];

  get isHeaderVisible(): boolean {
    return !this.navBar.currentState?.hideDesktopHeader;
  }

  get isNavigationHidden(): boolean {
    if (!this.clarkVersion.isClark2 && !this.session.isAuthenticated) {
      return true;
    }

    return Boolean(this.navBar.currentState?.hide);
  }

  get showLegalLinks(): boolean {
    return Boolean(this.navBar.currentState?.showLegalLinks);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AppHeader: typeof AppHeaderComponent;
    'app-header': typeof AppHeaderComponent;
  }
}
