/* eslint-disable ember/no-actions-hash */
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { runTask } from 'ember-lifeline';

export default class TargetingSelection extends ClarkRoute.extend({
  actions: {
    didTransition() {
      // @ts-expect-error: Property '_super' does not exist on type '{}'
      this._super(false);
    },
  },
}) {
  @service declare tracking: Services['tracking'];

  async model() {
    const targetingModel = this.modelFor('index.mandate.index.targeting');

    return {
      user: this.modelFor('index'),
      // @ts-expect-error: 'targetingModel' is of type 'unknown'
      categories: targetingModel.categories,
      // @ts-expect-error: 'targetingModel' is of type 'unknown'
      isHome24Customer: targetingModel.isHome24Customer,
      onBack: this.onBack,
    };
  }

  @action
  onBack() {
    this.tracking.track('manager__cat-select_click_back');
    runTask(this, () => window.history.back());
  }
}
