import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Shell::SettingsRoute::Toggle\n  @label={{t \"native.settings.options.push_enabled\"}}\n  @checked={{this.isEnabled}}\n  @update={{this.update}}\n  @busy={{this.isUninitialized}}\n/>", {"contents":"<Shell::SettingsRoute::Toggle\n  @label={{t \"native.settings.options.push_enabled\"}}\n  @checked={{this.isEnabled}}\n  @update={{this.update}}\n  @busy={{this.isUninitialized}}\n/>","moduleName":"@clarksource/client/components/shell/settings-route/push-notifications-toggle.hbs","parseOptions":{"srcName":"@clarksource/client/components/shell/settings-route/push-notifications-toggle.hbs"}});
import type { PushNotificationsSettingsService } from '@clark-shell/ember';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { indeterminate } from './toggle/button';

interface ShellSettingsRoutePushNotificationsToggleSignature {
  Args: {};
}

export default class ShellSettingsRoutePushNotificationsToggleComponent extends Component<ShellSettingsRoutePushNotificationsToggleSignature> {
  @service('shell/settings/push-notifications')
  declare shellSettingsPushNotifications: PushNotificationsSettingsService;

  get isEnabled(): boolean | typeof indeterminate {
    return this.shellSettingsPushNotifications.isEnabled ?? indeterminate;
  }

  get isUninitialized(): boolean {
    return this.isEnabled === indeterminate;
  }

  @action async update(isChecked: boolean): Promise<void> {
    await this.shellSettingsPushNotifications.toggle(isChecked);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Shell::SettingsRoute::PushNotificationsToggle': typeof ShellSettingsRoutePushNotificationsToggleComponent;
    'shell/settings-route/push-notifications-toggle': typeof ShellSettingsRoutePushNotificationsToggleComponent;
  }
}
