import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-triple-curlies }}\n{{! Loading icon for web is needed for a new experiment (which one?) }}\n{{#unless this.loaded}}\n  <div class=\"mandate-confirmation__loader\">\n    <svg\n      class=\"mandate-confirmation__loader__circle\"\n      viewBox=\"25 25 50 50\"\n    >\n      <circle\n        class=\"mandate-confirmation__loader__circle__path\"\n        cx=\"50\"\n        cy=\"50\"\n        r=\"20\"\n        fill=\"none\"\n        stroke-width=\"2\"\n        stroke-miterlimit=\"10\"\n      ></circle>\n    </svg>\n  </div>\n{{/unless}}\n\n<div>\n  {{{this.iFrameMarkup}}}\n</div>\n\n<div\n  class=\"mandate-confirmation__instructions\n    {{if\n      this.loaded\n      'mandate-confirmation__instructions--loaded'\n    }}\"\n>\n  {{t \"account.wizards.confirming.signature_new.instructions\"}}\n</div>", {"contents":"{{! template-lint-disable no-triple-curlies }}\n{{! Loading icon for web is needed for a new experiment (which one?) }}\n{{#unless this.loaded}}\n  <div class=\"mandate-confirmation__loader\">\n    <svg\n      class=\"mandate-confirmation__loader__circle\"\n      viewBox=\"25 25 50 50\"\n    >\n      <circle\n        class=\"mandate-confirmation__loader__circle__path\"\n        cx=\"50\"\n        cy=\"50\"\n        r=\"20\"\n        fill=\"none\"\n        stroke-width=\"2\"\n        stroke-miterlimit=\"10\"\n      ></circle>\n    </svg>\n  </div>\n{{/unless}}\n\n<div>\n  {{{this.iFrameMarkup}}}\n</div>\n\n<div\n  class=\"mandate-confirmation__instructions\n    {{if\n      this.loaded\n      'mandate-confirmation__instructions--loaded'\n    }}\"\n>\n  {{t \"account.wizards.confirming.signature_new.instructions\"}}\n</div>","moduleName":"@clarksource/client/components/mandate/insign-signature.hbs","parseOptions":{"srcName":"@clarksource/client/components/mandate/insign-signature.hbs"}});
/* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/no-component-lifecycle-hooks */
import type { PlatformService } from '@clark-shell/ember';
import Component from '@ember/component';
import { schedule } from '@ember/runloop';
import { service } from '@ember/service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface MandateMandateInsignSignature {
  Args: {};
}

export default Component.extend({
  platform: service('shell/platform'),
  tagName: '',
  loaded: false,
  // have to create the markup dynamically to avoid race conditions
  iFrameMarkup: '',

  didReceiveAttrs() {
    // @ts-expect-error: Property '_super' does not exist on type 'Mixin | Record<string, unknown>'
    this._super();

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    window.clark.ember = window.clark.ember || {};
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    window.clark.ember.listeners = window.clark.ember.listeners || {};
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    window.clark.ember.listeners.insignLoaded = this.insignLoaded.bind(this);
  },

  didInsertElement() {
    // @ts-expect-error: Property '_super' does not exist on type 'Mixin | Record<string, unknown>'
    this._super();

    // for the web
    // eslint-disable-next-line ember/no-runloop
    schedule('afterRender', this, () => {
      this.setUpIframe();
    });
  },

  setUpIframe() {
    const width = document.body.clientWidth;
    const height = document.body.clientHeight;

    let widthForFrame = 0;
    let heightForFrame = 0;

    if (!(this.platform as unknown as PlatformService).isNative) {
      if (width > 1000) {
        widthForFrame = 860;
      } else {
        // -10 % from width as in modal
        // then take 40 from that for padding (in modal) (100 if larger than 730)
        const padding = width > 740 ? 100 : 40;
        const tenPercent = (width / 100) * 10;

        widthForFrame = width - padding - tenPercent;
      }
      heightForFrame = width > 740 ? 250 : 140;
    } else {
      // for the app

      // height is 100% - height of other things on the page (accumulated value of other divs)
      // 27 - instructions
      // 15 - margin
      // 60 - CTA
      // 48 - header
      // total 150, plus 10 to make sure
      heightForFrame = width - 160;
      // 30 is margin on both sides (93 for the tab and title bar)
      widthForFrame = height - 30 + 93;
    }

    // @ts-expect-error: Property 'set' does not exist on type 'Mixin | Record<string, unknown>'
    this.set(
      'iFrameMarkup',
      `
      <iframe id="insign-iframe"
              style="height:${heightForFrame}px;"
              class="mandate-confirmation__iframe"
              src="/de/account/mandate/insign?width=${widthForFrame}px&height=${heightForFrame}px&variation=true"
              frameborder="0"
              scrolling="no">
      </iframe>
    `,
    );
  },

  insignLoaded() {
    // @ts-expect-error: Property 'set' does not exist on type 'Mixin | Record<string, unknown>'
    this.set('loaded', true);
  },
});
