import Evented from '@ember/object/evented';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

/**
 * This service exists so that the cookie banner can be shown/hidden/managed by any component
 */
export default class CookieBannerManagementService extends Service.extend(
  Evented,
) {
  @tracked
  isVisible = false;

  @tracked
  canShowAdvancedCookies = false;

  isUpdate = false;

  isConfigShownAtleastOnce = false;

  toggleShowCookieBanner(
    isVisible = !this.isVisible,
    showAdvancedCookies = false,
  ): void {
    this.isVisible = isVisible;
    if (showAdvancedCookies) {
      this.toggleShowAdvancedCookies(true);
    }
  }

  toggleShowAdvancedCookies(
    canShowAdvancedCookies = !this.canShowAdvancedCookies,
  ): void {
    this.isConfigShownAtleastOnce = true;
    this.canShowAdvancedCookies = canShowAdvancedCookies;
  }

  toggleIsUpdate(isUpdate = !this.isUpdate): void {
    this.isUpdate = isUpdate;
  }

  triggerInitiateCookieBanner() {
    // @ts-expect-error: Property 'trigger' does not exist on type 'CookieBannerManagementService'
    this.trigger('initiateCookieBanner');
  }

  triggerSubmitCookieBanner(trackingEnabled = false) {
    // @ts-expect-error: Property 'trigger' does not exist on type 'CookieBannerManagementService'
    this.trigger('submitCookieBanner', trackingEnabled);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'cookie-banner-management': CookieBannerManagementService;
  }
}
