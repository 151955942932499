import Route from '@ember/routing/route';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class Invitees extends Route {
  @service declare invitationDataHandler: Services['invitation-data-handler'];

  async model() {
    const invitees = await this.invitationDataHandler.getCustomerInvitees();

    return { invitees };
  }
}
