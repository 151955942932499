import { CustomerState } from '@clark-customer/entities';
import { currentUserToCustomer } from '@clark-customer/entities/transforms';
import Route from '@ember/routing/route';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class ProductRoute extends Route {
  @service declare experiments: Services['experiments'];
  @service declare router: Services['router'];
  @service declare settings: Services['settings'];
  @service declare session: Services['session'];

  private isSelfServiceCustomer(): boolean {
    if (!this.session.currentUser) {
      return false;
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'User' is not assignable to param... Remove this comment to see the full error message
    const { state } = currentUserToCustomer(this.session.currentUser);

    return state === CustomerState.SelfService;
  }

  async beforeModel() {
    const { id } = this.paramsFor(this.routeName) as { id: string };

    const shouldRedirect = await this.shouldRedirectToContractsPage();

    if (shouldRedirect) {
      this.router.replaceWith('contracts.details', id);
      return;
    }

    this.router.replaceWith('contracts.details', `product-${id}`);
  }

  async shouldRedirectToContractsPage(): Promise<boolean> {
    const isClark2User =
      this.settings.getSetting('clark2') &&
      this.experiments.getVariant('business-strategy') === 'clark2';

    if (!isClark2User) {
      return false;
    }

    return !this.isSelfServiceCustomer();
  }
}
