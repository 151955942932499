import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Floater\n  class=\"{{@contentClass}} {{local-class 'button-content'}}\"\n>\n  {{! TODO: should not yield any buttons and just use `<Ui::Button>` directly everywhere. }}\n  {{yield\n    (hash\n      Back=(component\n        \"button-floater/legacy-input-button-adapter\"\n        floaterType=\"back\"\n      )\n      Next=(component\n        \"button-floater/legacy-input-button-adapter\"\n        floaterType=\"next\"\n      )\n    )\n  }}\n</Floater>", {"contents":"<Floater\n  class=\"{{@contentClass}} {{local-class 'button-content'}}\"\n>\n  {{! TODO: should not yield any buttons and just use `<Ui::Button>` directly everywhere. }}\n  {{yield\n    (hash\n      Back=(component\n        \"button-floater/legacy-input-button-adapter\"\n        floaterType=\"back\"\n      )\n      Next=(component\n        \"button-floater/legacy-input-button-adapter\"\n        floaterType=\"next\"\n      )\n    )\n  }}\n</Floater>","moduleName":"@clarksource/client/components/button-floater.hbs","parseOptions":{"srcName":"@clarksource/client/components/button-floater.hbs"}});
import templateOnlyComponent from '@ember/component/template-only';

interface ButtonFloaterSignature {
  Args: {
    /**
     * Optional class to pass through to the floater content wrapper
     */
    contentClass?: string;
  };
  Blocks: {
    default: [unknown];
  };
}

const ButtonFloaterComponent = templateOnlyComponent<ButtonFloaterSignature>();

export default ButtonFloaterComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ButtonFloater: typeof ButtonFloaterComponent;
    'button-floater': typeof ButtonFloaterComponent;
  }
}
