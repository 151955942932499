import type { Lock } from '@clark-ui/navigation/services/navigation/confirm-transition';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type Transition from '@ember/routing/transition';
import { next } from '@ember/runloop';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class DemandcheckQuestionnaireController extends Controller {
  @service declare features: Services['features'];
  @service declare router: Services['router'];
  @service declare tracking: Services['tracking'];

  // @ts-expect-error TS(2416) FIXME: Property 'queryParams' in type 'DemandcheckQuestio... Remove this comment to see the full error message
  queryParams = [
    { qIdx: { type: 'number' } },
    'responseId',
    'source',
    'goBack',
    'hideNavBar',
  ];

  @tracked qIdx?: number;
  @tracked questionId?: string;
  @tracked questionIndex?: number;
  @tracked responseId?: string;
  @tracked source?: string;
  @tracked goBack?: string;
  @tracked hideNavBar?: string;

  private started = false;
  private finished = false;

  @action
  startQuestionnaire() {
    this.started = true;
    // Reset the state whenever a new questionnaire starts
    this.finished = false;
  }

  @action
  finishQuestionnaire() {
    this.finished = true;
  }

  @action
  onCancel(): void {
    // eslint-disable-next-line ember/no-runloop
    next(this, () => window.history.back());
  }

  @action
  showBounceScreen(transition: Transition) {
    if (!this.features.isEnabled('QUESTIONNAIRE_BOUNCE_SCREEN')) {
      return false;
    }

    const isRunning = this.started && !this.finished;
    const transitionIsNotThisRoute =
      transition.to?.name !== this.router.currentRouteName;
    const routeIsNotLoading = this.router.currentRouteName !== 'loading';

    // we will show the bounce screen when ...
    return isRunning && transitionIsNotThisRoute && routeIsNotLoading;
  }

  @action
  leaveQuestionnaire(
    lock: Lock,
    bounceResponse: { bounceReason: string; other: string },
  ) {
    const targetRoute = lock.currentTransition
      ? this.router.urlFor(lock.currentTransition.to?.name as string)
      : '';

    // @ts-expect-error: Object is of type 'unknown'
    if (this.model.censusTemplateId) {
      this.tracking.track('census/demandcheck/bounce:leave-demandcheck', {
        ...bounceResponse,
        // @ts-expect-error: Object is of type 'unknown'
        censusTemplateId: this.model.censusTemplateId,
        // @ts-expect-error: Object is of type 'unknown'
        censusTemplateName: this.model.censusTemplateName,
        targetRoute,
      });
    } else {
      this.tracking.track(
        'recommendations/demandcheck/bounce:leave-demandcheck',
        {
          ...bounceResponse,
          questionId: this.questionId as string,
          questionIndex: this.questionIndex as number,
          questionnaireResponseId: this.responseId as string,
          targetRoute,
        },
      );
    }

    lock.allow();
  }
}
