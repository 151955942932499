import Service, { type Registry as Services, service } from '@ember/service';
import { cached } from '@glimmer/tracking';
import UAParser from 'ua-parser-js';

type UserAgent = {
  browser: {
    major: string | undefined;
    name: string | undefined;
  };
  device: {
    isDesktop: boolean;
    isMobile: boolean;
    isTablet: boolean;
  };
  engine: {
    name: string | undefined;
  };
  os: {
    isAndroid: boolean;
    isIOS: boolean;
    name: string | undefined;
  };
  userAgent: string;
};

export default class ClarkUserAgentService extends Service {
  @service declare config: Services['config'];

  userAgentParser = new UAParser();

  get isApp(): boolean {
    const { userAgent } = this._userAgent;

    return /Clark/.test(userAgent);
  }

  get isAndroidApp(): boolean {
    const { os } = this._userAgent;

    return this.isApp && os.isAndroid;
  }

  get isIOSApp(): boolean {
    const { os } = this._userAgent;

    return this.isApp && os.isIOS;
  }

  get bodyClasses(): string[] {
    const brandName = this.config.getConfig('brand') as string;

    const { browser, device, engine, os } = this._userAgent;

    let bodyClass = '';
    let deviceClass = '';

    if (this.isApp) {
      if (os.isAndroid) {
        bodyClass = 'body--mobile-android';
      } else if (os.isIOS) {
        bodyClass = 'body--mobile-ios';
      } else {
        bodyClass = 'body--mobile';
      }
      deviceClass = `app-${brandName}`;
    } else if (device.isMobile) {
      if (os.isAndroid) {
        bodyClass = 'body--browser-android';
      } else if (os.isIOS) {
        bodyClass = 'body--browser-ios';
      } else {
        bodyClass = 'body--browser';
      }
      deviceClass = 'mobile';
    } else if (device.isTablet) {
      if (os.isAndroid) {
        bodyClass = 'body--browser-android';
      } else if (os.isIOS) {
        bodyClass = 'body--browser-ios';
      } else {
        bodyClass = 'body--browser';
      }
      deviceClass = 'tablet';
    } else {
      bodyClass = 'body--web';
      deviceClass = 'desktop';
    }

    const browserClass = (browser.name ?? '')
      .toLowerCase()
      .replace(/\s+/g, '-');

    const browserVerClass = browserClass ? browserClass + browser.major : '';

    const osClass = (os.name ?? '').replace(/\s/g, '').toLowerCase();

    const engineClass = (engine.name ?? '').toLowerCase();

    return [
      brandName,
      bodyClass,
      browserClass,
      browserVerClass,
      osClass,
      engineClass,
      deviceClass,
    ].filter(Boolean);
  }

  @cached get _userAgent(): UserAgent {
    const browser = this.userAgentParser.getBrowser();
    const device = this.userAgentParser.getDevice();
    const engine = this.userAgentParser.getEngine();
    const os = this.userAgentParser.getOS();
    const userAgent = this.userAgentParser.getUA();

    return {
      browser: {
        major: browser.major,
        name: browser.name,
      },
      device: {
        isDesktop: !device.type,
        isMobile: device.type === 'mobile',
        isTablet: device.type === 'tablet',
      },
      engine: {
        name: engine.name,
      },
      os: {
        isAndroid: os.name === 'Android',
        isIOS: os.name === 'iOS',
        name: os.name,
      },
      userAgent,
    };
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'clark-user-agent': ClarkUserAgentService;
  }
}
