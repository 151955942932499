import type ApplicationInstance from '@ember/application/instance';

export function initialize(instance: ApplicationInstance): void {
  instance.lookup('service:shell/tracking');
}

export default {
  name: 'shell/integrations',
  after: 'shell/registrations',
  initialize,
};
