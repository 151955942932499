import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class MandateMilesAndMoreService extends Service {
  @service declare api: Services['api'];

  getUserMilesAndMore = task(async () => {
    const response = await this.api.get('partnerships/mam/customer');

    return response;
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'mandate/miles-and-more': MandateMilesAndMoreService;
  }
}
