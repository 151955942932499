import type { Category } from '@clark-utils/enums-and-types';
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { type Registry as Services, service } from '@ember/service';

export default class QuestionnaireCategoryRoute extends Route {
  @service declare category: Services['category'];
  @service declare router: Services['router'];

  async beforeModel(transition: Transition): Promise<void> {
    const { id } = transition.to?.params as Record<string, unknown>;

    if (!id) {
      this.router.replaceWith('index.manager');
    }

    const category = (await this.category.findById(id as number)) as Category;

    const questionnaireIdentifier = category.questionnaire.identifier;

    this.router.replaceWith(
      'index.questionnaire.index',
      questionnaireIdentifier,
      {
        queryParams: transition.to?.params,
      },
    );
  }
}
