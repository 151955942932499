import Route from '@ember/routing/route';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class ManagerRoute extends Route {
  @service declare experiments: Services['experiments'];
  @service declare router: Services['router'];
  @service declare session: Services['session'];

  beforeModel(): void {
    const isClark2 =
      this.experiments.getVariant('business-strategy') === 'clark2';

    if (!isClark2) {
      return;
    }

    if (!this.session.currentUser) {
      this.router.replaceWith('contracts');
    }
  }
}
