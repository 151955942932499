import Controller from '@ember/controller';
import { action } from '@ember/object';
import { type Registry as Services, service } from '@ember/service';

export default class IndexMandateIndexFinishedController extends Controller {
  @service declare router: Services['router'];

  @action navigateToContracts(): void {
    this.router.transitionTo('index.manager.landing.contracts');
  }
}
