/* eslint-disable ember/no-actions-hash */
import ClarkRoute from '@clarksource/client/routes/-clark-route';

export default class PasswordReset extends ClarkRoute.extend({
  actions: {
    // no plus button on reset-password page
    didTransition() {
      // @ts-expect-error: Property '_super' does not exist on type '{}'
      this._super(false);
    },
  },
}) {}
