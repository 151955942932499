import { wrapAsync } from '@clarksource/client/sentry';
import { retryOnNetworkFailure } from '@clarksource/ember-api/utils';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';

export default class MandatePhoneVerificationService extends Service {
  @service declare api: Services['api'];
  @service declare config: Services['config'];

  // This updates the mandate primary phone, and sends an SMS message to the mandate.user with a token in order to verify the ownership of a phone number. The verification is valid for 5 minutes.

  requestVerificationCode(phoneNumber: string) {
    const data = {
      phone: phoneNumber,
    };

    const logPhoneNumber = Boolean(
      this.config.getConfig(
        'customer.phoneVerification.logPhoneNumberToSentry',
      ),
    );

    return retryOnNetworkFailure(
      wrapAsync(() => this.api.post(`customer/phone/verify/request`, data), {
        tags: {
          composite: 'customer',
          constituent: 'phone-verification',
          personalData: String(logPhoneNumber),
        },
        extra: logPhoneNumber ? { phoneNumber } : {},
      }),
    );
  }

  // In the case the token is a match, it validates the phone for a given mandate and it erases the token so the user cannot be validated twice
  confirmVerificationCode(token: string) {
    const data = {
      token,
    };

    return retryOnNetworkFailure(
      wrapAsync(() => this.api.patch(`customer/phone/verify/confirm`, data), {
        tags: { composite: 'customer', constituent: 'phone-verification' },
        extra: { token },
      }),
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'mandate/phone-verification': MandatePhoneVerificationService;
  }
}
