import Evented from '@ember/object/evented';
import Service from '@ember/service';

export default class AuthEventManagerService extends Service.extend(Evented) {
  willLogout() {
    // @ts-expect-error: Property 'trigger' does not exist on type 'AuthEventManagerService'
    this.trigger('willLogout');
  }

  didLogin() {
    // @ts-expect-error: Property 'trigger' does not exist on type 'AuthEventManagerService'
    this.trigger('didLogin');
  }

  didLogout() {
    // @ts-expect-error: Property 'trigger' does not exist on type 'AuthEventManagerService'
    this.trigger('didLogout');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'auth-event-manager': AuthEventManagerService;
  }
}
