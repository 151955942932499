import { CustomerStateWireFormat } from '@clark-customer/entities/customer-state';
import type { User } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { MandateStep } from '@clarksource/client/services/mandate-state';
import { action } from '@ember/object';
import type Transition from '@ember/routing/transition';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
// eslint-disable-next-line import/no-unresolved
import { hash } from 'rsvp';

export default class MandateProfilingRoute extends ClarkRoute {
  @service declare mandateState: Services['mandate-state'];
  @service declare router: Services['router'];

  @service('mandate/payback') declare payback: Services['mandate/payback'];

  @service('mandate/miles-and-more')
  declare milesAndMore: Services['mandate/miles-and-more'];

  // @ts-expect-error TS(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
  beforeModel(...args) {
    // @ts-expect-error: A spread argument must either have a tuple type or be passed to a rest parameter
    super.beforeModel(...args);

    const user = this.modelFor('index') as User;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const customerState = user.mandate?.customer_state;

    if (
      customerState === CustomerStateWireFormat.SelfService ||
      customerState === CustomerStateWireFormat.Prospect
    ) {
      this.router.transitionTo('customer.upgrade', {
        queryParams: {
          callback: 'index.mandate.index.profiling',
        },
      });
      return;
    }

    this.mandateState.gotoMissingStep(user, MandateStep.Profiling);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter '_' implicitly has an 'any' type.
  model(_, transition: Transition) {
    return hash({
      // @ts-expect-error: Object is of type 'unknown'
      mandate: this.modelFor('index').get('mandate'),
      user: this.modelFor('index'),
      customFunnelParams: this.modelFor('index.mandate.index'),
      payback: this.payback.getUserPayback.perform(),
      milesAndMore: this.milesAndMore.getUserMilesAndMore.perform(),
      origin: transition.from?.name,
    });
  }

  @action
  didTransition() {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    super.actions.didTransition.call(this, false);
  }
}
