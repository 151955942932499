/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import { cancel, later } from '@ember/runloop';

// move into the lifters folder

export default EmberObject.extend({
  interval: 3000,

  // @ts-expect-error TS(7006) FIXME: Parameter 'f' implicitly has an 'any' type.
  schedule(f) {
    // eslint-disable-next-line ember/no-runloop
    return later(
      this,
      function () {
        f.bind(this)(() => {
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          this.set('timeTick', this.schedule(f));
        });
      },
      this.interval,
    );
  },

  stop() {
    // @ts-expect-error TS(2339) FIXME: Property 'timeTick' does not exist on type 'Fix<Em... Remove this comment to see the full error message
    // eslint-disable-next-line ember/no-runloop
    cancel(this.timeTick);
  },

  start() {
    this.onPoll(() => {
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      this.set('timeTick', this.schedule(this.onPoll));
    });
  },

  // do nothing pool function
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  onPoll(next) {
    next();
  },
});
