/* eslint-disable ember/no-actions-hash, ember/no-classic-classes */
import { CONFIG } from '@clark-utils/business-de';
import Route from '@ember/routing/route';
import { schedule } from '@ember/runloop';
import { service } from '@ember/service';
import { isDevelopingApp, isTesting, macroCondition } from '@embroider/macros';
import { runTask } from 'ember-lifeline';

function logMessage(message: string): void {
  if (macroCondition(isDevelopingApp() && !isTesting())) {
    console.log(`%cInfo: ${message}`, 'color:green;');
  }
}

export default Route.extend({
  platform: service('shell/platform'),
  redirectOnCompletionService: service('redirect-on-completion'),
  routerService: service('router'),
  sectionsHandler: service('sections-handler'),
  sessionStorageService: service('session-storage'),
  trackingService: service('tracking'),
  updateNativeAppService: service('update-native-app'),

  // @ts-expect-error TS(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
  beforeModel(...args) {
    // @ts-expect-error: Property '_super' does not exist on type 'Mixin | Record<string, unknown>'
    this._super(...args);

    // check for update and redirect respectively
    // @ts-expect-error TS(2339) FIXME: Property 'isUpdateAvailable' does not exist on typ... Remove this comment to see the full error message
    if (this.updateNativeAppService.isUpdateAvailable) {
      // @ts-expect-error TS(2339) FIXME: Property 'replaceWith' does not exist on type... Remove this comment to see the full error message
      this.routerService.replaceWith('update-native-app');
      return;
    }

    // @ts-expect-error TS(2339) FIXME: Property 'getRedirect' does not exist on type 'Com... Remove this comment to see the full error message
    const redirect = this.redirectOnCompletionService.getRedirect(
      'redirect-after-action',
    );

    if (redirect && redirect !== 'index.invitations') {
      // @ts-expect-error TS(2339) FIXME: Property 'redirect' does not exist on type 'Comput... Remove this comment to see the full error message
      this.redirectOnCompletionService.redirect('redirect-after-action');
    }
  },

  activate() {
    // @ts-expect-error: Property '_super' does not exist on type 'Mixin | Record<string, unknown>'
    this._super();

    // @ts-expect-error TS(2339) FIXME: Property 'hasSectionAccess' does not exist on type... Remove this comment to see the full error message
    if (!this.sectionsHandler.hasSectionAccess(this.routeName)) {
      // @ts-expect-error TS(2339) FIXME: Property 'transitionTo' does not exist on type... Remove this comment to see the full error message
      this.routerService.transitionTo('index.manager');
    }
  },

  deactivate() {
    // @ts-expect-error: Property '_super' does not exist on type 'Mixin | Record<string, unknown>'
    this._super();

    // Remove the modal-open class from body, incase any modal is open
    // otherwise it blocks scrolling in the native app
    // This will remove the modal-open class once the route has changed
    document.body.classList.remove('modal-open');
  },

  actions: {
    willTransition() {
      // un focus on inputs to make sure the keyboard goes down in iOS
      [...document.getElementsByTagName('input')].forEach((element) =>
        element.dispatchEvent(new Event('blur')),
      );

      return true;
    },

    didTransition() {
      logMessage('Ahoy log for page transition');

      // eslint-disable-next-line ember/no-runloop
      schedule('afterRender', this, () => {
        // track the page view in ahoy
        // the false at the end states dont track in tag manager

        // @ts-expect-error TS(2339) FIXME: Property 'track' does not exist on type 'Com... Remove this comment to see the full error message
        this.trackingService.track('pageview');

        // track page views in sesssion storage for debugging
        const history =
          // @ts-expect-error TS(2339) FIXME: Property 'getData' does not exist on type 'Compute... Remove this comment to see the full error message
          this.sessionStorageService.getData(`${CONFIG.brand}-history`) || [];

        history.push(window.location.pathname);

        // only care about max five URLS
        if (history.length > 5) {
          history.shift();
        }

        // @ts-expect-error TS(2339) FIXME: Property 'setData' does not exist on type 'Compu... Remove this comment to see the full error message
        this.sessionStorageService.setData(`${CONFIG.brand}-history`, history);

        runTask(
          this,
          () => {
            // @ts-expect-error TS(2339) FIXME: Property 'isNative' does not exist on type 'Comput... Remove this comment to see the full error message
            if (this.platform.isNative) {
              const nextFixedFlagBtns = document.querySelectorAll(
                '.next-fixed-flag .btn',
              );

              // for when the keyboard was previously open
              [...nextFixedFlagBtns].forEach((element) => {
                // @ts-expect-error TS(2339) FIXME: Property 'style' does not exist on type 'Element'.
                element.style.bottom = '0px';
              });
            }

            // the hook for our tests in capybara so it knows when the page has transitioned
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            const testHook = window.clark.webapp.capybaraTransitionHook;
            if (testHook) {
              testHook();
            }
          },
          900,
        );
      });
    },
  },
});
