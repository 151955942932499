import type { APIError, TransportError } from '@clarksource/ember-api/errors';
import { ResponseError } from '@clarksource/ember-api/errors';

export default class UserServiceError {
  originalError: APIError | ResponseError | TransportError;

  constructor(originalError: APIError | ResponseError | TransportError) {
    this.originalError = originalError;
  }

  get errors(): {
    email?: string;
    password?: string;
    password_confirmation?: string;
  } {
    // If we have the right data to show errors in the front end, then we return
    // them else we just set this as an empty array
    if (
      this.originalError instanceof ResponseError &&
      this.originalError.body instanceof Object &&
      this.originalError.body.errors instanceof Object
    ) {
      // If we have these type of errors we also need to sort out the ones that
      // could be from user / lead / api as they are all a miss mash of errors
      const { errors } = this.originalError.body;

      return errors.api || errors.user || errors.lead || errors;
    }

    // If there are no API errors we set this as an empty array so the templates
    // could not mess up that use this sort of user service errors
    return {};
  }
}
