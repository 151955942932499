import type { PlatformService } from '@clark-shell/ember';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { scheduleOnce } from '@ember/runloop';
import { type Registry as Services, service } from '@ember/service';

export default class LoginRoute extends Route {
  @service declare tracking: Services['tracking'];
  @service('shell/platform') declare platform: PlatformService;
  @service declare session: Services['session'];

  private async waitForSession(): Promise<void> {
    if (this.session.load.isRunning) {
      await this.session.load.last;
    } else if (!this.session.isAuthenticated && !this.session.load.last) {
      await this.session.load.perform();
    }
  }

  async beforeModel(transition: Transition): Promise<void> {
    await this.waitForSession();

    const { restorationToken } = transition.to?.queryParams as Record<
      string,
      unknown
    >;

    if (!restorationToken) {
      return;
    }

    if (this.platform.isNative) {
      return;
    }

    // FIXME:: https://clarkteam.atlassian.net/browse/JCLARK-93962
    window.location.replace(
      `/de/signup?restoration_token=${encodeURIComponent(restorationToken as string)}`,
    );
  }

  sendTrackingEvent() {
    this.tracking.track('customer/account/login:pageview');
  }

  @action
  didTransition() {
    // eslint-disable-next-line ember/no-runloop
    scheduleOnce('actions', this, this.sendTrackingEvent);
  }
}
