import type { Mandate } from '@clark-utils/enums-and-types';
import { Partner } from '@clark-utils/enums-and-types';
import { MandateStep } from '@clarksource/client/services/mandate-state';
import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import * as Sentry from '@sentry/browser';

export default class MandateConfirmingController extends Controller {
  @service declare experiments: Services['experiments'];
  @service declare features: Services['features'];
  @service declare mandateState: Services['mandate-state'];
  @service declare router: Services['router'];
  @service declare sessionStorage: Services['session-storage'];
  @service declare tracking: Services['tracking'];

  @service('mandate/mandate')
  declare mandateMandate: Services['mandate/mandate'];

  @service('mandate/user') declare mandateUser: Services['mandate/user'];

  @tracked uploadError = false;

  get isPartofSignatureOptimisationExperiment(): boolean {
    if (this.isDisabledPartners) {
      return false;
    }

    // @ts-expect-error: Object is of type 'unknown'
    if (this.model.isHome24Customer || this.hasFunnelCustomized) {
      return false;
    }

    if (!this.features.isEnabled('MANDATE_FUNNEL_SIGNATURE_OPTIMISATION')) {
      return false;
    }

    const variant = this.experiments.getVariant(
      '2022Q1MandateFunnelSignatureOptimisation',
    );

    return variant === 'v1' || variant === 'v2';
  }

  get isDisabledPartners(): boolean {
    // @ts-expect-error: Object is of type 'unknown'
    const { user } = this.model.user;

    if (!user) {
      return false;
    }

    return (
      user.mandate.partner === Partner.dkb ||
      user.mandate.partner === Partner.malburg
    );
  }

  get hasFunnelCustomized() {
    // @ts-expect-error: Object is of type 'unknown'
    return !isEmpty(this.model.customFunnelParams);
  }

  @action
  async confirmMandate() {
    // this will prevent the reminder modal from showing on the first visit
    this.sessionStorage.setAttr('reminders-since-mandate', 'checked', true);

    this.mandateState.completeMandateStep(MandateStep.Confirming);

    // @ts-expect-error: Object is of type 'unknown'
    const { mandate } = this.model.user;

    // now confirm the mandate using the API and redirect to finished
    // @ts-expect-error: Object is of type 'unknown'
    if (this.model.isHome24Customer) {
      mandate.info.home24_consultation_waiving_condition = true;
      mandate.info.home24_contract_details_condition = true;
    }

    try {
      const response = (await this.mandateMandate.confirmMandate(mandate)) as {
        mandate: Mandate;
      };

      // @ts-expect-error: Object is of type 'unknown'
      set(this.model.user, 'mandate', response.mandate);

      /**
       * We need to reload the session's currentUser so that the user's
       * step completion is reflected elsewhere.
       */
      await this.mandateUser.reloadCurrentUser();

      /**
       * We need to remove the step from local storage, since the mandate's
       * wizard_steps should be the only source of truth from now on.
       */
      this.mandateState.removeCompletedStep(MandateStep.Confirming);

      // The tracking event has been renamed from `mandate_created` due to JCLARK-84243.
      // @ts-expect-error TS(2345) FIXME: Argument of type '"confirming_completed"' is not a... Remove this comment to see the full error message
      this.tracking.track('confirming_completed', {
        mandate_id: mandate.id,
      });

      /**
       * FIXME:: Following logic should be taken out into the mandatestate
       * service later.
       */
      // @ts-expect-error: Object is of type 'unknown'
      if (this.mandateState.hasExtraIbanStep(this.model.user.mandate)) {
        this.router.transitionTo('index.mandate.index.iban');
      } else {
        this.router.transitionTo('index.mandate.index.exit-poll');
      }
    } catch (error) {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      Sentry.captureException(error.err);
      this.uploadError = true;
    }
  }
}
