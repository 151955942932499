import type { CensusDemandCheck } from '@clark-utils/enums-and-types';
import Route from '@ember/routing/route';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class QuestionnaireRoute extends Route {
  @service declare api: Services['api'];
  @service declare customer: Services['customer'];
  @service declare experiments: Services['experiments'];
  @service declare features: Services['features'];

  queryParams = {
    responseId: {
      refreshModel: false,
      replace: true,
    },
    qId: {
      refreshModel: false,
      replace: true,
    },
    source: {
      refreshModel: false,
      replace: true,
    },
    goBack: {
      refreshModel: false,
      replace: true,
    },
    hideNavBar: {
      refreshModel: false,
      replace: true,
    },
  };

  @tracked templateId?: number;
  @tracked censusTemplateName?: string;

  async beforeModel() {
    // create prospect if we are clark2
    if (this.experiments.getVariant('business-strategy') === 'clark2') {
      await this.customer.createProspectIfNotExists();
    }

    if (!this.features.isEnabled('DEMANDCHECK_CENSUS_QUESTIONNAIRE')) {
      return;
    }

    const response = (await this.api.get(
      'questionnaires/bedarfcheck/demand_check_template',
      {
        version: 'v5',
      },
    )) as CensusDemandCheck;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (response?.active) {
      this.templateId = response.template_id;
      this.censusTemplateName = response.template_name;
    }
  }

  model() {
    return {
      censusTemplateId: this.templateId,
      censusTemplateName: this.censusTemplateName,
    };
  }

  resetController(controller: any, isExiting: boolean, transition: any) {
    super.resetController(controller, isExiting, transition);

    controller.qIdx = undefined;
  }
}
