import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<input\n  data-test-toggle-button-input\n  local-class=\"checkbox\"\n  type=\"checkbox\"\n  {{this.setAriaAttributes\n    isBusy=@isBusy\n    isChecked=@isChecked\n    isDisabled=@isDisabled\n    onChange=@onChange\n  }}\n  ...attributes\n/>", {"contents":"<input\n  data-test-toggle-button-input\n  local-class=\"checkbox\"\n  type=\"checkbox\"\n  {{this.setAriaAttributes\n    isBusy=@isBusy\n    isChecked=@isChecked\n    isDisabled=@isDisabled\n    onChange=@onChange\n  }}\n  ...attributes\n/>","moduleName":"@clarksource/client/components/shell/settings-route/toggle/button.hbs","parseOptions":{"srcName":"@clarksource/client/components/shell/settings-route/toggle/button.hbs"}});
import {
  isDestroyed,
  isDestroying,
  registerDestructor,
} from '@ember/destroyable';
import { action } from '@ember/object';
import { run, scheduleOnce } from '@ember/runloop';
import Component from '@glimmer/component';
import type { NamedArgs, PositionalArgs } from 'ember-modifier';
import Modifier from 'ember-modifier';

/**
 * Pass this `Symbol` as `@isChecked` to switch the checkbox into an indeterminate
 * state. The checkbox's state is neither `true` nor `false`, but is instead
 * `indeterminate`, meaning that its state cannot be determined or stated in
 * pure binary terms.
 *
 * This may happen, for instance, if the state of the checkbox depends on
 * multiple other checkboxes, and those checkboxes have different values.
 *
 * Essentially, then, the `indeterminate` `Symbol` adds a third possible state
 * to the checkbox: "I don't know."
 *
 * In this state, the knob rendered by the component will be a slim bar instead of
 * a circle, horizontally centered in the middle of the toggle switch.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#indeterminate
 */
export const indeterminate = Symbol('indeterminate');

/**
 * Invoked, when the user toggles the checkbox.
 *
 * @note You must update the `@isChecked` argument (and `@isDisabled` / `@isBusy`)
 * in the same render loop to avoid state flickering. If you want to block an
 * update, simply don't propagate the new value to `@isChecked` and the checkbox
 * will remain in its previous state. You may want to consider using `@isDisabled`
 * or `@isBusy` though.
 *
 * @param isChecked The new value.
 * @param event The original `change` event that triggered this change.
 */
export type UpdateHandler = (checked: boolean, event: Event) => void;

interface SetAriaAttributesModifierSignature {
  Args: {
    Named: {
      isBusy?: boolean;
      isChecked?: boolean | typeof indeterminate;
      isDisabled?: boolean;
      onChange?: UpdateHandler;
    };
    Positional: [];
  };
  Element: HTMLInputElement;
}

class SetAriaAttributesModifier extends Modifier<SetAriaAttributesModifierSignature> {
  private _element!: HTMLInputElement;
  private _named!: NamedArgs<SetAriaAttributesModifierSignature>;

  /**
   * Whether the checkbox is temporarily busy and unresponsive.
   *
   * @internal
   */
  private get isBusy() {
    return Boolean(this._named.isBusy);
  }

  /**
   * Whether the checkbox is toggled on.
   *
   * @internal
   */
  private get isChecked() {
    return this._named.isChecked === true;
  }

  /**
   * Whether the checkbox is disabled for any user interaction.
   *
   * @internal
   */
  private get isDisabled() {
    return this._named.isDisabled ?? !this._named.onChange;
  }

  /**
   * Whether `indeterminate` was passed instead of a `boolean` value for
   * `@checked`.
   *
   * @internal
   * @see indeterminate
   */
  private get isIndeterminate() {
    return this._named.isChecked === indeterminate;
  }

  modify(
    element: HTMLInputElement,
    _positional: PositionalArgs<SetAriaAttributesModifierSignature>,
    named: NamedArgs<SetAriaAttributesModifierSignature>,
  ) {
    this._element = element;
    this._named = named;

    this._element.addEventListener('change', this.onChange);
    this.sync();

    registerDestructor(this, () => {
      this._element.removeEventListener('change', this.onChange);
    });
  }

  /**
   * Registered as a `change` event listener on the target `element`.
   * Conditionally calls `@onChange` and syncs the properties and
   * attributes of the target `element`.
   *
   * @internal
   */
  @action private onChange(event: Event) {
    // eslint-disable-next-line ember/no-runloop
    run(() => {
      if (isDestroying(this) || isDestroyed(this)) {
        return;
      }

      if (this.isDisabled || this.isBusy) {
        // eslint-disable-next-line ember/no-runloop
        scheduleOnce('render', this, this.sync);
        return;
      }

      const { onChange } = this._named;
      onChange?.(this._element.checked, event);

      // eslint-disable-next-line ember/no-runloop
      scheduleOnce('render', this, this.sync);
    });
  }

  /**
   * Syncs the desired state properties & attributes to the target `element`.
   *
   * @internal
   */
  @action private sync() {
    const { isChecked, isIndeterminate } = this;

    if (this._element.checked !== isChecked) {
      this._element.checked = isChecked;
    }

    if (this._element.indeterminate !== isIndeterminate) {
      this._element.indeterminate = isIndeterminate;
    }

    const isBusy = String(this.isBusy);
    const isDisabled = String(this.isDisabled);

    if (this._element.getAttribute('aria-busy') !== isBusy) {
      this._element.setAttribute('aria-busy', isBusy);
    }

    if (this._element.getAttribute('aria-disabled') !== isDisabled) {
      this._element.setAttribute('aria-disabled', isDisabled);
    }

    if (this._element.getAttribute('aria-readonly') !== isBusy) {
      this._element.setAttribute('aria-readonly', isBusy);
    }
  }
}

interface ShellSettingsRouteToggleButtonSignature {
  Args: {
    isBusy: boolean;
    isChecked: boolean | typeof indeterminate | undefined;
    isDisabled?: boolean;
    onChange: UpdateHandler;
  };
  Element: HTMLInputElement;
}

export default class ShellSettingsRouteToggleButtonComponent extends Component<ShellSettingsRouteToggleButtonSignature> {
  setAriaAttributes = SetAriaAttributesModifier;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Shell::SettingsRoute::Toggle::Button': typeof ShellSettingsRouteToggleButtonComponent;
    'shell/settings-route/toggle/button': typeof ShellSettingsRouteToggleButtonComponent;
  }
}
