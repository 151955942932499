import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';

type RedirectOnCompletionKey = 'redirect-after-action' | 'redirect-after-flow';

// This Service simplifies handling callback URLs across multiple routes.
// It utilizes the session store to store, redirect, and remove redirects after actions or complex flows.
// For instance, it can redirect users to specific locations or sections after completing processes like
// filling out a questionnaire and scheduling an appointment.

export default class RedirectOnCompletionService extends Service {
  @service declare router: Services['router'];
  @service declare sessionStorage: Services['session-storage'];

  /**
   * Sets the redirect URL in the session storage.
   */
  setRedirectUrl(route: string, key: RedirectOnCompletionKey) {
    this.sessionStorage.setAttr(key, 'url', route);
  }

  /**
   * Checks if a redirect URL is currently stored.
   */
  hasRedirect(key: RedirectOnCompletionKey): boolean {
    return Boolean(this.sessionStorage.getAttr(key, 'url', false));
  }

  /**
   * Retrieves the stored redirect URL, if any.
   */
  getRedirect(key: RedirectOnCompletionKey): string | false {
    return this.sessionStorage.getAttr(key, 'url', false);
  }

  /**
   * Clears the stored redirect URL from the session storage.
   */
  clearRedirect(key: RedirectOnCompletionKey) {
    this.sessionStorage.setAttr(key, 'url', 'delete');
  }

  /**
   * Executes the redirect to the stored URL and clears it afterward.
   */
  redirect(key: RedirectOnCompletionKey): boolean {
    const redirectUrl = this.getRedirect(key);

    if (!redirectUrl) {
      return false;
    }

    this.clearRedirect(key);
    this.router.transitionTo(redirectUrl);

    return true;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'redirect-on-completion': RedirectOnCompletionService;
  }
}
