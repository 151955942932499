import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-at-ember-render-modifiers }}\n<Ui::Button\n  local-class=\"\n    button\n    {{if this.isSticky 'button-sticky' 'button-fixed'}}\n    {{if this.isPressed 'pressed'}}\n  \"\n  @appearance=\"icon-only\"\n  @isDisabled={{@isDisabled}}\n  @isLoading={{@isLoading}}\n  @onClick={{unless this.shouldHandleIOSKeyboard @onClick}}\n  {{style top=(concat this.topOffset \"px\")}}\n  {{did-insert this.initialize}}\n  data-button-state={{if this.isSticky \"sticky\" \"fixed\"}}\n  ...attributes\n>\n  <Ui::Icon @source=\"button-arrow-right\" @size=\"free\" />\n\n  {{! Workaround for cucumber tests }}\n  <span\n    local-class=\"cucumber-button-text\"\n    data-test-button-content\n  >\n    <span data-test-button-content-block>\n      {{@cucumberButtonText}}\n    </span>\n  </span>\n</Ui::Button>", {"contents":"{{! template-lint-disable no-at-ember-render-modifiers }}\n<Ui::Button\n  local-class=\"\n    button\n    {{if this.isSticky 'button-sticky' 'button-fixed'}}\n    {{if this.isPressed 'pressed'}}\n  \"\n  @appearance=\"icon-only\"\n  @isDisabled={{@isDisabled}}\n  @isLoading={{@isLoading}}\n  @onClick={{unless this.shouldHandleIOSKeyboard @onClick}}\n  {{style top=(concat this.topOffset \"px\")}}\n  {{did-insert this.initialize}}\n  data-button-state={{if this.isSticky \"sticky\" \"fixed\"}}\n  ...attributes\n>\n  <Ui::Icon @source=\"button-arrow-right\" @size=\"free\" />\n\n  {{! Workaround for cucumber tests }}\n  <span\n    local-class=\"cucumber-button-text\"\n    data-test-button-content\n  >\n    <span data-test-button-content-block>\n      {{@cucumberButtonText}}\n    </span>\n  </span>\n</Ui::Button>","moduleName":"@clarksource/client/components/mobile-next-button.hbs","parseOptions":{"srcName":"@clarksource/client/components/mobile-next-button.hbs"}});
import type { PlatformService } from '@clark-shell/ember';
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import type Owner from '@ember/owner';
import { later } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface MobileNextButtonSignature {
  Args: {
    /**
     * Text that is used by cucumber tests to find button.
     */
    cucumberButtonText?: string;
    /**
     * If true, the button will be disabled.
     */
    isDisabled?: boolean;
    /**
     * An action fired when the button is clicked.
     */
    onClick?(event: MouseEvent): void;
  };
  Element: HTMLElement;
}

export const BUTTON_SIZE = 70;
export const BUTTON_MARGIN = 24;

function getViewportHeight(): number {
  return window.visualViewport?.height ?? window.innerHeight;
}

export default class MobileNextButtonComponent extends Component<MobileNextButtonSignature> {
  @service('shell/platform') declare platform: PlatformService;

  readonly offset = BUTTON_SIZE + BUTTON_MARGIN;
  readonly defaultViewportHeight = getViewportHeight();

  @tracked topOffset = 0;
  @tracked isSticky = this.shouldHandleIOSKeyboard;
  @tracked isPressed = false;

  constructor(owner: Owner, args: MobileNextButtonSignature['Args']) {
    super(owner, args);

    if (!this.shouldHandleIOSKeyboard) {
      return;
    }

    const inputs = [...document.getElementsByTagName('input')];

    window.visualViewport!.addEventListener('resize', this.stick);
    document.addEventListener('scroll', this.stick);
    document.addEventListener('click', this.handleClick);
    document.addEventListener('touchstart', this.handleTouchStart);
    document.addEventListener('touchend', this.handleTouchEnd);

    inputs.forEach((element: HTMLInputElement) => {
      element.addEventListener('focus', this.stick);
      element.addEventListener('blur', this.fix);
    });

    registerDestructor(this, () => {
      window.visualViewport!.removeEventListener('resize', this.stick);
      document.removeEventListener('scroll', this.stick);
      document.removeEventListener('click', this.handleClick);
      document.removeEventListener('touchstart', this.handleTouchStart);
      document.removeEventListener('touchend', this.handleTouchEnd);

      inputs.forEach((element: HTMLInputElement) => {
        element.removeEventListener('focus', this.stick);
        element.removeEventListener('blur', this.fix);
      });
    });
  }

  get shouldHandleIOSKeyboard(): boolean {
    return this.platform.isNativeIOS && Boolean(window.visualViewport);
  }

  // Stick button to calculated top at render to avoid buggy position
  // on transition to previous screen.
  @action initialize(): void {
    if (!this.shouldHandleIOSKeyboard) {
      return;
    }

    this.calculateTop();

    // eslint-disable-next-line ember/no-runloop
    later(this.fix, 500);
  }

  @action handleClick(event: MouseEvent): void {
    if (this.args.isDisabled) {
      return;
    }
    if (!this.args.onClick) {
      return;
    }

    const { x, y } = event;

    if (!this.coordsWithinButton(x, y)) {
      return;
    }

    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    this.args.onClick(event);
  }

  @action stick(): void {
    this.isSticky = true;
    this.calculateTop();
  }

  @action fix(): void {
    this.isSticky = false;
  }

  @action calculateTop(): void {
    if (!this.isSticky) {
      return;
    }

    const newViewportHeight = getViewportHeight();
    const keyboardHeight = this.defaultViewportHeight - newViewportHeight;

    const isKeyboardHidden = Math.abs(keyboardHeight) < 1e-8;

    if (isKeyboardHidden) {
      this.isSticky = false;
      return;
    }

    this.topOffset = newViewportHeight + window.scrollY - this.offset;
  }

  @action handleTouchStart(event: TouchEvent): void {
    if (this.args.isDisabled) {
      return;
    }

    // @ts-expect-error TS(2339) FIXME: Property 'clientX' does not exist on type 'Touch |... Remove this comment to see the full error message
    const [{ clientX, clientY }] = event.targetTouches;

    if (!this.coordsWithinButton(clientX, clientY)) {
      return;
    }

    this.isPressed = true;
  }

  @action handleTouchEnd(): void {
    this.isPressed = false;
  }

  private coordsWithinButton(x: number, y: number): boolean {
    const { height, width } = window.visualViewport!;

    const buttonX = width - BUTTON_SIZE - BUTTON_MARGIN;
    const buttonY = height - BUTTON_SIZE - BUTTON_MARGIN;

    return (
      Math.abs(x - buttonX) <= BUTTON_SIZE &&
      Math.abs(y - buttonY) <= BUTTON_SIZE
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MobileNextButton: typeof MobileNextButtonComponent;
    'mobile-next-button': typeof MobileNextButtonComponent;
  }
}
