import type { Mandate, User } from '@clark-utils/enums-and-types';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';

type CustomFunnelParams = {
  fde_jwt?: string;
  fde_mrmo_order_id?: string;
  fde_token?: string;
  fde_uuid?: string;
  partner?: string;
  profile?: Profile;
  utm_campaign?: string;
  utm_tc_confirmed?: string;
};

type Contract = {
  categoryIdent: string;
  categoryName: string;
  companyIdent: string;
  companyName: string;
  customerId: number;
  customerName: string;
  id: number;
  state: string;
};

type Customer = {
  id: number;
  primaryPhoneVerified: boolean;
  showConsentModal: boolean;
  tcConfirmed: boolean;
};

export type Profile = Pick<
  Mandate,
  | 'birthdate'
  | 'city'
  | 'first_name'
  | 'gender'
  | 'house_number'
  | 'last_name'
  | 'street'
  | 'zipcode'
> &
  Pick<User, 'email'> & { country_code?: string; phone?: string };

export default class MandateFdeOnboardingService extends Service {
  @service declare api: Services['api'];
  @service declare localStorage: Services['local-storage'];

  get campaign(): string {
    return this.customFunnelParams.utm_campaign || '';
  }

  get isTCConfirmed(): boolean {
    return this.customFunnelParams.utm_tc_confirmed === 'true';
  }

  set isTCConfirmed(value: boolean) {
    const { fde_uuid, fde_mrmo_order_id, fde_jwt, fde_token } =
      this.customFunnelParams;

    this.localStorage.setData('incentive_specific_funnel', {
      ...this.customFunnelParams,
      ...{
        utm_tc_confirmed: value ? 'true' : 'false',
      },
    });
    this.api.put('fde_onboarding/enable_fde', {
      data: {
        tc_confirmed: value,
        fde_uuid,
        fde_mrmo_order_id,
        fde_jwt,
        fde_token,
      },
    });
  }

  get profile(): CustomFunnelParams['profile'] {
    return this.customFunnelParams.profile;
  }

  set profile(value: CustomFunnelParams['profile']) {
    this.localStorage.setData('incentive_specific_funnel', {
      ...this.customFunnelParams,
      ...{
        profile: value,
      },
    });
  }

  get customFunnelParams(): CustomFunnelParams {
    return this.localStorage.getData('incentive_specific_funnel') ?? {};
  }

  set customFunnelParams(value: CustomFunnelParams) {
    this.localStorage.setData('incentive_specific_funnel', value);
  }

  getCustomer(): Promise<Customer> {
    return this.api.get('fde_onboarding/customer', {
      deserializeJSONAPI: true,
    });
  }

  async getProfile(
    {
      utm_tc_confirmed: tc_confirmed = 'false',
      fde_uuid = '',
      fde_mrmo_order_id = '',
      fde_token = '',
      fde_jwt = '',
    } = this.customFunnelParams,
  ): Promise<Profile | undefined> {
    try {
      return await this.api.get(
        `fde_onboarding/profile?${new URLSearchParams({
          tc_confirmed,
          fde_uuid,
          fde_mrmo_order_id,
          fde_token,
          fde_jwt,
        })}`,
      );
    } catch {
      // eslint-disable-next-line no-useless-return
      return;
    }
  }

  async importPhoneNumber(): Promise<boolean> {
    const { api, profile } = this;

    if (!profile?.phone) {
      return false;
    }

    try {
      const { primaryPhoneVerified } = await api.put<{
        primaryPhoneVerified: boolean;
      }>(
        'fde_onboarding/skip_phone_validation',
        {
          data: {
            phone_number: profile.phone,
          },
        },
        {
          deserializeJSONAPI: true,
        },
      );
      return primaryPhoneVerified;
    } catch {
      return false;
    }
  }

  async migrateContracts(): Promise<Contract[]> {
    try {
      return await this.api.post(
        'fde_onboarding/migrate_contracts',
        undefined,
        {
          deserializeJSONAPI: true,
        },
      );
    } catch {
      return [];
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'mandate/fde-onboarding': MandateFdeOnboardingService;
  }
}
