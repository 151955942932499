import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showVoucherModal}}\n  <Customer::VoucherModal\n    @onClose={{this.closeVoucherModal}}\n    @trackingEvent=\"customer/mandate/profiling/campaign-incentive-info:view\"\n    @voucherCode={{@user.mandate.voucher_code}}\n    @voucherTermsConditions={{@user.mandate.voucher_terms_conditions}}\n  />\n{{/if}}\n\n<Mandate::MandateProfilingShort::Form\n  @fieldErrors={{this.formFieldErrors}}\n  @initialValues={{this.initialFormValues}}\n  @isSubmitting={{this.isFormSubmitting}}\n  @isVoucherHidden={{@isVoucherHidden}}\n  @onSubmit={{this.submitProfiling}}\n  @showFormToolTip={{this.showFormToolTip}}\n  @submitDisabled={{this.submitDisabled}}\n/>", {"contents":"{{#if this.showVoucherModal}}\n  <Customer::VoucherModal\n    @onClose={{this.closeVoucherModal}}\n    @trackingEvent=\"customer/mandate/profiling/campaign-incentive-info:view\"\n    @voucherCode={{@user.mandate.voucher_code}}\n    @voucherTermsConditions={{@user.mandate.voucher_terms_conditions}}\n  />\n{{/if}}\n\n<Mandate::MandateProfilingShort::Form\n  @fieldErrors={{this.formFieldErrors}}\n  @initialValues={{this.initialFormValues}}\n  @isSubmitting={{this.isFormSubmitting}}\n  @isVoucherHidden={{@isVoucherHidden}}\n  @onSubmit={{this.submitProfiling}}\n  @showFormToolTip={{this.showFormToolTip}}\n  @submitDisabled={{this.submitDisabled}}\n/>","moduleName":"@clarksource/client/components/mandate/mandate-profiling-short.hbs","parseOptions":{"srcName":"@clarksource/client/components/mandate/mandate-profiling-short.hbs"}});
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Profile } from '@clark-customer/common/models';
import type { Mandate, User } from '@clark-utils/enums-and-types';
import { WizardStep } from '@clark-utils/enums-and-types';
import { MandateStep } from '@clarksource/client/services/mandate-state';
import { action, set } from '@ember/object';
import type Owner from '@ember/owner';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';

type ProfileFormModel = Partial<Profile>;

interface MandateMandateProfilingShortSignature {
  Args: {
    isVoucherHidden?: boolean;
    mandate: Mandate;
    user: User;
  };
}

interface FormErrors {
  city?: any;
  gender?: any;
  house_number?: any;
  street?: any;
  voucher_code?: any;
  zipcode?: any;
}

export default class MandateMandateProfilingShortComponent extends Component<MandateMandateProfilingShortSignature> {
  @service declare mandateState: Services['mandate-state'];
  @service declare router: Services['router'];
  @service declare session: Services['session'];
  @service declare tracking: Services['tracking'];
  @service declare sessionStorage: Services['session-storage'];
  @service declare experiments: Services['experiments'];
  @service declare features: Services['features'];

  @service('mandate/mandate')
  declare mandateMandate: Services['mandate/mandate'];

  @service('mandate/user') declare mandateUser: Services['mandate/user'];

  @tracked submitDisabled = false;
  @tracked doneProfiling = false;
  @tracked formErrors: FormErrors = {};
  @tracked isFormSubmitting = false;
  @tracked mandate: Mandate;
  @tracked mandateBirthdate: string | null;
  @tracked showFormToolTip = false;
  @tracked showVoucherModal = false; // Open Voucher modal
  @tracked user: User;
  @tracked voucherNavigationRoute = ''; // Navigation route after Voucher modal

  @tracked
  initialFormValues = {};

  allFieldsEmpty(formData: ProfileFormModel): boolean {
    return !(
      formData.birthdate ||
      formData.city ||
      formData.gender ||
      formData.houseNumber ||
      formData.street ||
      formData.zipcode
    );
  }

  get isAlreadyPartOfIncentiveInfoMandateFunnelExperiment(): boolean {
    if (!this.features.isEnabled('INCENTIVE_INFO_MANDATE_FUNNEL')) {
      return false;
    }
    return this.experiments.hasVariant('2024Q3IncentiveInfoMandateFunnel');
  }

  get birthDate(): Date | undefined {
    const { mandateBirthdate } = this;

    if (!mandateBirthdate) {
      return;
    }

    return DateTime.fromFormat(mandateBirthdate, 'dd.LL.yyyy').toJSDate();
  }

  get doneConfirming(): boolean {
    return this.mandate.info.wizard_steps.includes(WizardStep.confirming);
  }

  get formFieldErrors(): Record<string, any> {
    return {
      ...this.formErrors,
      voucherCode: this.formErrors.voucher_code && [
        this.formErrors.voucher_code,
      ],
      zipcode: this.formErrors.zipcode && [this.formErrors.zipcode?.[0]?.[0]],
    };
  }

  constructor(
    owner: Owner,
    args: MandateMandateProfilingShortSignature['Args'],
  ) {
    super(owner, args);

    const { mandate, user } = this.args;
    this.mandate = mandate;
    this.user = user;
    this.mandateBirthdate = this.parseBirthDate(mandate.birthdate);
    this.doneProfiling = this.mandate.info.wizard_steps.includes(
      WizardStep.profiling,
    );

    const { gender, street, house_number, zipcode, city } = this.mandate;

    this.initialFormValues = {
      birthdate: this.birthDate,
      city,
      gender,
      houseNumber: house_number,
      street,
      zipcode,
    };
  }

  // mandate birthdate value comes in different formats (from tests), this method was created to support all formats as before
  parseBirthDate(birthDate: string | Date | null): string | null {
    if (!birthDate) {
      return null;
    }

    if (typeof birthDate === 'object') {
      return DateTime.fromJSDate(birthDate).toFormat('dd.LL.yyyy');
    }

    const isoDate = DateTime.fromISO(birthDate, { setZone: true }); // want to keep the zone since it's a date of birth

    if (isoDate.isValid) {
      return isoDate.toFormat('dd.LL.yyyy');
    }

    return birthDate; // the default dd.LL.yyyy
  }

  async progressMandate(): Promise<void> {
    try {
      const response = (await this.mandateMandate.update(this.mandate)) as {
        mandate: Mandate;
      };

      set(this.user, 'mandate', response.mandate);

      if (this.doneProfiling) {
        this.setupVoucherModal({
          redirectTo: 'index.manager',
          user: this.user,
        });

        return;
      }

      await this.mandateState.progress(this.user.mandate, WizardStep.profiling);

      /**
       * We need to reload the session's currentUser so that the user's
       * step completion is reflected elsewhere.
       */
      await this.mandateUser.reloadCurrentUser();

      this.tracking.track('profiling_completed');

      this.mandateState.completeMandateStep(MandateStep.Profiling);

      const redirectTo = this.mandateState.nextStepRoute(this.user);

      this.setupVoucherModal({
        redirectTo,
        user: this.user,
      });
    } catch (error) {
      this.mandateState.removeState(this.user.mandate, WizardStep.profiling);

      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      const validationErrors = error.body?.errors;

      if (validationErrors) {
        this.formErrors = validationErrors.mandate ?? validationErrors.api;
      }

      this.submitDisabled = false;
    }
  }

  setupVoucherModal(params: { redirectTo: string; user: User }): void {
    const { redirectTo, user } = params;

    const termsAndConditions =
      user.mandate.voucher_terms_conditions ?? ({} as Record<string, string>);

    const numTermsAndConditions = Object.keys(termsAndConditions).length;

    const seenVoucherModal = this.sessionStorage.getAttr(
      'promotional-voucher-modal',
      'seen',
      false,
    );

    const codelessCampaign =
      user.mandate.codeless_campaign &&
      this.isAlreadyPartOfIncentiveInfoMandateFunnelExperiment &&
      !user.mandate.voucher_code;

    if (numTermsAndConditions === 0 || seenVoucherModal || codelessCampaign) {
      this.router.transitionTo(redirectTo);
      return;
    }

    const { terms_conditions_link, terms_conditions_title } =
      termsAndConditions;

    if (!terms_conditions_link || !terms_conditions_title) {
      this.router.transitionTo(redirectTo);
      return;
    }

    this.experiments.forceVariant(
      '2024Q3IncentiveInfoMandateFunnel',
      'control',
    );

    this.showVoucherModal = true;
    this.voucherNavigationRoute = redirectTo;
  }

  @action closeVoucherModal(): void {
    this.router.transitionTo(this.voucherNavigationRoute);
  }

  // we are not going to do any validation here we will do it all on the BE
  @action async submitProfiling(formData: ProfileFormModel): Promise<void> {
    if (this.allFieldsEmpty(formData)) {
      this.showFormToolTip = true;
      return;
    }

    this.isFormSubmitting = true;

    this.showFormToolTip = false;
    this.submitDisabled = true;

    // update the mandate birthdate with the value of the text field
    if (formData.birthdate) {
      this.mandateBirthdate = DateTime.fromJSDate(formData.birthdate).toFormat(
        'dd.LL.yyyy',
      );
    }

    /**
     * first_name, last_name and email are not needed in this component but the
     * update apis still expects these fields to be present, so load it from session and
     * send it anyway
     */
    const { first_name, last_name } = this.session.currentMandate as Mandate;
    const { email } = this.session.currentUser as User;

    /**
     * Need to do this because the mandate and profile models are not compatible
     * at the moment.
     */
    this.mandate = {
      ...this.mandate,
      ...formData,
      house_number: formData.houseNumber ?? null,
      birthdate: this.mandateBirthdate,
      first_name,
      last_name,
    };

    this.user.email = email;
    this.user.mandate = this.mandate;

    try {
      await this.mandateUser.update(this.user);
      await this.progressMandate();
      this.isFormSubmitting = false;
    } catch (error) {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      this.formErrors = error.errors;
      this.submitDisabled = false;
      this.isFormSubmitting = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Mandate::MandateProfilingShort': typeof MandateMandateProfilingShortComponent;
    'mandate/mandate-profiling-short': typeof MandateMandateProfilingShortComponent;
  }
}
