import { MandateStep } from '@clarksource/client/services/mandate-state';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class MandateIndexCustomFunnelController extends Controller {
  @service declare mandateState: Services['mandate-state'];

  @action
  onSubmit() {
    this.mandateState.completeAndNavigateToNextStep(MandateStep.Status);
  }
}
