import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class BankConnectUnavailableRoute extends Route {
  @service declare router: Services['router'];

  beforeModel(transition: Transition) {
    if (isEmpty(transition.from)) {
      return this.router.transitionTo(
        'index.mandate.index.targeting.selection',
      );
    }
  }
}
