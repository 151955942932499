import Controller from '@ember/controller';
import { action } from '@ember/object';
import { type Registry as Services, service } from '@ember/service';

export default class IndexIbanController extends Controller {
  @service declare router: Services['router'];

  @action navigateToManager(): void {
    this.router.transitionTo('index.manager');
  }
}
