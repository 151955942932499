import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-at-ember-render-modifiers no-invalid-interactive }}\n<div\n  {{did-insert this.mountTrustBox}}\n  local-class=\"trustpilot-widget\"\n  id=\"trustpilot-container\"\n  data-test-trustpilot-widget-container\n>\n  <div\n    class=\"trustpilot-widget\"\n    id=\"trustpilot-box\"\n    data-locale=\"de-DE\"\n    data-template-id={{this.templateId}}\n    data-businessunit-id={{this.businessUnitId}}\n    data-style-height=\"20px\"\n    data-style-width=\"100%\"\n  >\n    <a\n      href=\"https://de.trustpilot.com/review/clark.de\"\n      target=\"_blank\"\n      rel=\"noopener noreferrer\"\n    >Trustpilot</a>\n  </div>\n</div>", {"contents":"{{! template-lint-disable no-at-ember-render-modifiers no-invalid-interactive }}\n<div\n  {{did-insert this.mountTrustBox}}\n  local-class=\"trustpilot-widget\"\n  id=\"trustpilot-container\"\n  data-test-trustpilot-widget-container\n>\n  <div\n    class=\"trustpilot-widget\"\n    id=\"trustpilot-box\"\n    data-locale=\"de-DE\"\n    data-template-id={{this.templateId}}\n    data-businessunit-id={{this.businessUnitId}}\n    data-style-height=\"20px\"\n    data-style-width=\"100%\"\n  >\n    <a\n      href=\"https://de.trustpilot.com/review/clark.de\"\n      target=\"_blank\"\n      rel=\"noopener noreferrer\"\n    >Trustpilot</a>\n  </div>\n</div>","moduleName":"@clarksource/client/components/trustpilot-widget.hbs","parseOptions":{"srcName":"@clarksource/client/components/trustpilot-widget.hbs"}});
import { action } from '@ember/object';
import { run } from '@ember/runloop';
import Component from '@glimmer/component';

interface TrustPilotWidgetSignature {
  Args: {
    mode: 'micro-star' | 'micro-trustscore';
    /**
     * An action fired when the button is clicked.
     */
    onView?: () => void;
  };
}

export default class TrustPilotWidgetComponent extends Component<TrustPilotWidgetSignature> {
  businessUnitId = '55c0d2ac0000ff000581c5b0'; // pragma: allowlist-secret

  @action
  mountTrustBox() {
    const newScript = document.createElement('script');
    newScript.type = 'text/javascript';
    newScript.src =
      '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    newScript.async = true;
    document.head.appendChild(newScript);

    newScript.onload = () => {
      // We need the runloop here for test purpose.
      // eslint-disable-next-line ember/no-runloop
      run(() => {
        const trustpilotbox = document.getElementById('trustpilot-box');
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        window.Trustpilot.loadFromElement(trustpilotbox);

        this.args.onView?.();
      });
    };
  }

  get templateId() {
    const microStarId = '5419b732fbfb950b10de65e5'; // pragma: allowlist-secret
    const microTrustScoreId = '5419b637fa0340045cd0c936'; // pragma: allowlist-secret

    if (this.args.mode === 'micro-star') {
      return microStarId;
    } else {
      return microTrustScoreId;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    TrustPilotWidget: typeof TrustPilotWidgetComponent;
    'trustpilot-widget': typeof TrustPilotWidgetComponent;
  }
}
