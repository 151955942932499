/**
 * Get element height with padding
 */
export function getElementInnerHeight(element: Element): number {
  const { borderBottomWidth, borderTopWidth } = getComputedStyle(element);

  const borderY =
    Number.parseFloat(borderTopWidth) + Number.parseFloat(borderBottomWidth);

  // @ts-expect-error TS(2339) FIXME: Property 'offsetHeight' does not exist on type 'El... Remove this comment to see the full error message
  return element.offsetHeight - borderY;
}

export function getElementOffset(element: Element): {
  left: number;
  top: number;
} {
  const { documentElement } = document;
  const box = element.getBoundingClientRect();

  const left = box.left + window.pageXOffset - documentElement.clientLeft;
  const top = box.top + window.pageYOffset - documentElement.clientTop;

  return { left, top };
}
