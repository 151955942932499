import type {
  DocumentViewerService,
  PlatformService,
} from '@clark-shell/ember';
import type { Mandate, User } from '@clark-utils/enums-and-types';
import { MandateStep } from '@clarksource/client/services/mandate-state';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';

interface FormErrors {
  email?: any;
  first_name?: any;
  last_name?: any;
}

interface APIFormError {
  code: 'first_name' | 'last_name' | 'email';
  title: string;
}

type MandateSignUpFormData = {
  email?: string;
  firstName?: string;
  lastName?: string;
};

export default class MandateSignUpController extends Controller {
  @service declare api: Services['api'];
  @service declare experiments: Services['experiments'];
  @service declare features: Services['features'];
  @service declare mandateState: Services['mandate-state'];
  @service declare router: Services['router'];
  @service declare settings: Services['settings'];
  @service declare tracking: Services['tracking'];

  @service('mandate/fde-onboarding')
  declare fdeOnboarding: Services['mandate/fde-onboarding'];

  @service('mandate/user') declare mandateUser: Services['mandate/user'];

  @service('shell/platform') declare platform: PlatformService;
  @service('shell/document-viewer')
  declare documentViewer: DocumentViewerService;

  @tracked initialFormValues = {};
  @tracked fieldErrors: FormErrors = {};
  @tracked isFormSubmitting = false;

  get mandate(): Mandate {
    // @ts-expect-error: Property 'mandate' does not exist on type 'unknown'
    const { mandate } = this.model;

    if (this.isRedirectedFromMandate && this.fdeOnboarding.profile) {
      const {
        birthdate,
        city,
        first_name,
        gender,
        house_number,
        last_name,
        street,
        zipcode,
      } = this.fdeOnboarding.profile;
      Object.assign(mandate, {
        birthdate,
        city,
        first_name,
        gender,
        house_number,
        last_name,
        street,
        zipcode,
      });
    }

    return mandate;
  }

  get user(): User {
    // @ts-expect-error: Property 'user' does not exist on type 'unknown'
    const { user } = this.model;

    if (this.isRedirectedFromMandate && this.fdeOnboarding.profile) {
      const { email } = this.fdeOnboarding.profile;
      Object.assign(user, { email });
    }

    return user;
  }

  get isRedirectedFromMandate(): boolean {
    // @ts-expect-error: Property 'origin' does not exist on type 'unknown'
    const { origin } = this.model;

    return origin?.startsWith('index.mandate');
  }

  get dataProtectionLink() {
    return this.generateHref(
      this.settings.getSetting('datenschutz_link'),
      this.settings.getSetting('privacy_pdf'),
    );
  }

  get agbLink() {
    return this.generateHref(
      this.settings.getSetting('agb_link'),
      this.settings.getSetting('terms_pdf'),
    );
  }

  get isPartOfOpenWalletRetargetingLegalFooterSignUp(): boolean {
    return (
      this.features.isEnabled('LEGAL_FOOTER_SIGN_UP_OPEN_WALLET_RETARGETING') &&
      this.experiments.getVariant(
        '2023Q1OpenWalletRetargetingLegalFooterSignUp',
      ) === 'v1'
    );
  }

  get isTrustpilotSignupScreen(): boolean {
    return this.features.isEnabled('TRUSTPILOT_SIGNUP_SCREEN');
  }

  get trustpilotWidgetMode(): string {
    const variant = this.experiments.getVariant('2024Q4SignUpTrustpilot');

    if (variant === 'v1') {
      return 'micro-star';
    }

    if (variant === 'v2') {
      return 'micro-trustscore';
    }

    // for control
    return 'hidden';
  }

  generateHref(route: string, pdfUrl: string): string {
    if (this.platform.isNative && pdfUrl) {
      return `/${pdfUrl}`;
    }

    return route ? `/de/${route}` : '';
  }

  submitTask = dropTask(async (formData: MandateSignUpFormData) => {
    const { firstName: first_name, email } = formData;

    let { lastName: last_name } = formData;

    // Send field even if empty and let backend handle validation
    if (isEmpty(last_name)) {
      last_name = '';
    }

    try {
      this.fieldErrors = {};

      await this.api.post('customer/current/profile/sign_up', {
        first_name,
        last_name,
        email,
      });

      await this.mandateUser.reloadCurrentUser();
    } catch (error) {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      const formErrors = error?.body?.errors as APIFormError[] | undefined;
      const tempFormErrors: FormErrors = {};

      if (formErrors) {
        for (const err of formErrors) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (err.code) {
            tempFormErrors[err.code] = [err.title];
          }
        }
        this.fieldErrors = tempFormErrors;
        return;
      }

      throw error;
    }

    this.tracking.track('customer/onboarding/sign-up:submit');

    this.mandateState.completeAndNavigateToNextStep(MandateStep.SignUp);
  });

  @action
  onOpenLegalLink(event: Event) {
    const href = (event.target as HTMLAnchorElement).getAttribute('href');
    // we need to be sure we have a link with a href
    if (!href) {
      return;
    }

    if (this.platform.isNative) {
      event.preventDefault();
    }

    // if not on native we bail out here and just open the link
    if (!this.platform.isNative) {
      return;
    }

    // else we open the PDF with the href passed
    this.documentViewer.open(href);
  }

  trackPageView() {
    this.tracking.track('customer/onboarding/sign-up:pageview');
  }

  @action
  async initialize() {
    const { first_name, last_name } = this.mandate;
    const { email } = this.user;

    this.initialFormValues = {
      firstName: first_name,
      lastName: last_name,
      email,
    };

    this.trackPageView();
  }

  @action
  redirectToLogin(): void {
    this.router.transitionTo('login');
  }

  @action
  onViewTrustPilotWidget(): void {
    this.tracking.track('customer/onboarding/sign-up/trustpilot-element:view');
  }
}
