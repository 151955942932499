import './vendor';
import '@clark-ui/design';
import '@clark-ui/legacy-design-tokens';
import 'ember-basic-dropdown/styles';
import 'ember-power-select/styles';
import 'flatpickr/dist/flatpickr.css';

import Application from '@ember/application';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';

import config from './config/environment';
import { engines } from './engines';
import { initializeSentry } from './sentry';

initializeSentry();

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
  engines = engines;
}

loadInitializers(App, config.modulePrefix);
