import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n  class=\"btn\n    {{if\n      (eq @appearance 'primary')\n      'btn-primary'\n      'btn-outline'\n    }}\"\n  local-class=\"button\"\n  type=\"button\"\n  {{on \"click\" @onClick}}\n  ...attributes\n>\n  {{@label}}\n</button>", {"contents":"<button\n  class=\"btn\n    {{if\n      (eq @appearance 'primary')\n      'btn-primary'\n      'btn-outline'\n    }}\"\n  local-class=\"button\"\n  type=\"button\"\n  {{on \"click\" @onClick}}\n  ...attributes\n>\n  {{@label}}\n</button>","moduleName":"@clarksource/client/components/cookie-banner/button.hbs","parseOptions":{"srcName":"@clarksource/client/components/cookie-banner/button.hbs"}});
import templateOnlyComponent from '@ember/component/template-only';

interface CookieBannerButtonSignature {
  Args: {
    appearance: 'primary' | 'secondary';
    label: string;
    onClick: () => Promise<void>;
  };
  Element: HTMLDivElement;
}

const CookieBannerButtonComponent =
  templateOnlyComponent<CookieBannerButtonSignature>();

export default CookieBannerButtonComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CookieBanner::Button': typeof CookieBannerButtonComponent;
  }
}
