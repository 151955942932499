import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Shell::TitleBar\n  @back={{if this.shouldRenderIntro true this.toggleRender}}\n  @more=\"mandate/mandate-more-sheet\"\n  @title={{t \"account.wizards.confirming.title\"}}\n/>\n\n{{#if this.shouldRenderIntro}}\n  <Mandate::Signature::Intro\n    @next={{this.toggleRender}}\n    @user={{@user}}\n  />\n{{else}}\n  <Mandate::Signature::Sign\n    @mandate={{@mandate}}\n    @onSubmit={{@onSubmit}}\n    @uploadError={{@uploadError}}\n    @user={{@user}}\n  />\n{{/if}}", {"contents":"<Shell::TitleBar\n  @back={{if this.shouldRenderIntro true this.toggleRender}}\n  @more=\"mandate/mandate-more-sheet\"\n  @title={{t \"account.wizards.confirming.title\"}}\n/>\n\n{{#if this.shouldRenderIntro}}\n  <Mandate::Signature::Intro\n    @next={{this.toggleRender}}\n    @user={{@user}}\n  />\n{{else}}\n  <Mandate::Signature::Sign\n    @mandate={{@mandate}}\n    @onSubmit={{@onSubmit}}\n    @uploadError={{@uploadError}}\n    @user={{@user}}\n  />\n{{/if}}","moduleName":"@clarksource/client/components/mandate/signature.hbs","parseOptions":{"srcName":"@clarksource/client/components/mandate/signature.hbs"}});
import type { Mandate, User } from '@clark-utils/enums-and-types';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface MandateSignatureSignature {
  Args: {
    mandate: Mandate;
    onSubmit: () => void;
    uploadError: boolean;
    user: User;
  };
}

export default class MandateSignatureComponent extends Component<MandateSignatureSignature> {
  @tracked shouldRenderIntro = true;

  @action
  toggleRender(): boolean {
    this.shouldRenderIntro = !this.shouldRenderIntro;
    return false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Mandate::Signature': typeof MandateSignatureComponent;
    'mandate/signature': typeof MandateSignatureComponent;
  }
}
