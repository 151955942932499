import type ClarkUserAgentService from '@clarksource/client/services/clark-user-agent';
import type ApplicationInstance from '@ember/application/instance';
import type { Registry as Services } from '@ember/service';

export function initialize(appInstance: ApplicationInstance) {
  const clarkUserAgent = appInstance.lookup(
    'service:clark-user-agent',
  ) as ClarkUserAgentService;
  // document.body.classList.add(...clarkUserAgent.bodyClasses);
  const { classList } = document.body;
  for (const className of clarkUserAgent.bodyClasses) {
    // Fuck IE 11
    // https://caniuse.com/#feat=classlist
    classList.add(className);
  }

  const config = appInstance.lookup('service:config') as Services['config'];

  // @TODO: remove me!
  if (config.getConfig('brand') === 'clark') {
    classList.add('clark2');
  }
}

export default {
  initialize,
};
