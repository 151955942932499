import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { action } from '@ember/object';

export default class IndexMandateIndexIbanRoute extends ClarkRoute {
  model() {
    return {
      user: this.modelFor('index'),
      // @ts-expect-error: Object is of type 'unknown'
      mandate: this.modelFor('index').mandate,
      customFunnelParams: this.modelFor('index.mandate.index'),
    };
  }

  @action
  didTransition() {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    super.actions.didTransition.call(this, false);
  }
}
