import Route from '@ember/routing/route';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class Clark2Route extends Route {
  @service declare router: Services['router'];

  beforeModel() {
    window.location.replace(
      this.router.urlFor('index', { queryParams: { cv: 2 } }),
    );
  }
}
