import Controller from '@ember/controller';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class IndexDemandcheckIndexIntroController extends Controller {
  @service declare router: Services['router'];
  @service declare tracking: Services['tracking'];

  @action startDemandCheck(): void {
    this.tracking.track('recommendations/demandcheck/intro:start');
    this.router.transitionTo('index.demandcheck.index');
  }
}
