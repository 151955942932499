/* eslint-disable ember/no-get */
// @TODO: the only component using this now is the offer-checkout, which we don't use any more since
// implementing the comparison offer view (unless it is a single option offer)
// We should remove this once we get rid of single option offers
// instead we should use the new offer-details-consultant component.

import { CONFIG } from '@clark-utils/business-de';
import type {
  Mandate,
  Offer,
  OfferOption,
  OfferOptionType,
} from '@clark-utils/enums-and-types';
import { CategoryIdent } from '@clark-utils/enums-and-types';
import { getProperties } from '@ember/object';
import type { Registry as Services } from '@ember/service';

// a little sprinkle of type saftey for our lovely strings
type OfferTextCatIdent =
  // @ts-expect-error TS(2702) FIXME: 'CategoryIdent' only refers to a type, but is bein... Remove this comment to see the full error message
  | CategoryIdent.Phv
  // @ts-expect-error TS(2702) FIXME: 'CategoryIdent' only refers to a type, but is bein... Remove this comment to see the full error message
  | CategoryIdent.Hausrat
  // @ts-expect-error TS(2702) FIXME: 'CategoryIdent' only refers to a type, but is bein... Remove this comment to see the full error message
  | CategoryIdent.Wohngebaeude
  // @ts-expect-error TS(2702) FIXME: 'CategoryIdent' only refers to a type, but is bein... Remove this comment to see the full error message
  | CategoryIdent.Unfallversicherung
  // @ts-expect-error TS(2702) FIXME: 'CategoryIdent' only refers to a type, but is bein... Remove this comment to see the full error message
  | CategoryIdent.Rechtsschutz;

class OfferTextGenerator {
  offer: Offer;

  mandate: Mandate;

  // @ts-expect-error TS(2564) FIXME: Property 'recommendedOption' has no initializer an... Remove this comment to see the full error message
  recommendedOption: OfferOption;

  optionForOldProduct = false;

  intl: Services['intl'];

  constructor(offer: Offer, mandate: Mandate, intl: Services['intl']) {
    this.intl = intl;

    this.offer = offer;
    this.mandate = mandate;

    this.optionForOldProduct = this.isOptionForOldProduct();
  }

  get recommended_company_name() {
    return this.recommendedOption.product.company.name_hyphenated;
  }

  get username() {
    return this.mandate.first_name as string;
  }

  get saving() {
    return `${this.offer.saving}${this.recommendedOption.product.premium_price_currency}`;
  }

  get premium_period() {
    return this.intl.t(
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      `manager.periods.${this.offer.options[0].product.premium_period}`,
    );
  }

  get admin_name() {
    if (CONFIG.hasAgents) {
      return CONFIG.agent?.teamName ?? this.offer.admin.first_name;
    }

    return this.intl.t('title');
  }

  public offerText(): string | boolean {
    const recommendedOption = this.recommendedOfferOption();
    if (!recommendedOption) {
      return false;
    }

    this.recommendedOption = recommendedOption as OfferOption;

    const catIdent = this.offer.category.ident as OfferTextCatIdent;

    let offerText = '';

    switch (catIdent) {
      case CategoryIdent.Unfallversicherung:
        offerText += this.unfalText();
        break;
      case CategoryIdent.Phv:
      case CategoryIdent.Hausrat:
      case CategoryIdent.Wohngebaeude:
      case CategoryIdent.Rechtsschutz:
        offerText += this.messageStart();
        offerText += this.intl.t(
          `manager.offers.client.checkout.advices.${catIdent}`,
        );
        offerText += this.messageEnd();
        break;
      default:
        return false;
    }

    return offerText;
  }

  // Here be private functions

  private messageStart(): string {
    let text = '';

    text += this.intl.t(
      'manager.offers.client.checkout.advices.hello',
      getProperties(this, 'username'),
    );
    text += this.intl.t('manager.offers.client.checkout.advices.spacer');
    text += this.intl.t('manager.offers.client.checkout.advices.intro');
    text += this.intl.t('manager.offers.client.checkout.advices.spacer');

    return text;
  }

  private messageEnd(): string {
    let text = '';

    text += this.intl.t('manager.offers.client.checkout.advices.spacer');
    text += this.intl.t(
      'manager.offers.client.checkout.advices.i_recommend_tarif',
      getProperties(this, 'recommended_company_name'),
    );

    text += this.savingsText();

    text += this.intl.t(
      'manager.offers.client.checkout.advices.outro.continued',
      getProperties(this, 'recommended_company_name'),
    );
    text += this.intl.t('manager.offers.client.checkout.advices.spacer');

    if (this.optionForOldProduct) {
      text += this.intl.t(
        'manager.offers.client.checkout.advices.existing_product',
      );
      text += this.intl.t('manager.offers.client.checkout.advices.spacer');
    }

    text += this.intl.t(
      'manager.offers.client.checkout.advices.admin_name',
      getProperties(this, 'admin_name'),
    );

    return text;
  }

  private unfalText(): string {
    let text = '';
    text += this.intl.t(
      'manager.offers.client.checkout.advices.hello',
      getProperties(this, 'username'),
    );
    text += this.intl.t('manager.offers.client.checkout.advices.spacer');
    text += this.intl.t('manager.offers.client.checkout.advices.unfall');
    text += this.intl.t('manager.offers.client.checkout.advices.spacer');

    if (this.optionForOldProduct) {
      text += this.intl.t(
        'manager.offers.client.checkout.advices.existing_product',
      );
      text += this.intl.t('manager.offers.client.checkout.advices.spacer');
    }

    text += this.intl.t(
      'manager.offers.client.checkout.advices.admin_name',
      getProperties(this, 'admin_name'),
    );

    return text;
  }

  private recommendedOfferOption(): OfferOption | boolean {
    // default to false if not found
    let recommended: OfferOption | boolean = false;
    // go through options and get the recommended
    this.offer.options.forEach((option: OfferOption) => {
      if (option.recommended) {
        recommended = option;
      }
    });

    return recommended;
  }

  private isOptionForOldProduct(): boolean {
    return this.offer.options.some((option: OfferOption) => {
      return (option.option_type as OfferOptionType) === 'old_product';
    });
  }

  private savingsText(): string {
    let savingText = '';
    const savingValue = this.offer.saving;
    if (savingValue) {
      const saving = Number.parseFloat(savingValue.replace(',', '.'));
      if (saving > 0) {
        savingText += this.intl.t(
          'manager.offers.client.checkout.advices.spacer',
        );
        savingText += this.intl.t(
          'manager.offers.client.checkout.advices.saving',
          getProperties(this, 'saving', 'premium_period'),
        );
      }
    }
    // space before the next line
    savingText += ' ';

    return savingText;
  }
}

export default OfferTextGenerator;
