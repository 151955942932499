import { Section } from '@clark-utils/enums-and-types';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';

export default class SectionsHandlerService extends Service {
  @service declare config: Services['config'];

  redirect = {};

  // This is shamazing, getting values direct from an enum!
  sections = Object.values(Section);

  hasSectionAccess(intent: string): boolean {
    const disabledSections = this.config.get('disabledSections');

    // do a regex for each section in the intent
    // @ts-expect-error: 'disabledSections' is of type 'unknown'
    return !disabledSections.some((section: Section) => {
      return this.sections.includes(section) && intent.includes(section);
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'sections-handler': SectionsHandlerService;
  }
}
