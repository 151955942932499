import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';

export default class QuestionnaireScheduleModalService extends Service {
  @service declare config: Services['config'];
  @service declare router: Services['router'];

  @service('questionnaire/modal')
  declare questionModalService: Services['questionnaire/modal'];

  scheduleModalIfNeeded(categoryIdent: string) {
    this.router.one('routeDidChange', () => {
      if (this.isLowMarginRuleMatrix(categoryIdent)) {
        this.questionModalService.showOfferModal();
      } else if (this.isLowMarginAutomated(categoryIdent)) {
        this.questionModalService.showOneDayOfferModal();
      } else if (this.isLowMarginManual(categoryIdent)) {
        this.questionModalService.showThreeDayOfferModal();
      }
    });
  }

  // work out the type of offer generation rules based on the config and the cat ident
  private isLowMarginRuleMatrix(categoryIdent: string): boolean {
    const idents = this.config.getConfig(
      'categoryIdentsLowMarginRuleMatrix',
    ) as string[];
    return idents.includes(categoryIdent);
  }

  private isLowMarginAutomated(categoryIdent: string): boolean {
    const idents = this.config.getConfig(
      'categoryIdentsLowMarginAutomated',
    ) as string[];
    return idents.includes(categoryIdent);
  }

  private isLowMarginManual(categoryIdent: string): boolean {
    const idents = this.config.getConfig(
      'categoryIdentsLowMarginManual',
    ) as string[];
    return idents.includes(categoryIdent);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'questionnaire/schedule-modal': QuestionnaireScheduleModalService;
  }
}
